import React, { useState, useEffect } from "react";
import Widget from "components/widget/Widget";
import { IoMdHome } from "react-icons/io";
import { MdBarChart, MdDashboard } from "react-icons/md";
import DailyTraffic from "./Dailytraffic";
import MonthlyTraffic from "./Monthlytraffic";
import DeviceTraffic from "./Devicetraffic";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import Select from "@mui/material/Select";
import Switch from "components/switch";
import { instance } from "common/Instance";
import { useSelector } from "react-redux";
import ExperienceCount from "./ExperienceCount";
import SocialMediaCountsDashboard from "../../SocialMediaCount";

const DashboardUser = ({ experienceData, activeSwitchId, multisceneData }) => {
  const [loading, setLoading] = useState(false);
  const [isMultiscene, setISMultiScene] = useState(false);
  const [VistorData, setVisitorData] = useState({});
  const userToken = useSelector((state) => state.auth.auth.token);

  const renderWidget = (icon, title, subtitle) => (
    <Widget icon={icon} title={title} subtitle={subtitle} loading={loading} />
  );

  const [experience, setExperienceName] = useState(experienceData[0]?.name);

  useEffect(() => {

    setExperienceName(experienceData[0]?.name);
  }, [experienceData, activeSwitchId]);

  const handleChange = (event) => {
    setExperienceName(event.target.value);
  };


  const getDashboardData = async () => {
    setLoading(true);
    try {
      let response = await instance.get(
        `api/visitors/get_visitors_user_reseller?userID=${activeSwitchId}&experienceName=${experience}${isMultiscene ? `&multiScene=true` : ""
        }`,
        {
          headers: {
            authorization: `Bearer ${userToken}`,
          },
        }
      );

      if (response.status === 200) {
        setVisitorData(response?.data?.data);
        setLoading(false)
      }
    } catch (error) {
      console.log("Dashboard Error: ", error);
      setLoading(false)
    }
  };

  useEffect(() => {
    if (experience && activeSwitchId) {
      getDashboardData()
    }

  }, [isMultiscene, experience, activeSwitchId])

  return (
    activeSwitchId !== null && (
      <div>
        <div className="flex flex-col  overflow-hidden">
          <h1 className="mb-2 mt-4 rounded-md bg-blue-100 px-2 py-2 text-start text-sm text-gray-900">
            USER DASHBOARD ANALYTICS
          </h1>

          <div className="flex items-center justify-between gap-2 ">
            {isMultiscene ? (
              <div className="mb-1 flex items-center  justify-end">
                {multisceneData?.length > 1 ? (
                  <FormControl
                    sx={{ m: 1, minWidth: 120, bgColor: "#fff" }}
                    size="small"
                    className="bg-white "
                  >
                    <InputLabel
                      id="demo-select-small-label"
                      sx={{ fontSize: "0.75rem", marginTop: "3px" }}
                    >
                      Multiscene1
                    </InputLabel>
                    <Select
                      id="demo-select-small"
                      value={experience}
                      label="Multiscene"
                      onChange={handleChange}
                      sx={{ fontSize: "0.75rem", bgColor: "#fff" }}
                      className="bg-white "
                    >
                      {multisceneData?.map((element) => (
                        <MenuItem
                          value={element?.name}
                          sx={{ fontSize: "0.75rem" }}
                        >
                          {element?.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                ) : (
                  multisceneData[0]?.name && (
                    <div className="text-black my-1 rounded-md border-2 border-gray-700 bg-white px-2 py-1  text-sm">
                      {" "}
                      {multisceneData[0]?.name}
                    </div>
                  )
                )}
              </div>
            ) : (
              <div className="mb-1 flex items-center  justify-end">
                {experienceData?.length > 1 ? (
                  <FormControl
                    sx={{ m: 1, minWidth: 120, bgColor: "#fff" }}
                    size="small"
                    className="bg-white "
                  >
                    <InputLabel
                      id="demo-select-small-label"
                      sx={{ fontSize: "0.75rem", marginTop: "3px" }}
                    >
                      Experience
                    </InputLabel>
                    <Select
                      labelId="demo-select-small-label"
                      id="demo-select-small"
                      value={experience}
                      label="Experience"
                      onChange={handleChange}
                      sx={{ fontSize: "0.75rem", bgColor: "#fff" }}
                      className="bg-white "
                    >
                      {experienceData?.map((element) => (
                        <MenuItem
                          value={element?.name}
                          sx={{ fontSize: "0.75rem" }}
                        >
                          {element?.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                ) : (
                  experienceData[0]?.name && (
                    <div className="text-black my-1 rounded-md border-2 border-gray-700 bg-white px-2 py-1  text-sm">
                      {" "}
                      {experienceData[0]?.name}
                    </div>
                  )
                )}
              </div>
            )}

            {
              multisceneData.length > 0 && <FormControlLabel
                control={
                  <Switch
                    checked={isMultiscene}
                    onClick={() => setISMultiScene(!isMultiscene)}
                  />
                }
                label={`${!isMultiscene ? `Experience` : `Multiscene`}`}
                sx={{
                  "& .MuiFormControlLabel-label": {
                    fontSize: "14px",
                    marginLeft: "3px",
                  },
                }}
              />
            }



          </div>

          <div className="mt-3 grid grid-cols-1 gap-5 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-3 3xl:grid-cols-3">
            {renderWidget(
              <MdBarChart className="h-6 w-6" />,
              "Total Viewers",
              VistorData?.total_experience_visitor
            )}
            {renderWidget(
              <IoMdHome className="h-6 w-6" />,
              "Returning Viewers",
              VistorData?.total_experience_visitor
            )}
            {renderWidget(
              <IoMdHome className="h-6 w-6" />,
              "Unique Viewers",
              VistorData?.total_unique_visitor
            )}
          </div>

          <div className="mt-5 grid w-full grid-cols-1 gap-5 md:grid-cols-2">
            <DailyTraffic
              activeSwitchId={activeSwitchId}
              isMultiscene={isMultiscene}
              experience={experience}
            />
            <ExperienceCount
              activeSwitchId={activeSwitchId}
              isMultiscene={isMultiscene}
              experience={experience}
            />
          </div>
          <div className="mt-5 grid w-full grid-cols-1 gap-5 md:grid-cols-2">
            <MonthlyTraffic
              activeSwitchId={activeSwitchId}
              isMultiscene={isMultiscene}
              experience={experience}
            />
            <DeviceTraffic
              data={VistorData}
              activeSwitchId={activeSwitchId}
              experience={experience}
            />
          </div>
          <div className="mt-5 grid w-full">
            <SocialMediaCountsDashboard
              experienceName={experience}
              userId={activeSwitchId}
            />
          </div>
        </div>
      </div>
    )
  );
};

export default DashboardUser;
