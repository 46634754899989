import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  SET_DOCUMENT_LINK,
  SET_DOCUMENT_PROPERTIES,
} from "../../../../../redux/reducerSlice/arSlice";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import Attach from "./attach";
import Animation from "./animation";

function DocumentEditorSection({ whatsClicked }) {
  const dispatch = useDispatch();
  const ardata = useSelector((state) => state.ar.data);

  //select group record
  const anchor = useSelector((state) => state.ar.data.anchor) || null;

  const selectedDocument = ardata?.documents?.find(
    (data) => data?.id === whatsClicked?.userData?.id
  );
  const { id, linkedAnchor } = selectedDocument || {};

  const [animationDelay, setAnimationDelay] = useState(
    ardata?.documents?.find((data) => data?.id === whatsClicked?.userData?.id)
      ?.animation?.delay
  );

  const [animationDuration, setAnimationDuration] = useState(
    ardata?.documents?.find((data) => data?.id === whatsClicked?.userData?.id)
      ?.animation?.duration
  );

  useEffect(() => {
    setAnimationDelay(
      ardata?.documents?.find((data) => data?.id === whatsClicked?.userData?.id)
        ?.animation?.delay
    );

    setAnimationDuration(
      ardata?.documents?.find((data) => data?.id === whatsClicked?.userData?.id)
        ?.animation?.duration
    );
  }, [whatsClicked?.userData?.id]);

  const linkType = useSelector((state) => {
    const icon = state?.ar?.data?.documents?.find(
      (data) => data.id === whatsClicked?.userData?.id
    );

    return icon?.linkType;
  });

  const iconLink = useSelector((state) => {
    const icon = state?.ar?.data?.documents?.find(
      (data) => data.id === whatsClicked?.userData?.id
    );
    return icon?.iconLink;
  });

  return (
    <div>
      {whatsClicked?.userData?.type === "documents" && (
        <div className="mt-2 px-2">
          <div className="rounded-md bg-white px-4 py-2 shadow-md">
            <div className="border-t-1 flex w-full items-center justify-center">
              <div className="flex w-full flex-row items-center justify-between">
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={linkType === "RedirectLink"}
                      onChange={(event) => {
                        const isChecked = event.target.checked;
                        const actionType = {
                          key: "linkType",
                          value: isChecked ? "RedirectLink" : "",
                          id: whatsClicked?.userData?.id,
                        };
                        dispatch(SET_DOCUMENT_PROPERTIES(actionType));
                      }}
                    />
                  }
                  label={<span style={{ fontSize: 12 }}>Link</span>}
                  className="w-full"
                />
              </div>
            </div>

            {whatsClicked?.userData?.type === "documents" &&
              linkType === "RedirectLink" && (
                <div className="text-bold mb-2 mt-2 flex w-full flex-col items-center text-xs text-gray-900">
                  <div className="flex w-full gap-3 rounded-lg bg-white px-2">
                    <h1 className="flex items-center py-1">URL:</h1>
                    <input
                      type="text"
                      className="w-full border p-1 outline-none"
                      value={iconLink}
                      onChange={(event) => {
                        const action = {
                          iconLink: event.target.value,
                          id: whatsClicked?.userData?.id,
                        };
                        dispatch(SET_DOCUMENT_LINK(action));
                      }}
                    />
                  </div>
                </div>
              )}
          </div>
        </div>
      )}

      {whatsClicked?.userData?.type === "documents" && Array.isArray(anchor) && anchor.length > 0 && (
          <Attach whatsClicked={whatsClicked} anchor={anchor} arItem={ardata.documents} type="documents" />
        )}

      {whatsClicked?.userData?.type === "documents" && (
        <Animation whatsClicked={whatsClicked} arData={ardata} type="documents" />
      )}
    </div>
  );
}

export default DocumentEditorSection;
