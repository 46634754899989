import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import UploadImage from "./UploadImage";
import { SET_PHOTO_PROPERTIES ,SET_PHOTO_LINK} from "../../../../../redux/reducerSlice/arSlice";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import Attach from "./attach";
import Animation from "./animation";

function PhotoEditorSection({ whatsClicked }) {
  const dispatch = useDispatch();
  const ardata = useSelector((state) => state.ar.data);
  
  const anchor = useSelector((state) => state?.ar?.data?.anchor) || null;

  const selectedPhoto = ardata.photos.find((data) => data?.id === whatsClicked?.userData?.id);
  const { id, geometry } = selectedPhoto || {};
  const [openModal, setOpenModal] = useState(null);

  const handleOpenModal = (modal) => setOpenModal(modal);
  const handleCloseModal = () => setOpenModal(null);

  const handleGeometryChange = (event) => {
    const action = {
      key: "geometry",
      value: event.target.value,
      id,
    };
    dispatch(SET_PHOTO_PROPERTIES(action));
  };

  const photoLink = useSelector((state) => {
    const photo = state?.ar?.data?.photos?.find(
      (data) => data.id === whatsClicked?.userData?.id
    );
    return photo?.urlLink;
  });

  return (
    <div>
      {whatsClicked?.userData?.type === "photo" && (
        <>
          <div className="mt-2 px-2 flex flex-col gap-2">
            <div className="bg-white px-4 py-2 rounded-md shadow-md">
              <p className="py-2">Edit Image</p>
              <div className="flex w-full items-center justify-center">
                <button
                  className="w-full cursor-pointer bg-[#3D2FA9] rounded-md p-1 py-2 text-center text-white"
                  onClick={handleOpenModal}
                >
                  Replace Image
                </button>
                <UploadImage
                  openModal={openModal}
                  handleCloseModal={handleCloseModal}
                  id={id}
                />

              </div>

              <div className="border-t-1 flex w-full items-center justify-center">
                <RadioGroup
                  value={geometry}
                  className="w-full"
                  onChange={handleGeometryChange}
                  row
                >
                  <div className="flex w-full items-center justify-center">
                    <FormControlLabel
                      value="Plane"
                      control={<Radio />}
                      label={<span style={{ fontSize: 13 }}>Plane</span>}
                      className="w-full"
                    />
                    <FormControlLabel
                      value="Circle"
                      control={<Radio />}
                      label={<span style={{ fontSize: 13 }}>Circle</span>}
                      className="w-full"
                    />
                  </div>
                </RadioGroup>
              </div>

              <div className="text-bold mb-2 mt-2 flex w-full flex-col items-center text-xs text-gray-900">
                <div className="flex w-full gap-3 rounded-lg bg-white px-2">
                  <h1 className="flex items-center py-1">URL:</h1>
                  <input
                    type="text"
                    className="w-full border p-1 outline-none"
                    value={ photoLink}
                    onChange={(event) => {
                      const action = {
                        link: event.target.value,
                        id: whatsClicked?.userData?.id,
                      };
                      dispatch(SET_PHOTO_LINK(action));
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
          <div/>

          {whatsClicked?.userData?.type === "photo" && Array.isArray(anchor) && anchor.length > 0 && (
            <Attach whatsClicked={whatsClicked} anchor={anchor} arItem={ardata.photos} type="photo" />
          )}

          <div/>

          {whatsClicked?.userData?.type === "photo" && (
            <Animation whatsClicked={whatsClicked} arData={ardata} type="photo"/>
          )}
        </>
      )}
    </div>
  );
}

export default PhotoEditorSection;
