import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { UPDATE_TEXT_PROPERTIES } from "../../.././../../redux/reducerSlice/arSlice";
import VideoThumbnail from "react-video-thumbnail";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";

import {
  SET_ICONS_LINK,
  SET_ICONS_PROPERTIES,
} from "../../../../../redux/reducerSlice/arSlice";

import FormControlLabel from "@mui/material/FormControlLabel";

import VCard from "../VCard";

import UploadImage from "./UploadImage";
import UploadVideo from "./UploadVideo";
import UploadIcon from "./UploadIcon";
import UploadTarget from "./UploadTarget";

const FormView = () => {
  const targetPreview = useSelector((state) => state.ar.data.targetImage.src);
  const [openVcard, setOpenVcard] = useState(false);
  const [id, setId] = useState(null);

  const dispatch = useDispatch();

  const arData = useSelector((state) => state.ar.data);



  const [openModal, setOpenModal] = useState(false);
  const handleOpenModal = (value) => {
    setOpenModal(true);
    setId(value);
  };

  const handleCloseModal = () => {
    setOpenModal(null);
    setVideoModal(false);
    setIconModal(false);
    setTargetModal(false);
  };

  const [openVideoModal, setVideoModal] = useState(false);
  const handleVideoOpenModal = (value) => {
    setVideoModal(true);
    setId(value);
  };

  const [openIconModal, setIconModal] = useState(false);
  const handleIconOpenModal = (value) => {
    setIconModal(true);
    setId(value);
  };

  const [openTargetModal, setTargetModal] = useState(false);
  const handleTargetOpenModal = (value) => {
    setTargetModal(true);
    setId(value);
  };

  const hanndleVcard = () => {
    setOpenVcard(true);
  };
  const handleClose = () => {
    setOpenVcard(false);
  };

  return (
    <>
      <div className="right bg-zinc-900 dark:bg-zinc-800 rouned-lg z-50 mx-1  h-[80vh] w-4/12  overflow-y-auto rounded-md   text-sm ">
        <div className="views  mx-auto  max-w-3xl rounded-2xl border-2 px-8 py-2 ">
          <button className="eye  flex w-full items-center justify-center rounded-lg px-2 py-1 text-center hover:bg-gray-100 dark:bg-gray-900 dark:text-white ">
            <span className="select-none text-center text-2xl font-bold text-gray-700 ">
              Editor Form
            </span>
          </button>

          <div className="flex flex-col gap-2 ">
            {arData && arData?.targetImage?.src && (
              <div className="mt-2 flex w-full items-center justify-between gap-4">
                <div className="flex flex-grow flex-col gap-2">
                  <div className="flex w-full">
                    <input
                      type="text"
                      className="w-full rounded border px-4 py-2 shadow-sm disabled:bg-gray-100 disabled:text-gray-500"
                      placeholder="Target Image"
                      value={targetPreview}
                      disabled
                    />
                  </div>

                  <button
                    onClick={handleTargetOpenModal}
                    className="self-start rounded-md  bg-[#3d2fa9] px-4 py-2 text-white"
                  >
                    Change
                  </button>
                </div>

                <div className="flex shrink-0 flex-col gap-1">
                  <img
                    src={targetPreview}
                    alt="Preview"
                    className="max-h-[150px] max-w-[150px] object-cover"
                  />
                </div>
              </div>
            )}

            {arData && arData?.text && arData?.text.length > 0 && (
              <div className=" border-b-2 border-gray-600 "></div>
            )}

            {arData &&
              arData?.text &&
              arData?.text?.map((item, index) => (
                <div className="mt-2 flex w-full items-center justify-between gap-2">
                  <div className="flex flex-grow flex-col gap-2">
                    <p className="text-md font-medium">
                      {" "}
                      {`Text-${index + 1}`}{" "}
                    </p>
                    <div className="flex w-full">
                      <input
                        className="w-full rounded border px-4 py-2 shadow-sm disabled:bg-gray-100 disabled:text-gray-500"
                        value={item.body}
                        onChange={(event) => {
                          const action = {
                            key: "body",
                            value: event.target.value,
                            id: item.id,
                          };
                          dispatch(UPDATE_TEXT_PROPERTIES(action));
                        }}
                      />
                    </div>
                  </div>
                  <div className="mt-7 flex  items-center">
                    <input
                      type="color"
                      className="h-10 w-10" // Adjust the height and width as needed
                      value={item.color}
                      onChange={(event) => {
                        const action = {
                          key: "color",
                          value: event.target.value,
                          id: item.id,
                        };
                        dispatch(UPDATE_TEXT_PROPERTIES(action));
                      }}
                    />
                  </div>
                </div>
              ))}

            {arData && arData?.photos && arData?.photos.length > 0 && (
              <div className=" border-b-2 border-gray-600 "></div>
            )}

            {arData &&
              arData?.photos &&
              arData?.photos?.map((item, index) => (
                <div className="mt-2 flex w-full items-center justify-between gap-4">
                  <div className="flex flex-grow flex-col gap-2">
                    <p className="text-md font-medium">
                      {" "}
                      {`Photo-${index + 1}`}{" "}
                    </p>
                    <div className="flex w-full">
                      <input
                        type="text"
                        className="w-full rounded border px-4 py-2 shadow-sm disabled:bg-gray-100 disabled:text-gray-500"
                        label={`Photo-${index + 1}`}
                        value={item.src}
                        disabled
                      />
                    </div>

                    <button
                      onClick={() => handleOpenModal(item.id)}
                      className="self-start rounded-md  bg-[#3d2fa9] px-4 py-2 text-white"
                    >
                      Change
                    </button>
                  </div>

                  <div className="flex shrink-0 flex-col gap-1">
                    <img
                      src={item.src}
                      alt={item.src}
                      className="max-h-[150px] max-w-[150px]"
                    />
                  </div>
                </div>
              ))}

            {arData && arData?.videos && arData?.videos.length > 0 && (
              <div className=" border-b-2 border-gray-600 "></div>
            )}

            {arData &&
              arData?.videos &&
              arData?.videos?.map((item, index) => (
                <div className="mt-2 flex w-full items-center justify-between gap-4">
                  <div className="flex flex-grow flex-col gap-2">
                    <p className="text-md font-medium">
                      {" "}
                      {`Video-${index + 1}`}{" "}
                    </p>
                    <div className="flex w-full">
                      <input
                        type="text"
                        className="w-full rounded border px-4 py-2 shadow-sm disabled:bg-gray-100 disabled:text-gray-500"
                        value={item.src}
                        disabled
                      />
                    </div>

                    <button
                      onClick={() => handleVideoOpenModal(item.id)}
                      className="self-start rounded-md  bg-[#3d2fa9] px-4 py-2 text-white"
                    >
                      Change
                    </button>
                  </div>
                  <div className="mt-6 flex w-[150px] items-center justify-start gap-2 px-4">
                    <VideoThumbnail videoUrl={item?.src ?? ""} />
                  </div>
                </div>
              ))}

            {arData && arData?.icons && arData?.icons.length > 0 && (
              <div className=" border-b-2 border-gray-600 "></div>
            )}

            {arData &&
              arData?.icons &&
              arData?.icons?.map((item, index) => (
                <div className="mt-2 flex w-full items-start justify-between gap-4 rounded-md border-4 p-4">
                  <div className="flex flex-grow flex-col gap-2">
                    <p className="text-md font-medium">
                      {item?.iconName ? item?.iconName : `Icons-${index + 1}`}
                    </p>
                    <div className="flex w-full ">
                      <input
                        type="text"
                        className="w-full rounded border px-4 py-2 shadow-sm disabled:bg-gray-100 disabled:text-gray-500"
                        value={item.src}
                        disabled
                      />
                    </div>

                    <button
                      onClick={() => handleIconOpenModal(item.id)}
                      className="self-start rounded-md  bg-[#3d2fa9] px-4 py-2 text-white"
                    >
                      Change
                    </button>

                    <div className="border-t-1   w-full ">
                      <RadioGroup
                        value={item.linkType}
                        className="w-full"
                        onChange={(event) => {
                          const isRedirectLink =
                            event.target.value === "RedirectLink";

                          const action = {
                            key: "isLink",
                            value: Boolean(isRedirectLink), // Convert to boolean
                            id: item.id,
                          };
                          dispatch(SET_ICONS_PROPERTIES(action));

                          const actionLink = {
                            key: "isvCard",
                            value: !isRedirectLink, // Negate for boolean value
                            id: item.id,
                          };
                          dispatch(SET_ICONS_PROPERTIES(actionLink));

                          const actionType = {
                            key: "linkType",
                            value: event.target.value,
                            id: item.id,
                          };
                          dispatch(SET_ICONS_PROPERTIES(actionType));
                        }}
                        row
                      >
                        <div className="mx-2 flex w-full items-center justify-center">
                          <FormControlLabel
                            value="RedirectLink"
                            control={<Radio />}
                            label={<span style={{ fontSize: 12 }}>Link</span>}
                            className="w-full"
                          />
                          <FormControlLabel
                            value="Vcard"
                            control={<Radio />}
                            label={<span style={{ fontSize: 12 }}>Vcard</span>}
                            className="w-full"
                          />
                        </div>
                      </RadioGroup>
                    </div>

                    <div>
                      {item.linkType === "Vcard" && (
                        <div className="mt-1">
                          <button
                            className="rounded-lg bg-[#3d2fa9] px-4 py-2  text-white"
                            onClick={hanndleVcard}
                          >
                            Add VCard
                          </button>
                          <VCard
                            open={openVcard}
                            handleClose={handleClose}
                            id={item.id}
                          />
                        </div>
                      )}

                      {item.linkType === "RedirectLink" && (
                        <div className="text-bold mb-2 mt-4 flex w-full flex-col items-center text-xs text-gray-900">
                          <div className="flex w-full gap-3 rounded-lg bg-white ">
                            <h1 className="flex items-center py-1">URL:</h1>
                            <input
                              type="text"
                              className="w-full rounded-sm border p-1  py-2 outline-none"
                              value={item.iconLink}
                              onChange={(event) => {
                                const action = {
                                  iconLink: event.target.value,
                                  id: item.id,
                                };
                                dispatch(SET_ICONS_LINK(action));
                              }}
                            />
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className=" mt-6 flex max-w-[200px] flex-col items-center justify-start">
                    <img
                      src={item?.src}
                      alt={item?.src}
                      className="w-full object-contain"
                    />
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>
      <UploadImage
        openModal={openModal}
        handleCloseModal={handleCloseModal}
        id={id}
      />
      <UploadVideo
        open={openVideoModal}
        handleClose={handleCloseModal}
        id={id}
      />
      <UploadIcon open={openIconModal} handleClose={handleCloseModal} id={id} />
      <UploadTarget
        open={openTargetModal}
        handleClose={handleCloseModal}
        id={id}
      />
    </>
  );
};

export default FormView;
