import React from "react";
import { useSelector } from "react-redux";
import Attach from "./attach";
import Animation from "./animation";

function GroupEditorSection({ whatsClicked }) {
  const ardata = useSelector((state) => state.ar.data);

  const anchor = useSelector((state) => state?.ar?.data?.anchor)?.filter(
    (data) => data?.id !== whatsClicked?.userData?.id
    );
 
  return (
    <div>
      {whatsClicked?.userData?.type === "anchor" && Array.isArray(anchor) && anchor.length > 0 && (
          <Attach whatsClicked={whatsClicked} anchor={anchor} arItem={ardata.anchor} type="anchor" />
      )}

      {whatsClicked?.userData?.type === "anchor" && (
        <Animation whatsClicked={whatsClicked} arData={ardata} type="anchor" />
      )}
    </div>
  );
}

export default GroupEditorSection;
