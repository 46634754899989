import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import Box from "@mui/material/Box";
import { instance, AWS_DATA_PREFIX, AWS_STATIC_PREFIX } from "common/Instance";
import Drawer from "@mui/material/Drawer";
import toast from "react-hot-toast";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { loadTexture } from "./EditorUtils";
import upload from "../../../../assets/img/upload.png";
import imageCompression from "browser-image-compression";
import { MdOutlineClear } from "react-icons/md";

import {
  REMOVE_ICONS,
  UPDATE_ICONS,
} from "../../../../redux/reducerSlice/arSlice";

const iconTemplate = [
  {
    small: `${AWS_DATA_PREFIX + "3d+icons/snapchat.svg"}`,
    description: "Snapchat",
    id: 1,
    link: `${
      AWS_DATA_PREFIX + "website+data/3d_modified/snapchat_modified/scene.gltf"
    }`,
  
  },
  {
    small: `${AWS_DATA_PREFIX + "3d+icons/linkedin.svg"}`,
    description: "Linkedin",
    id: 2,
    link: `${
      AWS_DATA_PREFIX + "website+data/models+(1)/linkedin_modified2/scene.gltf"
    }`,
  },
  {
    small: `${AWS_DATA_PREFIX + "3d+icons/Facebook.svg"}`,
    description: "Facebook",
    id: 5,
    link: `${
      AWS_DATA_PREFIX + "website+data/models+(1)/facebook_modified2/scene.gltf"
    }`,
  },
  {
    small: `${AWS_DATA_PREFIX + "3d+icons/image%2016.svg"}`,
    description: "Email",
    id: 6,
    link: `${AWS_STATIC_PREFIX + "3dmodels/mail3DIcon.glb"}`,
  },

  {
    small: `${AWS_DATA_PREFIX + "3d+icons/Instagram.svg"}`,
    description: "Instagram",
    id: 7,
    link: `${
      AWS_DATA_PREFIX + "website+data/models+(1)/instagram_modified2/scene.gltf"
    }`,
  },
  {
    small: `${AWS_DATA_PREFIX + "3d+icons/Pinterest.svg"}`,
    description: "Pinterest",
    id: 8,
    link: `${
      AWS_DATA_PREFIX + "website+data/models+(1)/pinterest_modified2/scene.gltf"
    }`,
  },
  {
    small: `${AWS_DATA_PREFIX + "3d+icons/reddit.svg"}`,
    description: "Reddit",
    id: 9,
    link: `${
      AWS_DATA_PREFIX + "website+data/models+(1)/reddit_modified2/scene.gltf"
    }`,
  },
  {
    small: `${AWS_DATA_PREFIX + "3d+icons/Twitter.svg"}`,
    description: "Twitter",
    id: 10,
    link: `${
      AWS_DATA_PREFIX + "website+data/models+(1)/twitter_modified2/scene.gltf"
    }`,
  },
  {
    small: `${AWS_DATA_PREFIX + "3d+icons/gmail.svg"}`,
    description: "Gmail",
    id: 11,
    link: `${AWS_DATA_PREFIX + "website+data/models+(1)/mail3DIcon.glb"}`,
  },
  {
    small: `${AWS_DATA_PREFIX + "3d+icons/WhatsApp.svg"}`,
    description: "Whatsapp",
    id: 12,
    link: `${AWS_STATIC_PREFIX + "3dmodels/whatsApp3DIcon.glb"}`,
  },

  {
    small: `${AWS_DATA_PREFIX + "3d+icons/www.svg"}`,
    description: "Website",
    id: 13,
    link: `${
      AWS_DATA_PREFIX +
      "website+data/models+(1)/globe_with_meridians_modified2/scene.gltf"
    }`,
  },
  {
    small: `${AWS_DATA_PREFIX + "3d+icons/Youtube.svg"}`,
    description: "Youtube",
    id: 14,
    link: `${
      AWS_DATA_PREFIX + "website+data/models+(1)/youtube1_modified2/scene.gltf"
    }`,
  },

  {
    small: `${AWS_DATA_PREFIX + "3d+icons/location.png"}`,
    description: "Location",
    id: 16,
    link: `${
      AWS_DATA_PREFIX +
      "website+data/models+(1)/map_pointer_modified2/scene.gltf"
    }`,
  },
];

const AddIcons = ({ open, handleClose }) => {
  const selectedIcons = useSelector((state) => state.ar.data.icons);
  const [galleryIcons, setGalleryIcons] = useState([]);
  const [tab, setTab] = useState("upload");
  const [loading, setLoading] = useState(false);
  const userToken = useSelector((state) => state.auth.auth.token);
  const user = useSelector((state) => state.auth.auth.user);
  const arData = useSelector((state) => state.ar.data);
  const dispatch = useDispatch();

  const Gallery = async () => {
    try {
      const response = await instance.get(`api/ar/fetchUserGallery/icons`, {
        headers: {
          authorization: `Bearer ${userToken}`,
        },
      });
      if (response.status === 200) {
        setGalleryIcons(response.data.data);
      }
    } catch (error) {
      toast.error(error?.response?.data?.error?.message ?? error.message);
    }
  };

  useEffect(() => {
    setTab("upload");
    Gallery();
  }, [open]);

  const handleImageClick = (icon, index) => {
    const isSelected = selectedIcons.some(
      (selectedIcon) => selectedIcon.src === icon.small
    );

    if (isSelected) {
      dispatch(
        REMOVE_ICONS({
          src: icon.small,
        })
      );
    } else {
      dispatch(
        UPDATE_ICONS({
          id: uuidv4(),
          type: "icons",
          src: icon?.link,
          preview: icon.small,
          position: { x: 0, y: 0, z: 0 },
          rotation: { x: 3, y: 0, z: 0 },
          scale: { x: 0.1, y: 0.1, z: 0.1 },
          isAnimation: false,
          LinkTo:"",
          iconName: icon.description ?? "",
          linkType: "",
          isLink: false,
          iconLink: "",
          animation: {
            property: "position",
            trigger:"Autoplay",
            duration: 0,
            delay: 0,
            direction: "topToBottom"
          },
          isvCard: false,
          vCard: "",
          vCardJson: {
            firstName: user?.fullName?.split(" ")[0] ?? "",
            lastName: user?.fullName?.split(" ")[1] ?? "",
            photo: user?.image ?? "",
            organization: "",
            email: user?.email || "",
            workPhone: user?.phoneNumber ?? "",
            title: "",
            url: "",
            note: user?.category?.join(', '),
            cellPhone: user?.phoneNumber ?? "",
            socialUrls: {
              custom: "",
              facebook: "",
              linkedIn: "",
              twitter: "",
            },
            homeAddress: {
              street: "",
              city: "",
              state: "",
            },
            workAddress: {
              street: "",
              city: "",
              state: "",
            },
            workUrl: "",
          },
        })
      );
    }
  };

  const AddImageFromGallary = (icon) => {
    let iconLink = icon;
    let iconLenght = iconLink.split("/");
    let iconName = iconLenght[iconLenght.length - 1];
    console.log("iconName",iconName,icon);
    dispatch(
      UPDATE_ICONS({
        id: uuidv4(),
        type: "icons",
        src: icon,
        preview: icon,
        position: { x: 0, y: 0, z: 0 },
        rotation: { x: 3, y: 0, z: 0 },
        scale: { x: 0.1, y: 0.1, z: 0.1 },
        isAnimation: false,
        LinkTo:"",
        iconName: iconName ?? "",
        linkType: "",
        isLink: false,
        iconLink: "",
        animation: {
          property: "position",
          trigger:"Autoplay",
          duration: 0,
          delay: 0,
          direction: "topToBottom"
        },
        isvCard: false,
        vCard: "",
        vCardJson: {
          firstName: user?.fullName?.split(" ")[0] ?? "",
          lastName: user?.fullName?.split(" ")[1] ?? "",
          photo: user?.image ?? "",
          organization: "",
          email: user?.email || "",
          workPhone: user?.phoneNumber ?? "",
          title: "",
          url: "",
          note: user?.category?.join(', '),
          cellPhone: user?.phoneNumber ?? "",
          socialUrls: {
            custom: "",
            facebook: "",
            linkedIn: "",
            twitter: "",
          },
          homeAddress: {
            street: "",
            city: "",
            state: "",
          },
          workAddress: {
            street: "",
            city: "",
            state: "",
          },
          workUrl: "",
        },
      })
    );
  };

  const handleFileUpload = async (e) => {
    try {
      setLoading(true);
      const files = e.target.files;
      console.log(files, "imageFiles");

      if (files) {
        const imageFile = files[0];
        const allowedTypes = ["image/jpeg", "image/png", "image/jpg"];
        if (!allowedTypes.includes(imageFile.type)) {
          setLoading(false);
          toast.error("Please select a .jpg, .jpeg, or .png file");
          return;
        }

        const fileSizeInMB = imageFile.size / (1024 * 1024);
        if (fileSizeInMB > 2) {
          setLoading(false);
          toast.error("File size should be less than 2 MB");
          return;
        }

        const options = {
          maxSizeMB: 1,
          maxWidthOrHeight: 1920,
          useWebWorker: true,
        };

        const compressedBlob = await imageCompression(imageFile, options);
        console.log(
          "compressedBlob instanceof Blob",
          compressedBlob instanceof Blob
        );
        console.log(
          `compressedBlob size ${compressedBlob.size / 1024 / 1024} MB`
        );

        // Convert Blob to File
        const compressedFile = new File([compressedBlob], imageFile.name, {
          type: imageFile.type,
          lastModified: Date.now(),
        });

        const formData = new FormData();
        formData.append("file", compressedFile);
        formData.append("assetType", "icons");
        const fileName = compressedFile.name.split(".").slice(0, -1).join(".");
        console.log(fileName, "fileName");

        const response = await instance.post(
          `/api/ar/upload/${user.username}/${arData.name}`,
          formData,
          {
            headers: {
              authorization: `Bearer ${userToken}`,
              "Content-Type": "multipart/form-data",
            },
          }
        );

        if (response.status === 200) {
          setLoading(false);
          const texture = await loadTexture(response?.data?.url);
          const textureWidth = texture.image.width;
          const textureHeight = texture.image.height;
          const aspectRatio = textureWidth / textureHeight;
          const baseSize = 0.4;

          dispatch(
            UPDATE_ICONS({
              id: uuidv4(),
              type: "icons",
              name: fileName,
              src: response?.data?.url,
              position: { x: 0, y: 0, z: 0 },
              rotation: { x: -1.5, y: 0, z: 0 },
              scale: { x: aspectRatio * baseSize, y: baseSize, z: baseSize },
              isAnimation: false,
              LinkTo:"",
              linkType: "",
              iconName: "",
              isLink: false,
              iconLink: "",
              animation: {
                property: "position",
                trigger:"Autoplay",
                duration: 0,
                delay: 0,
                direction: "topToBottom"
              },
              isvCard: false,
              vCard: "",
              vCardJson: {
                firstName: user?.fullName?.split(" ")[0] ?? "",
                lastName: user?.fullName?.split(" ")[1] ?? "",
                photo: user?.image ?? "",
                organization: "",
                email: user?.email || "",
                workPhone: user?.phoneNumber || "",
                title: "",
                url: "",
                note: user?.category?.join(', '),
                cellPhone: user?.phoneNumber || "",
                socialUrls: {
                  custom: "",
                  facebook: "",
                  linkedIn: "",
                  twitter: "",
                },
                homeAddress: {
                  street: "",
                  city: "",
                  state: "",
                },
                workAddress: {
                  street: "",
                  city: "",
                  state: "",
                },
                workUrl: "",
              },
            })
          );

          toast.success("Icon is Uploaded Successfully");

          handleClose();
        }
      }
    } catch (error) {
      setLoading(false);
      toast.error("Icon is Not uploaded");
      handleClose();
    }
  };

  return (
    <Drawer
      anchor="left"
      open={open}
      onClose={handleClose}
      className="relative"
    >
      <Box
        sx={{ width: 500 }}
        role="presentation"
        className="relative h-screen overflow-hidden"
      >
        <div>
          <div className="relative mb-9 mt-0 flex flex-col ">
            <div className="mb-2 flex  items-center justify-between bg-white p-3  text-gray-900 shadow-lg ">
              <h4 className=" text-lg font-semibold ">Icons</h4>
              <button className='cursor-pointer' onClick={()=>{
                handleClose()
              }}> <MdOutlineClear size={20} /></button>
            </div>

            <div className="mb-2 flex w-full items-center justify-between gap-1 px-1 ">
              <button
                className={`w-full rounded-lg  ${
                  tab === "upload"
                    ? "bg-[#3d2fa9] text-white"
                    : "border-2  text-[#3d2fa9] "
                }  px-2 py-3  `}
                onClick={() => setTab("upload")}
              >
                Upload Icon
              </button>

              <button
                className={`w-full rounded-lg px-2 py-3  ${
                  tab === "library"
                    ? "bg-[#3d2fa9] text-white"
                    : "border-2  text-[#3d2fa9] "
                }`}
                onClick={() => setTab("library")}
              >
                Icon Library
              </button>

              <button
                className={`w-full rounded-lg px-2 py-3  ${
                  tab === "browseIcon"
                    ? "bg-[#3d2fa9] text-white"
                    : "border-2  text-[#3d2fa9] "
                }`}
                onClick={() => setTab("browseIcon")}
              >
                Browse Icon
              </button>
            </div>

            {tab === "browseIcon" ? (
              <div className="mt-2  grid max-h-[100vh]   grid-cols-1 justify-center gap-4 overflow-y-auto px-2 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3">
                {iconTemplate?.map((icon, index) => (
                  <button
                    onClick={() => handleImageClick(icon, index)}
                    key={icon.id}
                    className={`flex h-24  cursor-pointer  overflow-hidden  hover:scale-105`}
                  >
                    <img
                      src={icon.small}
                      alt={icon.description}
                      className="flex h-24 w-full object-contain shadow-lg"
                    />
                  </button>
                ))}
              </div>
            ) : tab === "library" ? (
              galleryIcons?.length > 0 ? (
                <div className="mt-2  grid max-h-full   grid-cols-1 justify-center gap-4 overflow-y-auto px-2 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3">
                  {galleryIcons?.map((icon, index) => (
                    <div
                      key={index}
                      onClick={() => AddImageFromGallary(icon)}
                      className={`flex h-24  overflow-hidden  hover:scale-105 `}
                    >
                      <img
                        src={icon}
                        className="flex h-24 w-full object-contain shadow-lg"
                      />
                    </div>
                  ))}
                </div>
              ) : (
                <div className="mt-40 flex flex-col items-center justify-center gap-4 text-center">
                  <p className="text-md font-semibold ">
                    The Icons you will upload will be shown here
                  </p>
                </div>
              )
            ) : (
              <div>
                <div className=" mb-4 flex cursor-pointer flex-col items-center justify-center gap-1 px-2">
                  <img
                    src={upload}
                    alt="select "
                    className="h-[40%] w-[40%] rounded-lg"
                  />

                  <h1 className="pb-2 text-center text-2xl font-semibold text-gray-800">
                    Upload Icon
                  </h1>
                </div>

                <div className="px-4">
                  <label
                    htmlFor="fileInput"
                    className="flex h-full w-full items-center  justify-center rounded-xl border-[2px] border-dashed border-gray-600 p-4  dark:!border-navy-700 lg:pb-0"
                  >
                    <input
                      type="file"
                      id="fileInput"
                      accept="image/png, image/jpeg, image/jpg, image/svg"
                      className="hidden"
                      onChange={(e) => {
                        handleFileUpload(e);
                      }}
                    />

                    <div className="flex flex-col items-center justify-center">
                      <button
                        type="button"
                        onClick={() =>
                          document.getElementById("fileInput").click()
                        }
                        className="rounded-md bg-[#3d2fa9] px-4 py-2 text-white "
                      >
                        Browse
                      </button>
                      <p className="mt-4 text-lg font-semibold text-gray-900">
                        Supported Format :- JPEG, JPG, PNG
                      </p>

                      <p className="text-md my-2 text-gray-900">
                        file limit size upto 2mb
                      </p>
                    </div>
                  </label>
                </div>
              </div>
            )}
          </div>
        </div>

        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={loading}
        >
          <div className="flex flex-col items-center justify-center gap-4">
            <h1 className="font-bold">Uploading Icon</h1>
            <CircularProgress color="inherit" />
          </div>
        </Backdrop>
      </Box>
    </Drawer>
  );
};

export default AddIcons;
