import React from "react";
import { QRCodeSVG } from "qrcode.react";
import { LuNfc } from "react-icons/lu";
import { IoLocationSharp } from "react-icons/io5";
import { MdOutlineMail } from "react-icons/md";
import { FaMobileAlt } from "react-icons/fa";
import Logo from "./logo/Logo";

const CardSeven = React.forwardRef(({ username, experiencename, name }, ref) => {
  return (
    <div
      ref={ref}
      className="h-[250px] w-[400px] relative overflow-hidden bg-gradient-to-r from-gray-700 to-gray-900 shadow-lg"
    >
      <div className="absolute right-4 top-1 z-10 flex items-center justify-end">
        <LuNfc size={25} color="#FFFFFF" className="top-3" />
        <Logo color="#FFFFFF" height={35} width={35} />
      </div>
      <div className="relative flex items-center justify-center">
        <div className="p-6 relative z-10 h-full w-full text-white">
          <h2
            className={`mb-2 flex mx-w-[100px] break-all items-center justify-center break-words capitalize ${
              name.length > 20 ? "text-2xl" : "text-3xl"
            } font-bold`}
          >
            {name
              .split(" ")
              .map(
                (word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
              )
              .join(" ")}
          </h2>
        </div>
        <div className="flex h-[250px] w-1/2 flex-col items-center justify-center p-6 py-12 space-y-4">
          <QRCodeSVG
            value={`https://ar.immarsify.com/${username}/${experiencename}`}
            size={100}
            bgColor="#ffffff"
            fgColor="#000000"
            level="Q"
            includeMargin={false}
          />
        </div>
      </div>
    </div>
  );
});

export default CardSeven;

export const CardSevenBack = React.forwardRef(({ cardData }, ref) => {
  return (
    <div
      ref={ref}
      className="h-[250px] w-[400px] overflow-hidden bg-gradient-to-r from-gray-700 to-gray-900 shadow-lg text-white"
    >
      <div className="relative p-4 text-sm break-all text-white">
        <div className="mt-8">
          <h2
            className={`font-bold mb-2 text-center ${
              cardData?.companyName?.length > 20 ? "text-2xl" : "text-3xl"
            }`}
          >
            {cardData?.companyName
              ?.split(" ")
              .map(
                (word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
              )
              .join(" ")}
          </h2>
          <div className="flex justify-center">
            <div className="flex flex-col gap-2 mt-4">
              {cardData?.address && (
                <p className="text-sm flex gap-2">
                  <IoLocationSharp className="min-w-5 min-h-5" />
                  {cardData?.address}
                </p>
              )}
              {cardData?.email && (
                <p className="text-sm flex gap-2">
                  <MdOutlineMail className="min-w-5 min-h-5" />
                  {cardData?.email}
                </p>
              )}
              {cardData?.Phone && (
                <p className="text-sm flex gap-2">
                  <FaMobileAlt className="min-w-5 min-h-5" />
                  {cardData?.Phone}
                </p>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});
