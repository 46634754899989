import React, { useEffect, useState } from "react";
import ArImage from "../../../assets/Branding/ARbranding.jpg";
import { Switch, Skeleton } from "@mui/material";
import styled from "@emotion/styled";
import { useSelector, useDispatch } from "react-redux";
import toast from "react-hot-toast";
import { instance } from "common/Instance";
import CommonBackdrop from "common/CommonBackdrop";
import { SET_BRANDING_DATA } from "../../../redux/reducerSlice/arSlice";

// Custom styled Switch for MUI (matching the design in the image)
const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 28,
  height: 16,
  padding: 0,
  display: "flex",
  "&:active": {
    "& .MuiSwitch-thumb": {
      width: 10,
    },
    "& .MuiSwitch-switchBase.Mui-checked": {
      transform: "translateX(9px)",
    },
  },
  "& .MuiSwitch-switchBase": {
    padding: 2,
    "&.Mui-checked": {
      transform: "translateX(12px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: "#3182ce", // Tailwind blue-500
        opacity: 1,
      },
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "0 2px 4px 0 rgb(0 0 0 / 20%)",
    width: 12,
    height: 12,
    borderRadius: 6,
    backgroundColor: "#ffffff",
  },
  "& .MuiSwitch-track": {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor: "#e2e8f0", // Tailwind gray-200
    boxSizing: "border-box",
  },
}));

const INTITIAL_DATA = [
  {
    name: "Custom Welcome Screen",
    enabled: false,
    url: "",
    description:
      "Upload an image that will be displayed as a welcome screen when your AR experience launched",
    descripttext: "",
  },
  {
    name: "Loading Screen",
    enabled: false,
    url: "",
    description:
      "Upload an image that will be displayed as a Loading screen when your AR experience launched",
    descripttext: "",
  },
  {
    name: "Camera Watermark",
    enabled: false,
    url: "",
    description:
      "Upload an image that will be displayed as a Camera Watermark when your AR experience launched",
    descripttext: "",
  },
  {
    name: "Helper Message and Icon",
    enabled: false,
    url: "",
    description:
      "Upload an image that will be displayed as a Helper Message and Icon when your AR experience launched",
    descripttext: "",
  },
];

const Branding = () => {
  const userData = useSelector((state) => state.auth.auth.user);
  const userToken = useSelector((state) => state.auth.auth.token);
  const [isBrandingChanged, setIsBrandingChanged] = useState(false);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const branding = useSelector((state) => state.ar.brandingData);

  const handleFileChange = (event, key, name) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      const reader = new FileReader();
      reader.onloadend = () => {
        updateBranding(key, name, reader.result);
      };
      reader.readAsDataURL(selectedFile);
    }
  };

  const updateBranding = (key, name, value) => {
    let data = [...branding];
    let singleData = { ...data[key] };
    singleData[name] = value;
    data[key] = singleData;
    dispatch(SET_BRANDING_DATA(data));
    setIsBrandingChanged(true);
  };

  const handleVideoUpload = async (key, name, file) => {
    try {
      const files = file;
      if (files) {
        const imageFiles = files;
        const allowedTypes = [
          "image/jpg",
          "image/svg",
          "image/png",
          "image/jpeg",
          "image/gif",
        ];
        if (!allowedTypes.includes(imageFiles?.type)) {
          toast.error(
            "Invalid image file type. Only JPG, SVG, PNG, JPEG, and GIF files are allowed."
          );
          return;
        }
        const formData = new FormData();
        formData.append("file", imageFiles);
        formData.append("assetType", "Welcome_Screen");
        const response = await instance.post(
          `/api/user/upload_branding`,
          formData,
          {
            headers: {
              authorization: `Bearer ${userToken}`,
              "Content-Type": "multipart/form-data",
            },
          }
        );
        if (response.status === 200) {
          toast.success("Successfully uploaded");
          updateBranding(key, name, response?.data?.data?.url);
        }
      }
    } catch (error) {
      toast.error(error?.response?.data?.message ?? error.message);
    }
  };

  const fetchData = async () => {
    try {
      const response = await instance.get(
        `/api/user/user_details/${userData._id}`,
        {
          headers: {
            authorization: `Bearer ${userToken}`,
          },
        }
      );
      dispatch(SET_BRANDING_DATA(INTITIAL_DATA));
      if (response.status === 200) {
        if (response?.data?.data?.branding) {
          dispatch(
            SET_BRANDING_DATA(
              response?.data?.data?.branding?.length > 0
                ? response?.data?.data?.branding
                : INTITIAL_DATA
            )
          );
        }
      }
    } catch (error) {
      toast.error(error?.response?.data?.message ?? error.message);
      dispatch(SET_BRANDING_DATA(INTITIAL_DATA));
    }
  };

  const saveAllData = async () => {
    setLoading(true);
    try {
      const response = await instance.post(
        `/api/user/update_user_details/${userData._id}`,
        { branding },
        {
          headers: {
            authorization: `Bearer ${userToken}`,
          },
        }
      );
      if (response.status === 200) {
        toast.success(response.data?.message);
        setIsBrandingChanged(false);
        fetchData();
        setLoading(false);
      }
    } catch (error) {
      toast.error(error?.response?.data?.message ?? error.message);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [userData]);

  return (
    <div className="min-h-screen bg-gradient-to-b from-blue-50 to-white p-6">
      {isBrandingChanged && (
        <div className="flex items-center justify-end rounded-md bg-blue-800 p-2 mb-4">
          <button
            onClick={saveAllData}
            className="rounded-lg border border-gray-300 bg-white px-4 py-2 font-semibold text-gray-900 hover:bg-gray-100 hover:shadow-md transition-all duration-300"
          >
            Save
          </button>
        </div>
      )}
      {branding && branding?.length > 0 ? (
        <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 md:grid-cols-4">
          {branding?.map((item, key) => (
            <div
              key={key}
              className="w-full max-w-sm p-4 bg-white rounded-lg shadow-md hover:shadow-lg hover:-translate-y-1 transform transition-all duration-300 border border-blue-100"
            >
              <div className="flex items-center justify-between mb-4">
                <div className="flex items-center gap-3">
                  <AntSwitch
                    checked={item?.enabled}
                    name="enabled"
                    onChange={(e) => {
                      updateBranding(key, "enabled", e.target.checked);
                    }}
                    inputProps={{ "aria-label": "ant design" }}
                  />
                  <h2 className="text-lg font-bold text-blue-900">{item?.name}</h2>
                </div>
              </div>

              <div className="flex flex-col items-start gap-4">
                <p className="text-sm text-gray-700">{item?.description}</p>
                <p className="text-sm text-gray-600">
                  Supported Format: <span className="font-semibold text-blue-600">GIF</span>
                </p>
                <label className="w-full flex items-center justify-center px-4 py-2 bg-blue-100 text-blue-600 rounded-md cursor-pointer hover:bg-blue-200 hover:shadow-md transition-all duration-300">
                  <svg
                    className="h-5 w-5 mr-2"
                    fill="currentColor"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                  >
                    <path d="M16.88 9.1A4 4 0 0 1 16 17H5a5 5 0 0 1-1-9.9V7a3 3 0 0 1 4.52-2.59A4.98 4.98 0 0 1 17 8c0 .38-.04.74-.12 1.1zM11 11h3l-4-4-4 4h3v3h2v-3z" />
                  </svg>
                  Upload GIF
                  <input
                    className="hidden"
                    type="file"
                    accept="image/gif,image/svg,image/png,image/jpg,image/jpeg"
                    onChange={(e) => {
                      handleFileChange(e, key, "url");
                      const selectedFile = e.target.files[0];
                      const reader = new FileReader();
                      reader.onloadend = () => {
                        updateBranding(key, "url", reader.result);
                      };
                      reader.readAsDataURL(selectedFile);
                      handleVideoUpload(key, "url", e.target.files[0]);
                    }}
                  />
                </label>
              </div>

              <div className="mt-4 w-full">
                <div className="relative h-[200px] w-full rounded-md overflow-hidden border border-gray-200">
                  <div className="absolute left-1/2 top-0 h-1 w-10 -translate-x-1/2 rounded-b-md bg-gray-800"></div>
                  {item?.url ? (
                    <img
                      src={item?.url}
                      alt="Preview"
                      className="h-full w-full object-contain" // Ensures no overflow, fits within bounds
                    />
                  ) : (
                    <img
                      src={ArImage}
                      alt="Preview"
                      className="h-full w-full object-contain" // Ensures no overflow, fits within bounds
                    />
                  )}
                </div>
              </div>

              <div className="mt-4 w-full">
                <label className="text-sm font-medium text-gray-800">Note</label>
                <textarea
                  className="w-full mt-2 p-2 border border-gray-300 rounded-md focus:border-blue-500 focus:ring-1 focus:ring-blue-200 text-gray-700 text-sm placeholder-gray-400"
                  value={item?.descripttext}
                  placeholder="Add your notes here..."
                  name="descripttext"
                  rows="3"
                  onChange={(e) => {
                    updateBranding(key, "descripttext", e.target.value);
                  }}
                />
              </div>
            </div>
          ))}
          <CommonBackdrop
            loading={loading}
            title={"Uploading Loading Screen"}
            description={"Please do not refresh the page"}
          />
        </div>
      ) : (
        <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 md:grid-cols-4">
          {[...Array(4)].map((_, index) => (
            <Skeleton
              key={index}
              variant="rectangular"
              height={380}
              className="rounded-md bg-gray-200"
              animation="wave"
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default Branding;