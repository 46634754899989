import React, { useEffect, useRef } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Card from "components/card";
import { FaCopy } from "react-icons/fa";
import { useDispatch } from "react-redux";
import { useState } from "react";
// import QRCode from "react-qr-code";
import { useSelector } from "react-redux";
import { IoArrowBackCircleSharp } from "react-icons/io5";
import { IoMdDownload } from "react-icons/io";
import { instance} from "common/Instance";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { setViewCreated } from "../../../../redux/reducerSlice/valueSlice";
import toast from "react-hot-toast";
import { saveAs } from "file-saver";
import { toJpeg, toPng, toSvg } from "html-to-image";
// import html2canvas from "html2canvas";
import { QRCodeSVG } from "qrcode.react";
import axios from "axios";

const style = {
  display: "flex",
  alignItems: "flex-start",
  position: "absolute",
  top: "0",
  right: "0",
  width: "100vw",
  bgcolor: "background.paper",
  boxShadow: 24,
};

const Publish = ({ open, handleClose }) => {
  const [url, setUrl] = useState("");
  const [loading, setLoading] = useState(false);
  const userToken = useSelector((state) => state.auth.auth.token);
  const arData = useSelector((state) => state.ar.data);
  const userData = useSelector((state) => state.publishData);
  const viewCreated = useSelector((state) => state.value.viewCreated);
  const userName = useSelector(
    (state) => state?.auth?.auth?.user?.username ?? ""
  );
  const dispatch = useDispatch();
  const qrRef = useRef();
  console.log(viewCreated);

  useEffect(() => {
    if (open) {
      publishARExperience();
    }
  }, [open]);

  const publishARExperience = async () => {
    setLoading(true);
    try {
      const data = {
        ...arData,
        ...userData,
        userName,
      };
      delete data._id;

      const responseforFinalSubmit = await instance.post(
        `api/ar/publish_experience/${arData._id}`,
        data,
        {
          headers: {
            authorization: `Bearer ${userToken}`,
          },
        }
      );

      dispatch(setViewCreated(responseforFinalSubmit.data.data));

      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const copyToClipboard = (text) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        toast.success("Link is copied ");
      })
      .catch((error) => {
        toast.error(error?.response?.data?.message ?? error.message);
      });
  };

  // const downloadQRCode = () => {
  //   const qrCodeElement = document
  //     .getElementById("qrCode")
  //     .toDataURL("image/png")
  //     .replace("image/png", "image/octet-stream");

  //   if (qrCodeElement) {
  //     let aEl = document.createElement("a");
  //     aEl.href = qrCodeElement;
  //     aEl.download = `${arData?.name}_QR.png`;
  //     document.body.appendChild(aEl);
  //     aEl.click();
  //     document.body.removeChild(aEl);
  //     toast.success("QR code is Downloaded");
  //   }
  // };

  const downloadImage = async (imageUrl, name) => {
    try {
      const response = await axios.get(imageUrl, {
        responseType: "blob", // important
      });
      const file = new Blob([response.data], { type: "image/jpeg" });
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(file);
      link.download = `${name}-target-image.jpg`;
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
    } catch (error) {
      toast.error(error.message);
    }
  };
  console.log("qrRef", qrRef);
  const downloadQRCode = () => {
    if (qrRef.current === null) {
      return;
    }

    toJpeg(qrRef.current, { quality: 1, width: 180, height: 180 })
      .then((dataUrl) => {
        const link = document.createElement("a");
        link.download = `${arData?.name}_QR.jpeg`;
        link.href = dataUrl;
        link.click();
      })
      .catch((err) => {
        console.error("Error generating image:", err);
      });
  };

  return (
    <Modal open={open} onClose={handleClose}>
      <Box sx={style} className="min-h-screen overflow-y-scroll">
        <div className=" mt-2 flex h-screen w-full flex-wrap  lg:grid lg:grid-cols-12">
          <div className="col-span-7 -mt-5 lg:!mb-0">
            <Card extra={"w-full h-full p-[15px] bg-cover"}>
              <h2 className="flex items-center gap-2 p-2 text-3xl font-bold text-gray-700">
                {" "}
                <IoArrowBackCircleSharp
                  size={35}
                  color="#3f3f3f"
                  onClick={handleClose}
                  className="cursor-pointer"
                />{" "}
                Back To Editor
              </h2>
              <div className="flex h-fit max-w-full flex-col items-center rounded-lg  border-2  p-2">
                <h2 className="flex items-center gap-2 p-2 text-2xl font-bold text-gray-700">
                  Scan Your Target Image
                </h2>
                <p className="p-2 text-sm font-semibold text-gray-900">
                  Scan the Image using our AR Mobile Application or Web AR Link{" "}
                  <a>Learn More</a>
                </p>
                <img
                  src={arData?.targetImage?.src}
                  alt="hello"
                  className="mt-6 max-h-[400px] w-full max-w-[500px] object-contain "
                />

                <a
                  className="mt-2 flex cursor-pointer gap-2 rounded-lg bg-[#3d2fa9] px-6 py-2 text-white"
                  onClick={(e) => {
                    downloadImage(
                    arData?.targetImage?.src + "?not-from-cache-please",
                      arData?.name
                    );
                  }}
                >
                  <span>
                    <IoMdDownload size={24} />{" "}
                  </span>{" "}
                  Download Target Image
                </a>
              </div>
            </Card>
          </div>
          <div className="col-span-5 lg:!mb-0">
            <Card extra={"w-full h-screen px-[15px] bg-cover"}>
              <div className="flex flex-col  items-center gap-4">
                <div className=" flex w-[100%] items-center justify-center ">
                  <button className=" w-full rounded-lg border border-blue-400 bg-blue-500 px-14 py-1 text-white">
                    WEB AR
                  </button>
                </div>
                <div className="-mx-2 -mt-2  h-full w-full rounded-lg border-2">
                  <h2 className="mt-1 text-center text-lg font-bold text-gray-800">
                    Experience AR In Web
                  </h2>
                  <p className="text-md mt-1 px-4 text-center ">
                  Scan the Image using WEBAR Link.
                  </p>
                  <div className="mt-2 flex items-center justify-center gap-1 px-4 ">
                    <input
                      type="text"
                      className="text-md w-full rounded-md border  bg-gray-200 px-2 py-2 "
                      value={`https://sandboxar.immarsify.com/${userName}/${arData.name}`}
                      onChange={(e) => setUrl(e.target.value)}
                    />
                    <span>
                      <FaCopy
                        size={20}
                        className="cursor-pointer"
                        onClick={() =>
                          copyToClipboard(
                            `https://sandboxar.immarsify.com/${userName}/${arData.name}`
                          )
                        }
                      />
                    </span>
                  </div>
                  <div className="mb-1 mt-1 border-t-2 ">
                    <div className=" flex flex-col items-center justify-center gap-2">
                      <p className=" w-full py-2 pb-10 text-center text-lg">
                        Scan the QR code with your mobile camera
                      </p>
                      <div className="w-[180px]" ref={qrRef}>
                        <QRCodeSVG
                          value={`https://sandboxar.immarsify.com/${userName}/${arData.name}`}
                          fgColor="#1A3066"
                         className="h-full w-full text-[#1A3066] "
                          includeMargin
                        />
                      </div>

                      <button
                        onClick={downloadQRCode} // Call the download function on button click
                        className="mt-4 rounded  bg-[#3d2fa9] px-4 py-2 text-white"
                      >
                        Download QR Code
                      </button>
                      <a
                        href={`https://sandboxar.immarsify.com/${userName}/${arData.name}`}
                        target="_blank"
                        className="rounded-lg border px-2"
                      >
                        Open via link
                      </a>
                      <ol className="mt-2 flex flex-col gap-1" type="1">
                        <li className="text-sm">
                          Open the Camera on your Mobile and Tablet
                        </li>
                        <li className="text-sm">
                          Point your camera to QR code
                        </li>
                        <li className="text-sm">
                          Copy the URL and paste it to your Mobile web browser
                        </li>
                        <li className="text-sm">
                          Scan your target image using Immarsify viewer
                        </li>
                      </ol>
                    </div>
                  </div>
                </div>
              </div>
            </Card>
          </div>
        </div>
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={loading}
        >
          <div className="flex flex-col items-center justify-center gap-2">
            <h1 className="font-bold">Publishing AR Experience</h1>
            <CircularProgress color="inherit" />
          </div>
        </Backdrop>
      </Box>
    </Modal>
  );
};
export default Publish;
