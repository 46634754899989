import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import * as THREE from "three";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls.js";
import { TransformControls } from "three/examples/jsm/controls/TransformControls.js";
import { Modal, Box } from "@mui/material";
import {
  createPhotoMesh,
  createTextMesh,
  createVideoMesh,
  createIconMesh,
  createTargetMesh,
  createDefaultPosition,
  create3DMesh,
  createDocumentMesh,
  createCarousel,
  createGroupAnchor,
  createResumeMesh,
} from "./EditorUtils";
import { instance, AWS_DATA_PREFIX } from "common/Instance";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import {
  SET_ADD_GROUPS,
  UPDATE_PHOTO_FOR_EDITOR,
  UPDATE_CAROUSEL_FOR_EDITOR,
  UPDATE_TEXT_FOR_EDITOR,
  UPDATE_VIDEO_FOR_EDITOR,
  UPDATE_ICON_FOR_EDITOR,
  UPDATE_TARGET_FOR_EDITOR,
  UPDATE_GROUP_FOR_EDITOR,
  REMOVE_PHOTO,
  REMOVE_VIDEO,
  REMOVE_TEXT,
  REMOVE_ICONS,
  SET_LOADED_AR,
  UPDATE_CENTER_FOR_EDITOR,
  REMOVE_MODEL,
  UPDATE_MODEL_FOR_EDITOR,
  SET_TARGET_HIDDEN_VALUE,
  SET_IS_VISIBLE_ON_LOAD,
  REMOVE_DOCUMENT,
  UPDATE_DOCUMENT_FOR_EDITOR,
  SET_IS_ENQUIRY,
  REMOVE_CAROUSEL,
  REMOVE_RESUME,
  UPDATE_RESUME_FOR_EDITOR,
  REMOVE_GROUP,
  SET_HISTORY_CURRENT_PAGE,
  SET_HISTORY_TOTAL_PAGE,
  SET_STICK_TO_CAMERA,
  SET_HIDE_CAMERA,
} from "../../../../redux/reducerSlice/arSlice";
import toast from "react-hot-toast";

const PREVIEW_CONFIG = {
  MAX_WIDTH: 600,
  ANIMATION_DURATION: 300,
};

const zoom = 35;
const aspect = window.innerWidth / window.innerHeight;

const scene = new THREE.Scene();
const camera = new THREE.PerspectiveCamera(zoom, aspect, 0.1, 1000);
const ambientLight = new THREE.AmbientLight(0xffffff, 2);
const renderer = new THREE.WebGLRenderer({ antialias: true });

const orbitControls = new OrbitControls(camera, renderer.domElement);
const transformControls = new TransformControls(camera, renderer.domElement);
renderer.outputColorSpace = THREE.SRGBColorSpace;
renderer.setClearAlpha(0);
camera.position.set(0, 10, 0);
scene.add(ambientLight);
orbitControls.dampingFactor = 0.1;
orbitControls.enableDamping = true;
orbitControls.maxDistance = 15;
orbitControls.minDistance = 2;

export const loadingManager = new THREE.LoadingManager();

export default function FinalPreview({ open, handleClose }) {
  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);
  const user = useSelector((state) => state.auth.auth.user);
  const [deleteComfirmationDialog, setDeleteComfirmationDialog] = useState(false);
  const [isMounted, setIsMounted] = useState(false);
  const templateID = queryParams.get("templateID");
  const dispatch = useDispatch();
  const ardata = useSelector((state) => state.ar.data);
  const [photoMeshes, setPhotoMeshes] = useState([]);
  const [resumeMeshes, setResumeMeshes] = useState([]);
  const [textMeshes, setTextMeshes] = useState([]);
  const [videoMeshes, setVideoMeshes] = useState([]);
  const [iconMeshes, setIconMeshes] = useState([]);
  const [documentMeshes, setDocumentMeshes] = useState([]);
  const [threeDMeshes, setThreeDMeshes] = useState([]);
  const [carouselMesh, setCarouselMesh] = useState([]);
  const [groupMeshes, setGroupMeshes] = useState([]);
  const [targetMesh, setTargetMesh] = useState(null);
  const [centerPosition, setCenterPosition] = useState(null);
  const authToken = useSelector((state) => state?.auth?.auth?.token);
  const [mode, setMode] = useState("translate");
  const [whatsClicked, setWhatsClicked] = useState(null);
  const [isClicked, setIsClicked] = useState(false);
  const mountRef = useRef();
  transformControls.setSize(1.5, 1.5);

  const gridHelper = new THREE.GridHelper(1, 1);
  gridHelper.material.opacity = 0.2;
  gridHelper.material.transparent = true;
  gridHelper.rotation.x = Math.PI / 2;

  useEffect(() => {
    if (open) {
      setIsMounted(true);
      gridHelper.rotation.x = Math.PI / 2;
    } else {
      setIsMounted(false);
    }
  }, [open]);

  const handleResize = () => {
    if (!mountRef.current) return;
    const { clientWidth, clientHeight } = mountRef.current;
    renderer.setSize(clientWidth, clientHeight);
    camera.aspect = clientWidth / clientHeight;
    camera.updateProjectionMatrix();
  };

  loadingManager.onProgress = () => dispatch(SET_LOADED_AR(true));
  loadingManager.onLoad = () => dispatch(SET_LOADED_AR(false));

  let isDragging = false;
  let mouseDownPosition = { x: 0, y: 0 };

  function handleMouseDown(event) {
    mouseDownPosition.x = event.clientX;
    mouseDownPosition.y = event.clientY;
    isDragging = false;
    document.addEventListener("mousemove", handleMouseMove);
    document.addEventListener("mouseup", handleMouseUp);
  }

  function handleMouseMove(event) {
    const dx = event.clientX - mouseDownPosition.x;
    const dy = event.clientY - mouseDownPosition.y;
    if (Math.sqrt(dx * dx + dy * dy) > 5) {
      isDragging = true;
    }
  }

  function handleMouseUp(event) {
    document.removeEventListener("mousemove", handleMouseMove);
    document.removeEventListener("mouseup", handleMouseUp);
    if (!isDragging) {
      onMouseClick(event);
    }
    isDragging = false;
  }

  function onMouseClick(event) {
    const rect = renderer.domElement.getBoundingClientRect();
    const mouse = new THREE.Vector2();
    mouse.x = ((event.clientX - rect.left) / rect.width) * 2 - 1;
    mouse.y = -((event.clientY - rect.top) / rect.height) * 2 + 1;
    const raycaster = new THREE.Raycaster();
    raycaster.setFromCamera(mouse, camera);

    const clickableMeshes = scene.children.filter(
      (obj) =>
        obj.type === "Mesh" ||
        obj.type1 === "Icon" ||
        obj.type1 === "Model3d" ||
        obj.type === "Group"
    );
    const intersects = raycaster.intersectObjects(clickableMeshes);

    if (intersects.length > 0) {
      setIsClicked(true);
      const clickedObject = intersects[0].object;
      if (clickedObject.geometry.type === "TextGeometry") {
        transformControls.attach(clickedObject.parent);
        clickedObject.parent.userData.clicked = true;
        setWhatsClicked(clickedObject.parent);
      } else if (clickedObject.userData.type === "target_img") {
        transformControls.attach(clickedObject);
        clickedObject.userData.clicked = true;
        setWhatsClicked(clickedObject);
        transformControls.setMode("scale");
        setMode("scale");
      } else if (clickedObject.userData.type === "carousel") {
        transformControls.attach(clickedObject.parent);
        clickedObject.parent.userData.clicked = true;
        setWhatsClicked(clickedObject.parent);
        transformControls.setMode("translate");
        setMode("translate");
      } else {
        transformControls.attach(clickedObject);
        clickedObject.userData.clicked = true;
        setWhatsClicked(clickedObject);
        transformControls.setMode("translate");
        setMode("translate");
      }
    } else {
      setWhatsClicked(null);
      setIsClicked(false);
      transformControls.detach();
    }
  }

  function keyUp(event) {
    if (event.keyCode === 16) {
      transformControls.setTranslationSnap(null);
      transformControls.setRotationSnap(null);
      transformControls.setScaleSnap(null);
    }
  }

  function deleteConformation(event) {
    if (event.keyCode === 46) {
      setDeleteComfirmationDialog(true);
    }
  }

  const DeleteAssetdata = async (url) => {
    const newUrl = decodeURIComponent(url);
    const output = newUrl.replace(AWS_DATA_PREFIX, "");
    const result = output.startsWith("/") ? output.substring(1) : output;

    try {
      await instance.post(
        `/api/ar/delete_file`,
        { fileKey: result },
        { headers: { authorization: `Bearer ${authToken}` } }
      );
    } catch (error) {
      toast.error(error?.response?.data?.message ?? error.message);
    }
  };

  function handleDeleteKey(whatsClicked) {
    if (whatsClicked) {
      const { type, id, content, thumbnail } = whatsClicked.userData || {};
      switch (type) {
        case "photo":
          dispatch(REMOVE_PHOTO({ id }));
          setWhatsClicked(null);
          DeleteAssetdata(thumbnail);
          break;
        case "text":
          dispatch(REMOVE_TEXT({ body: content }));
          setWhatsClicked(null);
          break;
        case "video":
          dispatch(REMOVE_VIDEO({ id }));
          setWhatsClicked(null);
          DeleteAssetdata(thumbnail);
          break;
        case "icons":
          dispatch(REMOVE_ICONS({ id }));
          setWhatsClicked(null);
          DeleteAssetdata(thumbnail);
          break;
        case "Model3d":
          dispatch(REMOVE_MODEL({ id }));
          setWhatsClicked(null);
          DeleteAssetdata(thumbnail);
          break;
        case "documents":
          dispatch(REMOVE_DOCUMENT({ id }));
          setWhatsClicked(null);
          DeleteAssetdata(thumbnail);
          break;
        case "Group":
          dispatch(REMOVE_CAROUSEL({ id }));
          setWhatsClicked(null);
          break;
        case "anchor":
          dispatch(REMOVE_GROUP({ id }));
          setWhatsClicked(null);
          break;
        case "resume":
          dispatch(REMOVE_RESUME({ id }));
          setWhatsClicked(null);
          break;
        default:
          break;
      }
    }
  }

  const deleteKeyHandler = useCallback(
    (event) => deleteConformation(event),
    [whatsClicked]
  );

  useEffect(() => {
    document.addEventListener("keydown", deleteKeyHandler);
    return () => document.removeEventListener("keydown", deleteKeyHandler);
  }, [deleteKeyHandler]);

  function keyDown(event) {
    switch (event.keyCode) {
      case 16:
        transformControls.setTranslationSnap(0.25);
        transformControls.setRotationSnap(THREE.MathUtils.degToRad(15));
        transformControls.setScaleSnap(0.25);
        break;
      case 27:
        transformControls.reset();
        transformControls.detach();
        break;
    }
  }

  function transformControlsDrag(event) {
    orbitControls.enabled = !event.value;
  }

  const createPhotoMeshMemo = useCallback(
    async (photo, index) => await createPhotoMesh(photo, index),
    []
  );
  const createResumeMeshMemo = useCallback(
    async (resume, index) => await createResumeMesh(resume, index),
    []
  );
  const createTextMeshMemo = useCallback(
    async (text, index) => await createTextMesh(text, index),
    []
  );
  const createVideoMeshMemo = useCallback(
    async (video, index) => await createVideoMesh(video, index),
    []
  );
  const createIconMeshMemo = useCallback(
    async (icon, index) => await createIconMesh(icon, index),
    []
  );
  const create3DMeshMemo = useCallback(
    async (model, index) => await create3DMesh(model, index),
    []
  );
  const createGroup = useCallback(
    async (anchor, index) => await createGroupAnchor(anchor, index),
    []
  );
  const createCarouselMesh = useCallback(
    async (carousel, index) => await createCarousel(carousel, index),
    []
  );
  const createTargetMeshMemo = useCallback(
    async (targetImage) => await createTargetMesh(targetImage),
    []
  );
  const createDefaultPositionMemo = useCallback(
    async (data) => await createDefaultPosition(data),
    []
  );

  useEffect(() => {
    const fetchPhotoMeshes = async () => {
      const meshes = await Promise.all(ardata.photos.map(createPhotoMeshMemo));
      setPhotoMeshes(meshes);
    };
    fetchPhotoMeshes();
  }, [ardata.photos, createPhotoMeshMemo]);

  useEffect(() => {
    if (ardata?.resume) {
      const fetchResumeMeshes = async () => {
        const meshes = await Promise.all(ardata.resume.map(createResumeMeshMemo));
        setResumeMeshes(meshes);
      };
      fetchResumeMeshes();
    }
  }, [ardata?.resume, createResumeMeshMemo]);

  useEffect(() => {
    const fetchTextMeshes = async () => {
      const meshes = await Promise.all(ardata.text.map(createTextMeshMemo));
      setTextMeshes(meshes);
    };
    fetchTextMeshes();
  }, [ardata.text, createTextMeshMemo]);

  useEffect(() => {
    const fetchVideoMeshes = async () => {
      const meshes = await Promise.all(ardata.videos.map(createVideoMeshMemo));
      setVideoMeshes(meshes);
    };
    fetchVideoMeshes();
  }, [ardata.videos, createVideoMeshMemo]);

  useEffect(() => {
    if (ardata?.carousel) {
      const fetchCarouselMeshes = async () => {
        const meshes = await Promise.all(ardata.carousel.map(createCarouselMesh));
        setCarouselMesh(meshes);
      };
      fetchCarouselMeshes();
    }
  }, [ardata?.carousel, createCarouselMesh]);

  useEffect(() => {
    const fetchIconMeshes = async () => {
      const meshes = await Promise.all(ardata.icons.map(createIconMeshMemo));
      setIconMeshes(meshes);
    };
    fetchIconMeshes();
  }, [ardata.icons, createIconMeshMemo]);

  useEffect(() => {
    if (ardata?.documents) {
      const fetchDocumentMeshes = async () => {
        const meshes = await Promise.all(ardata.documents.map(createDocumentMesh));
        setDocumentMeshes(meshes);
      };
      fetchDocumentMeshes();
    }
  }, [ardata.documents]);

  useEffect(() => {
    if (ardata?.anchor) {
      const fetchGroupMeshes = async () => {
        const meshes = await Promise.all(ardata.anchor.map(createGroup));
        setGroupMeshes(meshes);
      };
      fetchGroupMeshes();
    }
  }, [ardata?.anchor, createGroup]);

  useEffect(() => {
    if (ardata?.model) {
      const fetch3DMeshes = async () => {
        const meshes = await Promise.all(ardata.model.map(create3DMeshMemo));
        setThreeDMeshes(meshes);
      };
      fetch3DMeshes();
    }
  }, [ardata?.model, create3DMeshMemo]);

  useEffect(() => {
    const fetchTargetMesh = async () => {
      const mesh = await createTargetMeshMemo(ardata?.targetImage);
      setTargetMesh(mesh);
    };
    fetchTargetMesh();
  }, [ardata.targetImage, createTargetMeshMemo]);

  useEffect(() => {
    const fetchCenterPosition = async () => {
      const position = await createDefaultPositionMemo(ardata);
      setCenterPosition(position);
    };
    fetchCenterPosition();
  }, [ardata, createDefaultPositionMemo]);

  const meshArray = useMemo(
    () =>
      [
        ...photoMeshes,
        ...textMeshes,
        ...videoMeshes,
        ...iconMeshes,
        ...threeDMeshes,
        ...documentMeshes,
        ...carouselMesh,
        ...resumeMeshes,
        ...groupMeshes,
        centerPosition,
        targetMesh,
      ].filter(Boolean),
    [
      photoMeshes,
      textMeshes,
      videoMeshes,
      iconMeshes,
      threeDMeshes,
      documentMeshes,
      carouselMesh,
      resumeMeshes,
      groupMeshes,
      centerPosition,
      targetMesh,
    ]
  );

  useEffect(() => {
    meshArray.forEach((mesh) => {
      if (mesh && !scene.children.includes(mesh)) {
        scene.add(mesh);
      }
    });

    return () => {
      meshArray.forEach((mesh) => {
        if (mesh) {
          mesh.material?.dispose();
          mesh.geometry?.dispose();
          scene.remove(mesh);
        }
      });
    };
  }, [meshArray]);

  function transformControlsMouseUp() {
    const object = transformControls.object;
    if (!object) return;

    const data = {
      id: object.userData.id,
      type: object.userData.type,
      position: { x: object.position.x, y: object.position.y, z: object.position.z },
      rotation: { x: object.rotation.x, y: object.rotation.y, z: object.rotation.z },
      scale: { x: object.scale.x, y: object.scale.y, z: object.scale.z },
    };

    switch (object.userData.type) {
      case "target_img":
        dispatch(UPDATE_TARGET_FOR_EDITOR(data));
        break;
      case "photo":
        dispatch(UPDATE_PHOTO_FOR_EDITOR(data));
        break;
      case "text":
        dispatch(UPDATE_TEXT_FOR_EDITOR(data));
        break;
      case "video":
        dispatch(UPDATE_VIDEO_FOR_EDITOR(data));
        break;
      case "icons":
        dispatch(UPDATE_ICON_FOR_EDITOR(data));
        break;
      case "Model3d":
        dispatch(UPDATE_MODEL_FOR_EDITOR(data));
        break;
      case "documents":
        dispatch(UPDATE_DOCUMENT_FOR_EDITOR(data));
        break;
      case "Group":
        dispatch(UPDATE_CAROUSEL_FOR_EDITOR(data));
        break;
      case "anchor":
        dispatch(UPDATE_GROUP_FOR_EDITOR(data));
        break;
      case "centerPosition":
        dispatch(UPDATE_CENTER_FOR_EDITOR(data));
        break;
      case "resume":
        dispatch(UPDATE_RESUME_FOR_EDITOR(data));
        break;
      default:
        break;
    }
    setWhatsClicked(object);
  }

  function render() {
    renderer.render(scene, camera);
    orbitControls.update();
  }

  useEffect(() => {
    if (!isMounted || !mountRef.current) return;

    scene.add(gridHelper, transformControls);
    const mount = mountRef.current;
    mount.appendChild(renderer.domElement);
    handleResize();

    let animationFrameId;
    const animate = () => {
      animationFrameId = requestAnimationFrame(animate);
      orbitControls.update();
      render();
    };
    animate();

    const eventHandlers = [
      { target: transformControls, event: "change", handler: render },
      { target: transformControls, event: "mouseUp", handler: transformControlsMouseUp },
      { target: transformControls, event: "dragging-changed", handler: transformControlsDrag },
      { target: window, event: "keydown", handler: keyDown },
      { target: window, event: "keyup", handler: keyUp },
      { target: window, event: "resize", handler: handleResize },
      { target: mount, event: "mousedown", handler: handleMouseDown },
    ];

    eventHandlers.forEach(({ target, event, handler }) =>
      target.addEventListener(event, handler)
    );

    if (whatsClicked) {
      let objectToAttach = meshArray.find(
        (mesh) => mesh.userData.id === whatsClicked.userData.id
      );
      if (whatsClicked.userData.type === "target_img") {
        objectToAttach = meshArray.find(
          (mesh) => mesh.userData.type === whatsClicked.userData.type
        );
      }
      if (
        objectToAttach?.type === "Mesh" ||
        objectToAttach?.type1 === "Icon" ||
        objectToAttach?.type1 === "Model3d"
      ) {
        transformControls.attach(objectToAttach);
      } else {
        transformControls.detach();
      }
    }

    return () => {
      cancelAnimationFrame(animationFrameId);
      eventHandlers.forEach(({ target, event, handler }) =>
        target.removeEventListener(event, handler)
      );
      renderer.dispose();
      if (mount.contains(renderer.domElement)) {
        mount.removeChild(renderer.domElement);
      }
      transformControls.detach();
      scene.remove(gridHelper, transformControls);
    };
  }, [isMounted, meshArray, whatsClicked]);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "60%",
    maxWidth: PREVIEW_CONFIG.MAX_WIDTH,
    bgcolor: "background.paper",
    border: "none",
    boxShadow: 24,
    outline: "none",
    borderRadius: 2,
    transition: `all ${PREVIEW_CONFIG.ANIMATION_DURATION}ms ease-in-out`,
    overflow: "hidden",
    maxHeight: "90vh",
  };

  return (
    <Modal open={open} onClose={handleClose} aria-labelledby="modal-title" closeAfterTransition>
      <Box sx={style}>
        <div className="flex w-full items-center justify-center rounded-t-lg bg-gradient-to-r from-indigo-500 from-10% via-blue-500 via-50% to-green-500 to-90% p-1.5">
          <h1 className="text-sm font-semibold capitalize text-white">AR Preview</h1>
        </div>
        <div className="editor-main flex flex-col justify-center overflow-hidden overflow-y-auto bg-white dark:text-white md:flex-row md:gap-0 md:overflow-hidden">
          <div className="relative h-[60vh] w-full">
            <div
              ref={mountRef}
              className="h-full bg-gray-100 dark:bg-gray-800 sm:w-full"
            ></div>
          </div>
        </div>
      </Box>
    </Modal>
  );
}