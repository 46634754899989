// src/Card.js
import React from "react";
import { QRCodeSVG } from "qrcode.react";
import { LuNfc } from "react-icons/lu";
import { IoLocationSharp } from "react-icons/io5";
import { MdOutlineMail } from "react-icons/md";
import { FaMobileAlt } from "react-icons/fa";
import NfcImmarsify from "assets/svg/immarsifyM.svg";

const formatName = (name) =>
  name
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(" ");

const CardOne = React.forwardRef(({ username, experiencename, name }, ref) => {
  return (
    <div
      ref={ref}
      className="relative flex h-[250px] w-[400px] flex-col overflow-hidden border bg-black p-3 font-cardFont shadow-lg"
    >
      <div className="absolute right-2 top-2 flex items-center justify-end">
        <LuNfc size={25} color="#fdd446" className="bg-black" />
        <img src={NfcImmarsify} className="right-1 top-4 h-4 w-7" />
      </div>
      <div className="mt-2.5 flex h-full w-full flex-col items-center justify-center p-2.5">
        <p className={`mb-2 text-center font-bold text-[#fdd446] ${name.length > 20 ? "text-2xl" : "text-3xl"}`}>{formatName(name)}</p>
        <QRCodeSVG
          value={`https://ar.immarsify.com/${username}/${experiencename}`}
          fgColor="#fdd446"
          bgColor="transparent"
          className="h-[115px] w-[115px]"
        />
        <div className="mt-4 text-md font-semibold italic tracking-wide text-[#fdd446]">
          Business की कहानी, Card की जुबानी
        </div>
      </div>
    </div>
  );
});

export default CardOne;

export const CardOneBackSide = React.forwardRef(({ cardData }, ref) => {
  return (
    <div
      ref={ref}
      className="relative flex h-[250px] w-[400px] flex-col overflow-hidden border bg-black p-3 font-cardFont shadow-lg"
    >
      <div className="absolute right-2 top-2 flex items-center justify-end">
        <LuNfc size={25} color="#fdd446" className="bg-black" />
        <img src={NfcImmarsify} className="right-1 top-4 h-4 w-7" />
      </div>
      <div className="relative p-4 text-sm text-yellow-300">
        <h2 className={`mb-2 text-center font-bold ${cardData?.companyName?.length > 20 ? "text-2xl" : "text-3xl"}`}>
          {formatName(cardData?.companyName || "")}
        </h2>
        <div className="mt-2 flex justify-center">
          <div className="flex flex-col gap-2">
            {cardData?.address && (
              <p className="flex gap-2 text-sm">
                <IoLocationSharp size={20} />
                {cardData?.address}
              </p>
            )}
            {cardData?.email && (
              <p className="flex gap-2 text-sm">
                <MdOutlineMail size={20} />
                {cardData?.email}
              </p>
            )}
            {cardData?.Phone && (
              <p className="flex gap-2 text-sm">
                <FaMobileAlt size={20} />
                {cardData?.Phone}
              </p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
});
