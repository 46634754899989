import { AvatarCreator, AvatarCreatorConfig, AvatarExportedEvent } from "@readyplayerme/react-avatar-creator";
import { Modal, Box } from "@mui/material";
import { SET_3DMODEL } from "../../../../redux/reducerSlice/arSlice";
import { useDispatch } from "react-redux";
import { v4 as uuidv4 } from "uuid";

const style = { width: "100%", height: "100%", border: "none" };

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "60vw", // Set fixed width
  height: "80vh",
  bgcolor: "background.paper",
  boxShadow: 24,
  display: "flex",
  justifyContent: "center", // Center AvatarCreator horizontally
  alignItems: "center", // Center AvatarCreator vertically
};

const config: AvatarCreatorConfig = {
  clearCache: true,
  bodyType: "fullbody",
  quickStart: false,
  language: "en",
};

export default function AddReadyPlayerme({ open, handleCloseReady, handleClose }) {
  const dispatch = useDispatch();

  const handleOnAvatarExported = (event) => {
    dispatch(
      SET_3DMODEL({
        id: uuidv4(),
        type: "model",
        src: event?.data?.url,
        position: { x: 0, y: 0, z: 0 },
        rotation: { x: -1.5, y: 0, z: 0 },
        scale: { x: 0.5, y: 0.5, z: 0.5 },
        isAnimation: false,
        isAutorotate: false,
        animation: {
          duration: 0,
          delay: 0,
          direction: "topToBottom",
        },
      })
    );

    handleClose()
    handleCloseReady()
  };

  return (
    <Modal open={open} onClose={handleCloseReady}>
      <Box sx={modalStyle}>
        <AvatarCreator subdomain="demo" config={config} style={style} onAvatarExported={handleOnAvatarExported} />
      </Box>
    </Modal>
  );
}
