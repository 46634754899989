import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Attach from "./attach";
import Animation from "./animation";
import { SET_EMITTER_PROPERTIES } from "../../../../../redux/reducerSlice/arSlice";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import UploadTexture from "./UploadTexture";

function ParticleEmitterEditorSection({ whatsClicked }) {
  const dispatch = useDispatch();
  const ardata = useSelector((state) => state.ar.data);
  const anchor = useSelector((state) => state?.ar?.data?.anchor) || null;
  const selectedItem = ardata?.emitter?.find((data) => data?.id === whatsClicked?.userData?.id);
  const { id } = selectedItem || {};
  const [openModal, setOpenModal] = useState(null);

  const handleOpenModal = (modal) => setOpenModal(modal);
  const handleCloseModal = () => setOpenModal(null);
 
  return (
    <div>
      {whatsClicked?.userData?.type === "emitter" && (
        <>
          <div className="mt-2 px-2">
            <div className="bg-white py-2 px-4 rounded-md shadow-md">
              <p className="py-1">Particle Properties</p>

              <div className="mx-2 my-1 flex flex-col gap-4 bg-white text-sm ">
                
                <div className="flex flex-col justify-center">
                  <div className="text-gray-900">Particle Size</div>
                  <input
                    id="particleSize"
                    type="number"
                    className="w-full border p-1"
                    value={ardata?.emitter?.find(
                      (data) => data?.id === whatsClicked?.userData?.id
                      )?.particleProperties?.particleSize}
                      onChange={(event) => {
                        const action = {
                        parentKey: "particleProperties",
                        key: "particleSize",
                        value: event.target.value,
                        id: whatsClicked?.userData?.id,
                      };
                      dispatch(SET_EMITTER_PROPERTIES(action));
                      }}
                  />
                </div>

                <div className="flex flex-col justify-center">
                  <div className="text-gray-900">Lifetime</div>
                  <input
                      id="lifetime"
                      type="number"
                      className="w-full border p-1"
                      value={ardata?.emitter?.find(
                        (data) => data?.id === whatsClicked?.userData?.id
                        )?.particleProperties?.lifetime}
                      onChange={(event) => {
                        const action = {
                        parentKey: "particleProperties",
                        key: "lifetime",
                        value: event.target.value,
                        id: whatsClicked?.userData?.id,
                      };
                      dispatch(SET_EMITTER_PROPERTIES(action));
                      }}
                  />
                </div>

                <div className="flex flex-col justify-center">
                  <div className="text-gray-900">Shape</div>
                  <select
                      id="shape"
                      className="w-full border p-1"
                      value={ardata?.emitter?.find(
                        (data) => data?.id === whatsClicked?.userData?.id
                        )?.particleProperties?.shape || ""
                      }
                      onChange={(event) => {
                      const action = {
                          parentKey: "particleProperties",
                          key: "shape",
                          value: event.target.value,
                          id: whatsClicked?.userData?.id,
                      };
                      dispatch(SET_EMITTER_PROPERTIES(action));
                      }}
                  >
                    <option value="box">Box</option>
                    <option value="capsule">Capsule</option>
                    <option value="cone">Cone</option>
                    <option value="cylinder">Cylinder</option>
                    <option value="ring">Ring</option>
                    <option value="sphere">Sphere</option>
                    <option value="cube">Cube</option>
                    <option value="circle">Circle</option> 
                    {/* More options can be added here */}
                  </select>
                </div>

                <div className="flex flex-col justify-center">
                  <div className="text-gray-900">Color</div>
                  <input
                      id="color"
                      type="color"
                      className="w-full border p-1"
                      value={ardata?.emitter?.find(
                        (data) => data?.id === whatsClicked?.userData?.id
                        )?.particleProperties?.color || ""
                      }
                      onChange={(event) => {
                      const action = {
                          parentKey: "particleProperties",
                          key: "color",
                          value: event.target.value,
                          id: whatsClicked?.userData?.id,
                      };
                      dispatch(SET_EMITTER_PROPERTIES(action));
                      }}
                  />
                </div>

                <div className="flex flex-col justify-center">
                  <div className="text-gray-900">Texture</div>
                  <div className="flex w-full items-center justify-center">
                    <button
                      className="w-full cursor-pointer bg-[#3D2FA9] rounded-md p-1 py-2 text-center text-white"
                      onClick={handleOpenModal}
                    >
                      Upload Texture
                    </button>
                    <UploadTexture
                      openModal={openModal}
                      handleCloseModal={handleCloseModal}
                      id={id}
                    />
                  </div>
                </div>

                <div className="flex flex-col justify-center">
                  <FormControlLabel
                  control={
                      <Checkbox
                      checked={ardata?.emitter?.find((data) => data?.id === whatsClicked?.userData?.id)?.particleProperties?.glowing}
                      onChange={(e) => {
                          const action = {
                            parentKey: "particleProperties",
                            key: "glowing",
                          value: e.target.checked,
                          id: whatsClicked?.userData?.id,
                          };
                          dispatch(SET_EMITTER_PROPERTIES(action));
                      }}
                      />
                  }
                  label={<span style={{ fontSize: 14 }}>Glowing</span>}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="mt-2 px-2">
            <div className="bg-white py-2 px-4 rounded-md shadow-md">
              <p className="py-1">Emitter Properties</p>

              <div className="mx-2 my-1 flex flex-col gap-4 bg-white text-sm ">
                <div className="flex flex-col justify-center">
                  <div className="text-gray-900">Duration</div>
                  <input
                      id="duration"
                      type="number"
                      className="w-full border p-1"
                      value={ardata?.emitter?.find(
                        (data) => data?.id === whatsClicked?.userData?.id
                        )?.emitterProperties?.duration}
                      onChange={(event) => {
                        const action = {
                        parentKey: "emitterProperties",
                        key: "duration",
                        value: event.target.value,
                        id: whatsClicked?.userData?.id,
                      };
                      dispatch(SET_EMITTER_PROPERTIES(action));
                      }}
                  />
                </div>

                <div className="flex flex-col justify-center">
                  <div className="text-gray-900">Speed</div>
                  <input
                      id="speed"
                      type="number"
                      className="w-full border p-1"
                      value={ardata?.emitter?.find(
                        (data) => data?.id === whatsClicked?.userData?.id
                        )?.emitterProperties?.speed}
                      onChange={(event) => {
                        const action = {
                        parentKey: "emitterProperties",
                        key: "speed",
                        value: event.target.value,
                        id: whatsClicked?.userData?.id,
                      };
                      dispatch(SET_EMITTER_PROPERTIES(action));
                      }}
                  />
                </div>

                <div className="flex flex-col justify-center">
                  <div className="text-gray-900">Frequency</div>
                  <input
                      id="frequency"
                      type="number"
                      className="w-full border p-1"
                      value={ardata?.emitter?.find(
                        (data) => data?.id === whatsClicked?.userData?.id
                        )?.emitterProperties?.frequency}
                      onChange={(event) => {
                        const action = {
                        parentKey: "emitterProperties",
                        key: "frequency",
                        value: event.target.value,
                        id: whatsClicked?.userData?.id,
                      };
                      dispatch(SET_EMITTER_PROPERTIES(action));
                      }}
                  />
                </div>

                <div className="flex flex-col justify-center">
                  <div className="text-gray-900">Emitter Angles</div>
                  <div className="flex flex-row justify-center">
                    <div>
                      <span className="text-gray-900 text-sm">Left : </span>
                      <input
                          id="left"
                          type="number"
                          className="w-[40%] border p-1"
                          value={ardata?.emitter?.find(
                            (data) => data?.id === whatsClicked?.userData?.id
                            )?.emitterProperties?.left}
                          onChange={(event) => {
                            const action = {
                            parentKey: "emitterProperties",
                            key: "left",
                            value: event.target.value,
                            id: whatsClicked?.userData?.id,
                          };
                          dispatch(SET_EMITTER_PROPERTIES(action));
                          }}
                      />
                    </div>
                    <div>
                      <span className="text-gray-900 text-sm">Right : </span>
                      <input
                          id="right"
                          type="number"
                          className="w-[40%] border p-1"
                          value={ardata?.emitter?.find(
                            (data) => data?.id === whatsClicked?.userData?.id
                            )?.emitterProperties?.right}
                          onChange={(event) => {
                            const action = {
                            parentKey: "emitterProperties",
                            key: "right",
                            value: event.target.value,
                            id: whatsClicked?.userData?.id,
                          };
                          dispatch(SET_EMITTER_PROPERTIES(action));
                          }}
                      />
                    </div>
                  </div>
                </div>

                <div className="flex flex-col justify-center">
                  <FormControlLabel
                  control={
                      <Checkbox
                      checked={ardata?.emitter?.find((data) => data?.id === whatsClicked?.userData?.id)?.emitterProperties?.loop}
                      onChange={(e) => {
                          const action = {
                            parentKey: "emitterProperties",
                            key: "loop",
                            value: e.target.checked,
                            id: whatsClicked?.userData?.id,
                          };
                          dispatch(SET_EMITTER_PROPERTIES(action));
                      }}
                      />
                  }
                  label={<span style={{ fontSize: 14 }}>Loop</span>}
                  />
                </div>

                <div className="flex flex-col justify-center">
                  <div className="text-gray-900">Direction</div>
                  <select
                      id="direction"
                      className="w-full border p-1"
                      value={ardata?.emitter?.find(
                        (data) => data?.id === whatsClicked?.userData?.id
                        )?.emitterProperties?.direction || ""
                      }
                      onChange={(event) => {
                      const action = {
                          parentKey: "emitterProperties",
                          key: "direction",
                          value: event.target.value,
                          id: whatsClicked?.userData?.id,
                      };
                      dispatch(SET_EMITTER_PROPERTIES(action));
                      }}
                  >
                    <option value="out">Outwards</option>
                    <option value="in">Inwards</option>
                    <option value="both">Both</option> 
                  </select>
                </div>

                <div className="flex flex-col justify-center">
                  <div className="text-gray-900">Transparency Effect</div>
                  <select
                      id="transparencyEffect"
                      className="w-full border p-1"
                      value={ardata?.emitter?.find(
                        (data) => data?.id === whatsClicked?.userData?.id
                        )?.emitterProperties?.transparencyEffect || ""
                      }
                      onChange={(event) => {
                      const action = {
                          parentKey: "emitterProperties",
                          key: "transparencyEffect",
                          value: event.target.value,
                          id: whatsClicked?.userData?.id,
                      };
                      dispatch(SET_EMITTER_PROPERTIES(action));
                      }}
                  >
                    <option value="none">None</option>
                    <option value="start">At start</option>
                    <option value="end">At end</option> 
                    <option value="both">Both</option>
                    {/* More options can be added here */}
                  </select>
                </div>
              </div>
            </div>
          </div>
        </>
      )}

      {whatsClicked?.userData?.type === "emitter" && Array.isArray(anchor) && anchor.length > 0 && (
          <Attach whatsClicked={whatsClicked} anchor={anchor} arItem={ardata.emitter} type="emitter" />
      )}

      {whatsClicked?.userData?.type === "emitter" && (
        <Animation whatsClicked={whatsClicked} arData={ardata} type="emitter" />
      )}
    </div>
  );
}

export default ParticleEmitterEditorSection;
