import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { TextField } from "@mui/material";
import { UPDATE_TEXT_PROPERTIES } from "../../../../../redux/reducerSlice/arSlice";
import Attach from "./attach";
import Animation from "./animation";

function TextEditorSection({ whatsClicked }) {

  const dispatch = useDispatch();
  const ardata = useSelector((state) => state.ar.data);
  const anchor = useSelector((state) => state.ar.data?.anchor) || null;
    
  const name = {
    id: ardata?.text?.find((data) => data?.id === whatsClicked?.userData?.id)
      ?.id,
  };

  const [textValue, setTextValue] = useState("");

  useEffect(() => {
    const data = ardata.text.find(
      (data) => data.id === whatsClicked?.userData?.id
    );
    if (data) {
      setTextValue(data.body);
    }
  }, [ardata, whatsClicked]);

  const handleChange = (event) => {
    const newValue = event.target.value;
    setTextValue(newValue);

    // Dispatch the action with the updated value
    const action = {
      key: "body",
      value: newValue,
      id: name.id,
    };
    dispatch(UPDATE_TEXT_PROPERTIES(action));
  };

  return (
    <div>
      {whatsClicked?.userData?.type === "text" && (
        <div className="mt-2 px-2">
          <div className="rounded-md bg-white px-4 py-2 text-sm shadow-md">
            <div className="w-full text-sm">
              <div className="w-full text-gray-900">Edit Text</div>
              <TextField
                rows={2}
                multiline
                type="text"
                className="w-full"
                value={textValue}
                onChange={handleChange}
              />
            </div>

            <div className="my-2 w-full text-sm">
              <div className="my-2 flex w-full items-center justify-start gap-2 text-sm text-gray-900">
                <h1 className="w-full">Color</h1>
                <div className="flex w-full justify-start">
                  <input
                    type="color"
                    value={ardata.text
                      .filter((data) => data.id === whatsClicked?.userData?.id)
                      .map((data) => data.color)}
                    onChange={(event) => {
                      const action = {
                        key: "color",
                        value: event.target.value,
                        id: name.id,
                      };
                      dispatch(UPDATE_TEXT_PROPERTIES(action));
                    }}
                  />
                </div>
              </div>

              <div className="my-2 flex w-full items-center justify-start gap-1 text-sm text-gray-900">
                <h1 className="w-full">Size (px)</h1>
                <div className="flex w-full justify-start">
                  <input
                    type="number"
                    className="w-full px-1"
                    value={ardata.text
                      .filter((data) => data.id === whatsClicked?.userData?.id)
                      .map((data) => data.fontSize)}
                    onChange={(event) => {
                      const action = {
                        key: "fontSize",
                        value: event.target.value,
                        id: name.id,
                      };
                      dispatch(UPDATE_TEXT_PROPERTIES(action));
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {whatsClicked?.userData?.type === "text" && Array.isArray(anchor) && anchor.length > 0 && (
        <Attach whatsClicked={whatsClicked} anchor={anchor} arItem={ardata.text} type="text" />
      )}

      {whatsClicked?.userData?.type === "text" && (
        <Animation whatsClicked={whatsClicked} arData={ardata} type="text" />
      )}
    </div>
  );
}

export default TextEditorSection;
