import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { instance } from "common/Instance";
import Pagination from "@mui/material/Pagination";
import Skeleton from "@mui/material/Skeleton";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import toast from "react-hot-toast";
import { MdDelete } from "react-icons/md";
import { FaPlus, FaEdit } from "react-icons/fa";
import {
  SET_INITIAL_STATE_AR,
  SET_HISTORY_DATA,
  SET_UPDATE_STATE_AR,
  SET_HISTORY_CURRENT_PAGE,
  SET_HISTORY_TOTAL_PAGE,
  SET_HISTORY_DATA_FOR_SIDEBAR,
} from "../../../redux/reducerSlice/worldTrackSlice";
import { setViewCreated } from "../../../redux/reducerSlice/valueSlice";

// Modal style
const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "white",
  boxShadow: "0 4px 20px rgba(0, 0, 0, 0.1)",
  borderRadius: "8px",
  p: 4,
};

const WorldTracking = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userid = useSelector((state) => state.auth.auth.user._id);
  const userToken = useSelector((state) => state.auth.auth.token);
  const historyData = useSelector((state) => state.WorldTrack.historyData);
  const currentPage = useSelector((state) => state.WorldTrack.currentPageHistory);
  const totalPages = useSelector((state) => state.WorldTrack.totalPagesHistory);
  const [itemsPerPage, setItemsPerPage] = useState(5);
  const [search, setSearch] = useState("");
  const [searchParam, setSearchParam] = useState("");
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [openDelete, setOpenDelete] = useState(null);

  const getHistoryData = async () => {
    setLoading(true);
    try {
      const response = await instance.get(
        `api/WTracking/WorldTrackingList_history/${userid}?page=${currentPage}&limit=${itemsPerPage}&searchText=${searchParam}`,
        { headers: { authorization: `Bearer ${userToken}` } }
      );
      if (response.status === 200) {
        dispatch(SET_HISTORY_DATA(response.data.data));
        dispatch(SET_HISTORY_TOTAL_PAGE(response.data.totalPages));
      }
    } catch (error) {
      toast.error(error?.response?.data?.error?.message ?? error.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getHistoryData();
  }, [currentPage, searchParam, itemsPerPage]);

  const handleSearch = (e) => {
    setSearch(e.target.value);
    setTimeout(() => setSearchParam(e.target.value.trim()), 1000);
  };

  const handlePageChange = (event, value) => {
    dispatch(SET_HISTORY_CURRENT_PAGE(value));
  };

  const handleItemsPerPageChange = (e) => {
    const newItemsPerPage = parseInt(e.target.value, 10);
    setItemsPerPage(newItemsPerPage);
    dispatch(SET_HISTORY_CURRENT_PAGE(1));
  };

  return (
    <div className="p-6 bg-gray-50 min-h-screen">
      {/* Header Section */}
      <div className="mb-6 flex flex-col sm:flex-row sm:items-center sm:justify-between gap-4">
        <input
          className="h-12 w-[60%] rounded-lg border border-gray-200 bg-white px-4 text-sm shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-400 transition-all"
          placeholder="Search World Tracking Experience Name"
          value={search}
          onChange={handleSearch}
        />
        <div className="flex flex-col sm:flex-row gap-4 items-center">
          <button
            className="flex items-center justify-center h-12 w-full sm:w-auto rounded-lg bg-blue-600 px-5 text-white font-medium hover:bg-blue-700 transition-colors shadow-md"
            onClick={() => setOpen(true)}
          >
            <FaPlus className="mr-2" />
            Create Experience
          </button>
          <div className="flex items-center gap-2">
            <span className="text-sm text-gray-600 font-medium">Per page:</span>
            <select
              className="h-10 w-20 rounded-lg border border-gray-200 bg-white px-2 text-sm shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-400 transition-all"
              value={itemsPerPage}
              onChange={handleItemsPerPageChange}
            >
              {[5, 10, 15, 20].map((value) => (
                <option key={value} value={value}>
                  {value}
                </option>
              ))}
            </select>
          </div>
        </div>
      </div>

      {/* Table Section */}
      {loading ? (
        <Skeleton variant="rectangular" height={300} className="rounded-lg" />
      ) : (
        <div className="bg-white rounded-lg shadow-md overflow-hidden">
          <table className="w-full table-auto">
            <thead className="bg-gray-100">
              <tr>
                <th className="px-6 py-4 text-left text-sm font-semibold text-gray-800">Name</th>
                <th className="px-6 py-4 text-left text-sm font-semibold text-gray-800">Date</th>
                <th className="px-6 py-4 text-left text-sm font-semibold text-gray-800">Status</th>
                <th className="px-6 py-4 text-left text-sm font-semibold text-gray-800">Actions</th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-100">
              {historyData.map((item) => (
                <tr key={item._id} className="hover:bg-gray-50 transition-colors">
                  <td className="px-6 py-4 text-sm text-gray-700">{item.name}</td>
                  <td className="px-6 py-4 text-sm text-gray-700">
                    {new Date(item.createdAt).toLocaleDateString()}
                  </td>
                  <td className="px-6 py-4 text-sm">
                    <span
                      className={`inline-block rounded-full px-3 py-1 text-xs font-medium ${
                        item.status === "published"
                          ? "bg-green-100 text-green-700"
                          : item.status === "draft"
                          ? "bg-yellow-100 text-yellow-700"
                          : "bg-red-100 text-red-700"
                      }`}
                    >
                      {item.status}
                    </span>
                  </td>
                  <td className="px-6 py-4 text-sm">
                    <div className="flex items-center gap-3">
                      <FaEdit
                        size={18}
                        className="cursor-pointer text-blue-500 hover:text-blue-600 transition-colors"
                        onClick={() => {
                          dispatch(SET_UPDATE_STATE_AR(item));
                          dispatch(setViewCreated(""));
                          navigate(`/admin/world_experience/${item.name}`);
                        }}
                      />
                      <MdDelete
                        size={18}
                        className="cursor-pointer text-red-500 hover:text-red-600 transition-colors"
                        onClick={() => setOpenDelete(item._id)}
                      />
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}

      {/* Pagination */}
      <div className="mt-6 flex justify-end">
        <Pagination
          count={totalPages}
          page={currentPage}
          onChange={handlePageChange}
          color="primary"
          size="large"
          sx={{ "& .MuiPaginationItem-root": { fontSize: "1rem" } }}
        />
      </div>

      <CreateExperienceModal open={open} onClose={() => setOpen(false)} />
      <DeleteModal id={openDelete} onClose={() => setOpenDelete(null)} />
    </div>
  );
};

const CreateExperienceModal = ({ open, onClose }) => {
  const [loading, setLoading] = useState(false);
  const [experienceName, setExperienceName] = useState("");
  const userToken = useSelector((state) => state.auth.auth.token);
  const userid = useSelector((state) => state.auth.auth.user._id);

  const handleCreateExperience = async () => {
    setLoading(true);
    try {
      const response = await instance.post(
        "/api/WTracking/create_WorldTracking",
        { name: experienceName, userId: userid },
        { headers: { authorization: `Bearer ${userToken}` } }
      );
      if (response.status === 200) {
        toast.success("Experience created successfully");
        onClose();
      }
    } catch (error) {
      toast.error(error?.response?.data?.message ?? error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Box sx={modalStyle}>
        <h2 className="mb-5 text-xl font-semibold text-gray-800">Create New Experience</h2>
        <input
          className="mb-5 w-full rounded-lg border border-gray-200 bg-gray-50 px-4 py-3 text-sm focus:outline-none focus:ring-2 focus:ring-blue-400 transition-all"
          placeholder="Enter Experience Name"
          value={experienceName}
          onChange={(e) => setExperienceName(e.target.value)}
        />
        <button
          className="w-full rounded-lg bg-blue-600 px-4 py-3 text-white font-medium hover:bg-blue-700 transition-colors disabled:bg-blue-400"
          onClick={handleCreateExperience}
          disabled={loading}
        >
          {loading ? "Creating..." : "Create"}
        </button>
      </Box>
    </Modal>
  );
};

const DeleteModal = ({ id, onClose }) => {
  const [loading, setLoading] = useState(false);
  const userToken = useSelector((state) => state.auth.auth.token);

  const handleDelete = async () => {
    setLoading(true);
    try {
      const response = await instance.delete(
        `api/WTracking/WorldTracking_delete/${id}`,
        { headers: { authorization: `Bearer ${userToken}` } }
      );
      if (response.status === 200) {
        toast.success("Experience deleted successfully");
        onClose();
      }
    } catch (error) {
      toast.error(error?.response?.data?.message ?? error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal open={Boolean(id)} onClose={onClose}>
      <Box sx={modalStyle}>
        <h2 className="mb-5 text-xl font-semibold text-gray-800">Delete Experience</h2>
        <p className="mb-6 text-sm text-gray-600">Are you sure you want to delete this experience?</p>
        <div className="flex justify-end gap-3">
          <button
            className="rounded-lg border border-gray-200 bg-white px-4 py-2 text-gray-700 font-medium hover:bg-gray-100 transition-colors"
            onClick={onClose}
          >
            Cancel
          </button>
          <button
            className="rounded-lg bg-red-600 px-4 py-2 text-white font-medium hover:bg-red-700 transition-colors disabled:bg-red-400"
            onClick={handleDelete}
            disabled={loading}
          >
            {loading ? "Deleting..." : "Delete"}
          </button>
        </div>
      </Box>
    </Modal>
  );
};

export default WorldTracking;