import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import Box from "@mui/material/Box";
import { instance } from "common/Instance";
import Drawer from "@mui/material/Drawer";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import toast from "react-hot-toast";
import upload from "../../../../assets/img/upload.png";
import { MdOutlineClear } from "react-icons/md";
import * as Yup from "yup";

import VideoThumbnail from "react-video-thumbnail";

import { SET_ADD_VIDEO } from "../../../../redux/reducerSlice/arSlice";

const AddingVideo = ({ open, handleClose }) => {
  const [loading, setLoading] = useState(false);
  const [loading1, setLoading1] = useState(false);
  const userToken = useSelector((state) => state.auth.auth.token);
  const user = useSelector((state) => state.auth.auth.user);
  const arData = useSelector((state) => state.ar.data);
  const [Galleryvideos, setGalleryVideos] = useState([]);

  const [tab, setTab] = useState("upload");

  const selectedVideos = useSelector((state) => state.ar.data.videos);
  const dispatch = useDispatch();

  const Gallery = async () => {
    try {
      const response = await instance.get(
        `api/ar/fetchUserGallery/video`,

        {
          headers: {
            authorization: `Bearer ${userToken}`,
          },
        }
      );
      if (response.status === 200) {
        setGalleryVideos(response.data.data);
      }
    } catch (error) {
      toast.error(error?.response?.data?.error?.message ?? error.message);
    }
  };

  useEffect(() => {
    Gallery();
  }, [open]);

  const handleFileUpload = async (e) => {
    try {
      const files = e.target.files;
      console.log(files, "fileSizeInMb");

      if (files) {
        const fileName = files[0].name.toLowerCase();
        if (!fileName.endsWith(".mp4")) {
          toast.error("Please select a .mp4 file");
          return;
        }

        const fileSizeInMB = files[0].size / (1024 * 1024);
        console.log(fileSizeInMB, "fileSizeInMb");

        if (fileSizeInMB > 25) {
          toast.error("File size should be less than or equal to 25 MB");
          return;
        }

        setLoading(true);
        const formData = new FormData();
        console.log(files, "files");
        formData.append("file", files[0]);
        formData.append("assetType", "video");
        const name = files[0]?.name.split(".").slice(0, -1).join(".");
        console.log(fileName, "fileName");

        const response = await instance.post(
          `/api/ar/upload/${user.username}/${arData.name}`,
          formData,
          {
            headers: {
              authorization: `Bearer ${userToken}`,
              "Content-Type": "multipart/form-data",
            },
          }
        );

        if (response.status === 200) {
          setLoading(false);

          dispatch(
            SET_ADD_VIDEO({
              id: uuidv4(),
              type: "video",
              name: name,
              src: response?.data?.url,
              position: { x: 0, y: 0.0005, z: 0 },
              rotation: { x: -Math.PI / 2, y: 0, z: 0 },
              scale: { x: 1, y: 1, z: 1 },
              isAnimation: false,
              LinkTo:"",
              urlLink: "",
              animation: {
                property: "position",
                trigger:"Autoplay",
                duration: 0,
                delay: 0,
                direction: "topToBottom"
              },

              vsettings: {
                autoplay: false,
                loop: false,
                muted: false,
                playpause: false,
                removeBg: false,
              },
            })
          );
          toast.success("Video is Uploaded Successfully");

          handleClose();
        }
      }
    } catch (error) {
      console.error("Error uploading file:", error);
      setLoading(false);
      handleClose();
      toast.error(error?.response?.data?.message ?? error.message);
    }
  };

  const handleVideoClick = (element) => {
    dispatch(
      SET_ADD_VIDEO({
        id: uuidv4(),
        type: "video",
        src: element,
        position: { x: 0, y: 0.0005, z: 0 },
        rotation: { x: -Math.PI / 2, y: 0, z: 0 },
        scale: { x: 1, y: 1, z: 1 },
        isAnimation: false,
        LinkTo:"",
        urlLink: "",
        animation: {
          property: "position",
          trigger:"Autoplay",
          duration: 0,
          delay: 0,
          direction: "topToBottom"
        },

        vsettings: {
          autoplay: false,
          loop: false,
          muted: false,
          playpause: false,
          removeBg: false,
          fullscreen: false,
        },
      })
    );
    toast.success("Video is Uploaded Successfully");

    handleClose();
  };

  const validationSchema = Yup.object().shape({
    photo: Yup.mixed().required("Photo is required"),
    audio: Yup.mixed().notRequired(),
    textArea: Yup.string().required("Text is required"),
    gender: Yup.string()
      .oneOf(["Male", "Female"], "Gender is required")
      .required("Gender is required"),
    pose: Yup.string()
      .oneOf(["Full", "Half"], "Pose is required")
      .required("Pose is required"),

    language: Yup.string().required("Language is required"),
  });

  return (
    <Drawer anchor="left" open={open} onClose={handleClose}>
      <Box
        sx={{ width: 500 }}
        role="presentation"
        className="relative h-screen overflow-hidden"
      >
        <div className="w-full ">
          <div className="relative mb-9 mt-0 w-full">
            <div className="mb-2 flex  items-center justify-between bg-white p-3  text-gray-900 shadow-lg ">
              <h4 className="text-lg font-semibold">Video </h4>
              <button
                className="cursor-pointer"
                onClick={() => {
                  handleClose();
                }}
              >
                {" "}
                <MdOutlineClear size={20} />
              </button>
            </div>

            <div className="mb-2 flex w-full items-center justify-between gap-1 px-4 ">
              <button
                className={`w-full rounded-lg  ${
                  tab === "upload"
                    ? "bg-[#3d2fa9] text-white"
                    : "border-2  text-[#3d2fa9] "
                }  px-2 py-3  `}
                onClick={() => setTab("upload")}
              >
                Upload Video
              </button>
              <button
                className={`w-full rounded-lg px-2 py-3  ${
                  tab === "library"
                    ? "bg-[#3d2fa9] text-white"
                    : "border-2  text-[#3d2fa9] "
                }`}
                onClick={() => setTab("library")}
              >
                Video Library
              </button>

              <button
                className={`w-full rounded-lg px-2 py-3  ${
                  tab === "Imagetovideo"
                    ? "bg-[#3d2fa9] text-white"
                    : "border-2  text-[#3d2fa9] "
                }`}
                onClick={() => setTab("Imagetovideo")}
              >
              AI Video
              </button>
            </div>

            {tab === "upload" ? (
              <div>
                <div className=" mb-4 mt-10 flex cursor-pointer flex-col items-center justify-center gap-1 px-2">
                  <img
                    src={upload}
                    alt="select "
                    className="h-[40%] w-[40%] rounded-lg"
                  />

                  <h1 className="py-2 text-center text-2xl font-semibold text-gray-800">
                    Upload Video
                  </h1>
                </div>

                <div className="px-4">
                  <label
                    htmlFor="fileInput"
                    className="flex h-full w-full items-center  justify-center rounded-xl border-[2px] border-dashed border-gray-600 p-4  dark:!border-navy-700 lg:pb-0"
                  >
                    <input
                      type="file"
                      id="fileInput"
                      accept="video/mp4"
                      className="hidden"
                      onChange={(e) => handleFileUpload(e)}
                    />

                    <div className="flex flex-col items-center justify-center">
                      <button
                        type="button"
                        onClick={() =>
                          document.getElementById("fileInput").click()
                        }
                        className=" mb-2 mt-2 cursor-pointer rounded-md bg-[#3d2fa9] px-4 py-2 text-center text-xl text-white"
                      >
                        Browse
                      </button>
                      <p className="mt-4 text-lg font-semibold text-gray-900">
                        Supported Format :- MP4
                      </p>

                      <p className="text-md my-2 text-gray-900">
                        Video limit size upto 25 MB
                      </p>
                    </div>
                  </label>
                </div>
              </div>
            ) : tab === "Imagetovideo" ? (
             <div className='mt-2 text-center text-md'>
              Upcoming....
             </div>
            ) : Galleryvideos?.length > 0 ? (
              <div className="grid max-h-[100vh] grid-cols-1 gap-4 overflow-y-auto px-4 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-2 ">
                {Galleryvideos.map((element) => {
                  return (
                    <div
                      className=" cursor-pointer "
                      onClick={() => handleVideoClick(element)}
                    >
                      <VideoThumbnail videoUrl={element ?? ""} />
                    </div>
                  );
                })}
              </div>
            ) : (
              <div className="mt-40 flex flex-col items-center justify-center gap-4 text-center">
                <p className="text-md font-semibold ">
                  The Videos you will upload will be shown here
                </p>
              </div>
            )}
          </div>
        </div>
      </Box>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <div className="flex flex-col items-center justify-center gap-4">
          <h1 className="font-bold  ">Uploading Video</h1>
          <CircularProgress color="inherit" />
        </div>
      </Backdrop>
    </Drawer>
  );
};

export default AddingVideo;
