import React from "react";
import { LuNfc } from "react-icons/lu";
import Logo from "./logo/Logo";
import { IoLocationSharp } from "react-icons/io5";
import { MdOutlineMail } from "react-icons/md";
import { QRCodeSVG } from "qrcode.react";

const CardFifteen = React.forwardRef(
  ({ username, experiencename, name }, ref) => {
    return (
      <div
        ref={ref}
        className="relative h-[250px] w-[400px] overflow-hidden bg-gradient-to-br from-gray-900 to-gray-800 shadow-xl"
      >
        <div className="absolute right-3 z-10 flex items-center justify-end">
          <LuNfc size={25} color="#ffff" className="top-4" />
          <Logo color={"#ffff"} height={35} width={35} />
        </div>
        <div className="relative z-10 flex h-full flex-col justify-center p-6 pt-12 text-white">
          <h2
            className={`mb-2 flex items-center justify-center break-words capitalize ${
              name.length > 20 ? "text-2xl" : "text-3xl"
            } font-bold`}
          >
            {name.split(" ").map(
              (word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
            ).join(" ")}
          </h2>
          <div className="flex items-center justify-center">
            <div className="flex items-center justify-center rounded-lg border border-gray-700 bg-gray-800 text-gray-300 shadow-inner">
              <QRCodeSVG
                value={`https://ar.immarsify.com/${username}/${experiencename}`}
                fgColor="#ffff"
                size={110}
                bgColor="transparent"
                className="text-[#ffff]"
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
);

export default CardFifteen;

export const CardFifteenBackSide = React.forwardRef(
  ({ cardData }, ref) => {
    return (
      <div
        ref={ref}
        className="relative h-[250px] w-[400px] overflow-hidden bg-gradient-to-br from-gray-900 to-gray-800 shadow-xl"
      >
        <div className="absolute right-3 z-10 flex items-center justify-end">
          <LuNfc size={25} color="#ffff" className="top-4" />
          <Logo color={"#ffff"} height={35} width={35} />
        </div>
        <div className="relative break-all p-4 text-sm text-white">
          <h2
            className={`mb-2 text-center font-bold ${
              cardData?.companyName?.length > 20 ? "text-2xl" : "text-3xl"
            }`}
          >
            {cardData?.companyName?.split(" ").map(
              (word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
            ).join(" ")}
          </h2>
          <div className="flex justify-center">
            <div className="mt-4 flex flex-col gap-2">
              {cardData?.address && (
                <p className="flex gap-2 text-sm">
                  <IoLocationSharp className="min-h-5 min-w-5" />
                  {cardData?.address}
                </p>
              )}
              {cardData?.email && (
                <p className="flex gap-2 text-sm">
                  <MdOutlineMail className="min-h-5 min-w-5" />
                  {cardData?.email}
                </p>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
);
