import React, { useState } from "react";
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
import { Button } from "@mui/material";

const InvoicePDFGenerator = ({ invoiceData }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);

  const generatePDF = async () => {
    const input = document.getElementById("invoice-content");
    const canvas = await html2canvas(input, { scale: 2 }); // Increase scale for better quality

    const imgWidth = 210; // A4 width in mm
    const pageHeight = 297; // A4 height in mm
    const imgHeight = (canvas.height * imgWidth) / canvas.width;
    let heightLeft = imgHeight;

    const pdf = new jsPDF("p", "mm", "a4");
    let position = 0;

    pdf.addImage(canvas, "PNG", 0, position, imgWidth, imgHeight, "", "FAST");
    heightLeft -= pageHeight;

    while (heightLeft >= 0) {
      position = heightLeft - imgHeight;
      pdf.addPage();
      pdf.addImage(canvas, "PNG", 0, position, imgWidth, imgHeight, "", "FAST");
      heightLeft -= pageHeight;
    }

    return pdf;
  };

  const sendEmail = async () => {
    setIsLoading(true);
    try {
      const pdf = await generatePDF();
      const pdfBlob = pdf.output("blob");

      const formData = new FormData();
      formData.append("pdf", pdfBlob, "invoice.pdf");
      formData.append("invoiceId", invoiceData._id);
      formData.append("email", invoiceData.userDetails.email);

      const response = await fetch("/api/send-invoice", {
        method: "POST",
        body: formData,
      });

      if (response.ok) {
        alert("Invoice sent successfully!");
      } else {
        throw new Error("Failed to send invoice");
      }
    } catch (error) {
      console.error("Error sending invoice:", error);
      alert("Failed to send invoice. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  const downloadPDF = async () => {
    setIsDownloading(true);
    try {
      const pdf = await generatePDF();
      pdf.save(`invoice-${invoiceData._id.slice(-6)}.pdf`);
    } catch (error) {
      console.error("Error downloading invoice:", error);
      alert("Failed to download invoice. Please try again.");
    } finally {
      setIsDownloading(false);
    }
  };
  const previewPDF = async () => {
    const blob = await generatePDF();
    const pdfUrl = URL.createObjectURL(blob);
    window.open(pdfUrl, "_blank");
  };
  return (
    <div className="container mx-auto p-4">
      <div
        id="invoice-content"
        className="overflow-hidden rounded-lg bg-white shadow-lg"
      >
        <div className="p-8">
          <div className="mb-8 flex items-start justify-between">
            <div>
              <h1 className="mb-2 text-3xl font-bold">Invoice</h1>
              <p>Invoice #: {invoiceData._id}</p>
              <p>Date: {new Date(invoiceData.date).toLocaleDateString()}</p>
              <p>
                Expiry Date:{" "}
                {new Date(invoiceData.expireDate).toLocaleDateString()}
              </p>
              <p>Status: {invoiceData.status}</p>
            </div>
            <img
              src="https://assets.immarsify.com/website+data/images/brandLogo.png"
              alt="Company Logo"
              className="w-48"
            />
          </div>

          <div className="mb-8 flex justify-between">
            <div>
              <h3 className="mb-2 font-bold">Bill To:</h3>
              <p>{invoiceData.userDetails.fullName}</p>
              <p>{invoiceData.userDetails.email}</p>
            </div>
            <div className="text-right">
              <h3 className="mb-2 font-bold">From:</h3>
              <p>Your Company Name</p>
              <p>Your Company Address</p>
              <p>Your Company Phone</p>
            </div>
          </div>

          <table className="mb-8 w-full">
            <thead>
              <tr className="bg-gray-100">
                <th className="p-2 text-left">Item</th>
                <th className="p-2 text-right">Quantity</th>
                <th className="p-2 text-right">Price</th>
                <th className="p-2 text-right">Total</th>
              </tr>
            </thead>
            <tbody>
              {invoiceData.items.map((item) => (
                <tr key={item.id} className="border-b">
                  <td className="p-2">{item.name}</td>
                  <td className="p-2 text-right">{item.quantity}</td>
                  <td className="p-2 text-right">
                    {invoiceData.currency} {item.price.toFixed(2)}
                  </td>
                  <td className="p-2 text-right">
                    {invoiceData.currency} {item.total.toFixed(2)}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>

          <div className="flex justify-end">
            <div className="w-1/2">
              <div className="mb-2 flex justify-between">
                <span>Subtotal:</span>
                <span>
                  {invoiceData.currency} {invoiceData.subTotal.toFixed(2)}
                </span>
              </div>
              <div className="mb-2 flex justify-between">
                <span>Extras:</span>
                <span>
                  {invoiceData.currency} {invoiceData.extras.toFixed(2)}
                </span>
              </div>
              <div className="flex justify-between font-bold">
                <span>Total:</span>
                <span>
                  {invoiceData.currency} {invoiceData.total.toFixed(2)}
                </span>
              </div>
              <div className="mt-2 flex justify-between">
                <span>Paid:</span>
                <span>
                  {invoiceData.currency} {invoiceData.paid.toFixed(2)}
                </span>
              </div>
            </div>
          </div>

          {invoiceData.note && (
            <div className="mt-8">
              <h3 className="mb-2 font-bold">Note:</h3>
              <p>{invoiceData.note}</p>
            </div>
          )}
        </div>
      </div>

      <div className="flex space-x-4">
        <Button
          onClick={previewPDF}
          className="rounded bg-purple-700 px-4 py-2 text-white"
        >
          Show pdf
        </Button>

        <Button
          onClick={sendEmail}
          disabled={isLoading}
          className="rounded bg-purple-700 px-4 py-2 text-white"
        >
          {isLoading ? "Sending..." : "Send Invoice"}
        </Button>
        <Button
          onClick={downloadPDF}
          disabled={isDownloading}
          className="rounded bg-green-600 px-4 py-2 text-white"
        >
          {isDownloading ? "Downloading..." : "Download Invoice"}
        </Button>
      </div>
    </div>
  );
};

export default InvoicePDFGenerator;
