import React from "react";
import { useSelector } from "react-redux";
import Attach from "./attach";
import Animation from "./animation";

export default function ModalEditorSection({ whatsClicked }) {
  
  const ardata = useSelector((state) => state.ar.data);

  const anchor = useSelector((state) => state.ar.data?.anchor) || null;

  return (
    <div>
      {whatsClicked?.userData?.type === "Model3d" && (
        <div className="mt-2 px-2"></div>
      )}

      {whatsClicked?.userData?.type === "Model3d" && Array.isArray(anchor) && anchor.length > 0 && (
          <Attach whatsClicked={whatsClicked} anchor={anchor} arItem={ardata.model} type="Model3d" />
      )}

      {whatsClicked?.userData?.type === "Model3d" && (
        <Animation whatsClicked={whatsClicked} arData={ardata} type="Model3d" />
      )}
    </div>
  );
}
