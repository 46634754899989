import React, { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
// import InputField from "components/fields/InputField";
import { instance } from "common/Instance";
import toast from "react-hot-toast";
import { Link, useNavigate } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import {
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  
  Select,
  MenuItem,
  OutlinedInput,
  TextField,
  Button,
  
  Chip,
  Box,
} from "@mui/material";
import { names } from "common/Category";
import { services } from "common/Services";
// import { countries, getStates } from "common/CountryStates"; // Assuming you have this file

// import "react-country-state-city/dist/react-country-state-city.css";
// import { CountrySelect, StateSelect } from "react-country-state-city";

import { Country, State } from 'country-state-city';

const ACCEPTED_FILE_TYPES = ['application/pdf', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'];
const MAX_FILE_SIZE = 25 * 1024 * 1024; // 50MB in bytes

const JoinReseller = () => {
  const [loading, setLoading] = useState(false);
  const [step, setStep] = useState(1);
  const [industryValue, setIndustryValue] = useState();
  const [selectedCountryCode, setSelectedCountryCode] = useState('');
  const [stateOptions, setStateOptions] = useState([]);

  // const [servicesValue, setServicesValue] = useState([]);
  // const [workSamples, setWorkSamples] = useState([]);
  const [caseStudies, setCaseStudies] = useState([]);
  // const [countryid, setCountryid] = useState(0);
  // const [stateid, setstateid] = useState(0);
  const navigate = useNavigate();

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const regMatch = /^(https?:\/\/)?(www\.)?[a-zA-Z0-9_-]+(\.[a-zA-Z]{2,})(\/[a-zA-Z0-9-._~:/?#[\]@!$&'()*+,;=%]*)?$/;

  const validationSchema = Yup.object({
    fullName: Yup.string().required("Full name is required"),
    partnerAs: Yup.string().required("Please select partner type"),
    email: Yup.string()
      .email("Invalid email address")
      .required("Email is required"),
    companyName: Yup.string().required("Company Name is required!"),
    companyWebsite: Yup.string().matches(regMatch, "Enter correct url!"),
    phoneNumber: Yup.string()
      .test(
        "is-valid-number",
        "Invalid phone number",
        (value) => value && value.length >= 10
      )
      .required("Phone number is required"),
    whatsappNumber: Yup.string()
      .test(
        "is-valid-number",
        "Invalid WhatsApp number",
        (value) => value && value.length >= 10
      )
      .required("WhatsApp number is required"),
    industry: Yup.string()
      // .min(1, "Industry is required!")
      .required("Industry is required!"),
    teamSize: Yup.number()
      .min(0, "Team size cannot be negative")
      .required("Team size is required"),
    whiteLabelingAvailable: Yup.string().required(
      "White labeling option is required"
    ),
    country: Yup.string().required("Country is required"),
    state: Yup.string().required("State is required"),
    clientsHandledBefore: Yup.string().required(
      "Clients handled before is required"
    ),
  });

  const formik = useFormik({
    initialValues: {
      fullName: "",
      partnerAs: "individual",
      email: "",
      companyName: "",
      companyWebsite: "",
      phoneNumber: "",
      whatsappNumber: "",
      industry: '',
      teamSize: "",
      whiteLabelingAvailable: "",
      country: "",
      countryCode: '',
      state: "",
      stateCode : '',
      clientsHandledBefore: "",
      workSamples: [],
      services: [],
      companyDescription: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      setLoading(true);
      try {
        // const { phoneNumber, whatsappNumber, ...valuesWithoutNumber  } = values

        // const payload = {
        //     ...valuesWithoutNumber,
        //     type: "reseller",
        //     caseStudies,
        //     phoneNumber : Number(phoneNumber),
        //     whatsappNumber : Number(whatsappNumber)
        // };

        const formData = new FormData();

        // Append all form fields to FormData
        Object.keys(values).forEach((key) => {
          if (Array.isArray(values[key])) {
            // For arrays, stringify the array and append it as a single value
            formData.append(key, JSON.stringify(values[key]));
          } else {
            formData.append(key, values[key]);
          }
        });

        // Append case studies
        caseStudies.forEach((file, index) => {
          formData.append(`files`, file);
        });

        formData.append("type", "reseller");
        formData.append("source", "");
        formData.append("status", "");
        formData.append("importance", "");
        // console.log("FormData payload:");
        // for (let [key, value] of formData.entries()) {
        //     console.log(key, value);
        // }

        const response = await instance.post(
          "api/inquiries/add_inquiry_ar",
          formData
        );
        if (response.status === 201) {
          toast.success("Form submitted successfully!");
          navigate("/auth/sign-in");
        }
      } catch (error) {
        console.error(error);
        toast.error(error?.response?.data?.message ?? error.message);
      } finally {
        setLoading(false);
      }
    },
  });

  const handleIndustryChange = (event) => {
    const { value } = event.target;
    setIndustryValue(value);
    const selectedOptions = names.find(
      (item) => Object.keys(item)[0] === value
    );
    console.log(" value of industry ", value);
    
    if (selectedOptions) {
      formik.setFieldValue("industry", value);
    } else {
      formik.setFieldValue("industry", '');
    }
  };

  // const handleServicesChange = (event) => {
  //     const { value } = event.target;
  //     setServicesValue(value);
  //     formik.setFieldValue("services", value);
  // };

  // const handleWorkSampleAdd = () => {
  //     if (workSamples.length < 10) {
  //         setWorkSamples([...workSamples, ""]);
  //     }
  // };

  // const handleWorkSampleChange = (index, value) => {
  //     const newWorkSamples = [...workSamples];
  //     newWorkSamples[index] = value;
  //     setWorkSamples(newWorkSamples);
  // };

  const handleCaseStudyUpload = (event) => {
    const files = Array.from(event.target.files);
    const validFiles = files.filter((file) => {

        if (!ACCEPTED_FILE_TYPES.includes(file.type)) {
            toast.error(`File ${file.name} is not an accepted file type`);
            return false;
          }

        if (file.size > MAX_FILE_SIZE) {
            toast.error(`File ${file.name} is larger than 50MB`);
            return false;
        }
        return true;
    });

    if (validFiles.length + caseStudies.length <= 10) {
      setCaseStudies((prevFiles) => [...prevFiles, ...validFiles]);
    } else {
      toast.error("You can only upload up to 10 case studies");
    }
  };

  const removeFile = (index) => {
    setCaseStudies((prevFiles) => prevFiles.filter((_, i) => i !== index));
  };

  // Get all countries
  const countryOptions = Country.getAllCountries().map(country => ({
    value: country.isoCode,
    label: country.name
  }));

  // Handle country change
  const handleCountryChange = (event) => {
    const value = event?.target?.value;
    setSelectedCountryCode(value);

    const selectedCountry = countryOptions?.find(country => country?.value === value);
    
    formik.setFieldValue('country', selectedCountry ? selectedCountry?.label : ''); // Set country label
    formik.setFieldValue('countryCode', value); // Set country ISO code

    // Update state options based on selected country
    if (value) {
      const states = State.getStatesOfCountry(value).map(state => ({
        value: state?.isoCode,
        label: state?.name,
      }));
      setStateOptions(states);
      formik.setFieldValue('state', '')
      formik.setFieldValue('stateCode', ''); // Reset state when country changes
    } else {
      setStateOptions([]);
      formik.setFieldValue('state', '');
      formik.setFieldValue('stateCode', '')
    }
  };

  // Handle state change
  const handleStateChange = (option) => {

    const selectedState = stateOptions?.find(country => country?.value === option.target?.value);
    formik.setFieldValue('state', selectedState ? selectedState?.label : ''); // Set country label
    formik.setFieldValue('stateCode', option.target?.value);
 
    console.log(formik.values);
    
  };

  const renderStep1 = () => (
    <>
      {/* <InputField
                variant="auth"
                extra="mb-3"
                label="Full Name*"
                placeholder="John Doe"
                id="fullName"
                type="text"
                value={formik.values.fullName}
                onChange={formik.handleChange}
                error={formik.touched.fullName && formik.errors.fullName}
            /> */}
      <div className="mb-3">
        <label
          htmlFor="fullName"
          className={`text-sm font-medium text-navy-700 dark:text-white`}
        >
          Full Name<span className=" text-red-600">*</span>
        </label>
        <input
          type="text"
          id="fullName"
          placeholder="John Doe"
          className={`text-md mt-2 flex  h-10 w-full items-center justify-center rounded-md border-2 border-gray-100 bg-white/0 p-3 outline-none ${
            formik.touched.fullName && formik.errors.fullName
              ? "border-gray-200"
              : "border-gray-200 dark:!border-white/10 dark:text-white"
          }`}
          onChange={formik.handleChange}
          value={formik.values.fullName}
        />
        {formik.touched.fullName && formik.errors.fullName && (
          <p className="ml-1.5 mt-1.5 text-sm text-red-500">
            {formik.touched.fullName && formik.errors.fullName}
          </p>
        )}
      </div>

      <FormControl component="fieldset" className="mb-3 mt-2">
        <label
          htmlFor="partnerAs"
          className=" text-sm font-medium text-navy-700 dark:text-white"
        >
          Partner As<span className=" text-red-600">*</span>
        </label>
        <RadioGroup
          row
          name="partnerAs"
          value={formik.values.partnerAs}
          onChange={formik.handleChange}
          className="ml-1.5"
        >
          <FormControlLabel
            value="individual"
            control={<Radio />}
            label="Individual"
          />
          <FormControlLabel
            value="organization"
            control={<Radio />}
            label="Organization"
          />
        </RadioGroup>
      </FormControl>

      {/* <InputField
        variant="auth"
        extra="mb-3"
        label="Email*"
        placeholder="mail@example.com"
        id="email"
        type="email"
        value={formik.values.email}
        onChange={formik.handleChange}
        error={formik.touched.email && formik.errors.email}
      /> */}
      <div className="mb-3">
        <label
          htmlFor="email"
          className={`text-sm font-medium text-navy-700 dark:text-white`}
        >
          Email<span className=" text-red-600">*</span>
        </label>
        <input
          type="email"
          id="email"
          placeholder="mail@example.com"
          className={`text-md mt-2 flex  h-10 w-full items-center justify-center rounded-md border-2 border-gray-100 bg-white/0 p-3 outline-none ${
            formik.touched.email && formik.errors.email
              ? "border-gray-200"
              : "border-gray-200 dark:!border-white/10 dark:text-white"
          }`}
          onChange={formik.handleChange}
          value={formik.values.email}
        />
        {formik.touched.email && formik.errors.email && (
          <p className="ml-1.5 mt-1.5 text-sm text-red-500">
            {formik.touched.email && formik.errors.email}
          </p>
        )}
      </div>

      {/* <InputField
        variant="auth"
        extra="mb-3"
        label="Company Name*"
        placeholder="Your Company"
        id="companyName"
        type="text"
        value={formik.values.companyName}
        onChange={formik.handleChange}
        error={formik.touched.companyName && formik.errors.companyName}
      /> */}

    <div className="mb-3">
        <label
          htmlFor="companyName"
          className={`text-sm font-medium text-navy-700 dark:text-white`}
        >
          Company Name<span className=" text-red-600">*</span>
        </label>
        <input
          type="text"
          id="companyName"
          placeholder="Your Company"
          className={`text-md mt-2 flex  h-10 w-full items-center justify-center rounded-md border-2 border-gray-100 bg-white/0 p-3 outline-none ${
            formik.touched.companyName && formik.errors.companyName
              ? "border-gray-200"
              : "border-gray-200 dark:!border-white/10 dark:text-white"
          }`}
          onChange={formik.handleChange}
          value={formik.values.companyName}
        />
        {formik.touched.companyName && formik.errors.companyName && (
          <p className="ml-1.5 mt-1.5 text-sm text-red-500">
            {formik.touched.companyName && formik.errors.companyName}
          </p>
        )}
      </div>

      {/* <InputField
        variant="auth"
        extra="mb-3"
        label="Company Website"
        placeholder="https://www.example.com"
        id="companyWebsite"
        type="url"
        value={formik.values.companyWebsite}
        onChange={formik.handleChange}
        error={formik.touched.companyWebsite && formik.errors.companyWebsite}
      /> */}

    <div className="mb-3">
        <label
          htmlFor="companyWebsite"
          className={`text-sm font-medium text-navy-700 dark:text-white`}
        >
          Company Website
        </label>
        <input
          type="text"
          id="companyWebsite"
          placeholder="https://www.example.com"
          className={`text-md mt-2 flex  h-10 w-full items-center justify-center rounded-md border-2 border-gray-100 bg-white/0 p-3 outline-none ${
            formik.touched.companyWebsite && formik.errors.companyWebsite
              ? "border-gray-200"
              : "border-gray-200 dark:!border-white/10 dark:text-white"
          }`}
          onChange={formik.handleChange}
          value={formik.values.companyWebsite}
        />
        {formik.touched.companyWebsite && formik.errors.companyWebsite && (
          <p className="ml-1.5 mt-1.5 text-sm text-red-500">
            {formik.touched.companyWebsite && formik.errors.companyWebsite}
          </p>
        )}
      </div>

      <div className="mb-3">
        <label className="text-sm font-medium text-navy-700">
          Phone Number<span className=" text-red-600">*</span>
        </label>
        <PhoneInput
          country={"in"}
          inputStyle={{ width: "100%", height: "40px" }}
          value={formik.values.phoneNumber}
          onChange={(value) => formik.setFieldValue("phoneNumber", value)}
          onBlur={formik.handleBlur}
        />
        {formik.touched.phoneNumber && formik.errors.phoneNumber && (
          <div className="text-sm text-red-500">
            {formik.errors.phoneNumber}
          </div>
        )}
      </div>

      <div className="mb-3">
        <label className="text-sm font-medium text-navy-700">
          WhatsApp Number<span className=" text-red-600">*</span>
        </label>
        <PhoneInput
          country={"in"}
          inputStyle={{ width: "100%", height: "40px" }}
          value={formik.values.whatsappNumber}
          onChange={(value) => formik.setFieldValue("whatsappNumber", value)}
          onBlur={formik.handleBlur}
        />
        {formik.touched.whatsappNumber && formik.errors.whatsappNumber && (
          <div className="text-sm text-red-500">
            {formik.errors.whatsappNumber}
          </div>
        )}
      </div>

      <FormControl fullWidth className="mb-3">
        <label className="text-sm font-medium text-navy-700">
          Industry<span className=" text-red-600">*</span>
        </label>
        <Select
          // labelId="demo-select-small-label"
          id="demo-select-small"
          value={industryValue}
          onChange={handleIndustryChange}
          // input={<OutlinedInput label="Tag" />}
          MenuProps={MenuProps}
          style={{ width: "100%", height: "40px" }}
        >
          {names.map((item, index) => (
            <MenuItem key={index} value={Object.keys(item)[0]}>
              {Object.keys(item)[0]}
            </MenuItem>
          ))}
        </Select>
        {formik.touched.industry && formik.errors.industry && (
          <div className="text-sm text-red-500">{formik.errors.industry}</div>
        )}
      </FormControl>

      {/* <button
        className="linear mt-4 w-full rounded-xl bg-brand-500 px-10 py-[12px] text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
        type="button"
        onClick={() => {
          formik.validateForm().then((errors) => {
            const requiredFields = [
              "fullName",
              "partnerAs",
              "email",
              "companyName",
              "companyWebsite",
              "phoneNumber",
              "whatsappNumber",
              "industry",
            ];
            const hasErrors = requiredFields.some((field) => errors[field]);
            
            if (!hasErrors) {
              setStep(2);
            } else {
              // Touch all fields to show errors
              requiredFields.forEach((field) =>
                formik.setFieldTouched(field, true)
              );
              formik.setTouched(formik.touched);
              toast.error("Please fill all mandatory fields");
            }
          });
        }}
      >
        Next
      </button> */}

<button
  className="linear mt-4 w-full rounded-xl bg-brand-500 px-10 py-[12px] text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
  type="button"
  onClick={() => {
    formik.validateForm().then((errors) => {
      const requiredFields = [
        "fullName",
        "partnerAs",
        "email",
        "companyName",
        "companyWebsite",
        "phoneNumber",
        "whatsappNumber",
        "industry",
      ];
      
      // Collect errors for required fields
      const fieldErrors = requiredFields.filter((field) => errors[field]);

      if (fieldErrors.length === 0) {
        setStep(2);
      } else {
        // Touch all fields to show errors
        requiredFields.forEach((field) => formik.setFieldTouched(field, true));

        // Display a toast for each field with an error
        fieldErrors.forEach((field) => {
          const errorMessage = errors[field];
          if (errorMessage) {
            toast.error(`${field}: ${errorMessage}`);
          }
        });
      }
    });
  }}
>
  Next
</button>

    </>
  );

  const renderStep2 = () => (
    <div className="space-y-3">
      <h3 className="text-2xl font-bold text-navy-700 dark:text-white">
        Additional Information
      </h3>

      <div>
        {/* <InputField
                    variant="auth"
                    label="Team Size*"
                    placeholder="Enter team size"
                    id="teamSize"
                    type="number"
                    value={formik.values.teamSize}
                    onChange={formik.handleChange}
                    error={formik.touched.teamSize && formik.errors.teamSize}
                /> */}

        <div>
          <label
            htmlFor="teamSize"
            className={`text-sm font-medium text-navy-700 dark:text-white `}
          >
            Team Size<span className=" text-red-600">*</span>
          </label>
          <input
            type="number"
            id="teamSize"
            placeholder="Enter team size"
            className={`text-md mt-2 flex  h-10 w-full items-center justify-center rounded-md border-2 border-gray-100 bg-white/0 p-3 outline-none ${
              formik.touched.teamSize && formik.errors.teamSize
                ? "border-gray-200"
                : "border-gray-200 dark:!border-white/10 dark:text-white"
            }`}
            onChange={formik.handleChange}
            value={formik.values.teamSize}
          />
          {formik.touched.teamSize && formik.errors.teamSize && (
            <p className="ml-1.5 mt-1.5 text-sm text-red-500">
              {formik.touched.teamSize && formik.errors.teamSize}
            </p>
          )}
        </div>
      </div>

      <div>
        <FormControl component="fieldset">
          <label
            htmlFor="whiteLabelingAvailable"
            className={`text-sm font-medium text-navy-700 dark:text-white`}
          >
            White labelling available?<span className=" text-red-600">*</span>
          </label>
          <RadioGroup
            row
            name="whiteLabelingAvailable"
            value={formik.values.whiteLabelingAvailable}
            onChange={formik.handleChange}
          >
            <FormControlLabel value="yes" control={<Radio />} label="Yes" />
            <FormControlLabel value="no" control={<Radio />} label="No" />
          </RadioGroup>
          {formik.touched.whiteLabelingAvailable &&
            formik.errors.whiteLabelingAvailable && (
              <p className="ml-1.5 mt-1.5 text-sm text-red-500">
                {formik.errors.whiteLabelingAvailable}
              </p>
            )}
        </FormControl>
      </div>

      <div className="grid grid-cols-1 gap-4 md:grid-cols-2">
        <FormControl fullWidth>
          <label htmlFor="country" className="mb-2 text-sm font-medium text-navy-700 dark:text-white">
            Country<span className=" text-red-600">*</span>
          </label>
          {/* <CountrySelect
            style={{
              border: "none",
            }}
            onChange={(e) => {
              // console.log("country ", e);

              setCountryid(e.id);
              formik.setFieldValue("country", e.name);
            }}
            placeHolder="Select Country"
            value={formik.values.country}
          /> */}
           
          <Select
            id="country"
            value={formik.values.countryCode}
            onChange={(e) => handleCountryChange(e)
            }
            // label="Country"
            style={{ width: "100%", height: "40px" }}
          >
            {countryOptions.map((country) => (
              <MenuItem key={country.value} value={country.value}>
                {country.label}
              </MenuItem>
            ))}
          </Select>
        
          {formik.touched.country && formik.errors.country && (
            <p className="ml-1.5 mt-1.5 text-sm text-red-500">
              {formik.errors.country}
            </p>
          )}
        </FormControl>

        <FormControl fullWidth>
          <label className="mb-2 text-sm font-medium text-navy-700 dark:text-white">
            State<span className=" text-red-600">*</span>
          </label>
          {/* <StateSelect
            countryid={countryid}
            style={{
              border: "none",
            }}
            onChange={(e) => {
              // console.log("state ", e);
              setstateid(e.id);
              formik.setFieldValue("state", e.name);
            }}
            placeHolder="Select State"
            value={formik.values.state}
          /> */}
          <Select
          labelId="state-label"
          value={formik.values.stateCode}
          onChange={(e) => handleStateChange(e)}
          // label="State"
          disabled={!selectedCountryCode}
          style={{ width: "100%", height: "40px" }}
          >
          {stateOptions.map((state) => (
            <MenuItem key={state.value} value={state.value}>
              {state.label}
            </MenuItem>
          ))}
        </Select>
          {formik.touched.state && formik.errors.state && (
            <p className="ml-1.5 mt-1.5 text-sm text-red-500">
              {formik.errors.state}
            </p>
          )}
        </FormControl>
      </div>

      <div>
        <FormControl component="fieldset">
          {/* <FormLabel component="legend" 
                    className="ml-1.5 text-sm font-medium text-navy-700 dark:text-white"
                    >
                        Clients handled before<span className=" text-red-600">*</span>
                    </FormLabel> */}
          <label
            htmlFor="clientsHandledBefore"
            className={`text-sm font-medium text-navy-700 dark:text-white`}
          >
            Clients handled before<span className=" text-red-600">*</span>
          </label>
          <RadioGroup
            row
            name="clientsHandledBefore"
            value={formik.values.clientsHandledBefore}
            onChange={formik.handleChange}
          >
            <FormControlLabel
              value="domestic"
              control={<Radio />}
              label="Domestic"
            />
            <FormControlLabel
              value="international"
              control={<Radio />}
              label="International"
            />
            <FormControlLabel value="Both Domestic & International" control={<Radio />} label="Both Domestic & International" />
          </RadioGroup>
          {formik.touched.clientsHandledBefore &&
            formik.errors.clientsHandledBefore && (
              <p className="ml-1.5 mt-1.5 text-sm text-red-500">
                {formik.errors.clientsHandledBefore}
              </p>
            )}
        </FormControl>
      </div>

      <div>
        <FormControl fullWidth>
          <label className="mb-2 text-sm font-medium text-navy-700 dark:text-white">
            Services
          </label>
          <Select
            labelId="demo-multiple-chip-label-small"
            id="demo-multiple-chip-small"
            multiple
            value={formik.values.services}
            onChange={(event) =>
              formik.setFieldValue("services", event.target.value)
            }
            input={<OutlinedInput id="select-multiple-chip" />}
            renderValue={(selected) => (
              <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                {selected.map((value) => (
                  <Chip
                    key={value}
                    label={value}
                    onDelete={(event) => {
                      event.preventDefault();
                      event.stopPropagation();
                      const newServices = formik.values.services.filter(
                        (service) => service !== value
                      );
                      formik.setFieldValue("services", newServices);
                    }}
                    deleteIcon={
                      <span
                        style={{ cursor: "pointer" }}
                        onMouseDown={(event) => {
                          event.preventDefault();
                          event.stopPropagation();
                        }}
                        onClick={(event) => {
                          event.preventDefault();
                          event.stopPropagation();
                        }}
                      >
                        ×
                      </span>
                    }
                  />
                ))}
              </Box>
            )}
            MenuProps={MenuProps}
          >
            {services.map((service) => (
              <MenuItem key={service} value={service}>
                {service}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
      <WorkSamples
        samples={formik.values.workSamples}
        setSamples={(newSamples) =>
          formik.setFieldValue("workSamples", newSamples)
        }
      />

      <div>
        <label className="mb-2 text-sm font-medium text-navy-700 dark:text-white">
          Upload Case Study (up to 10 files, 25MB each)
        </label>
        <input
          type="file"
          multiple
          onChange={handleCaseStudyUpload}
          accept=".pdf,.doc,.docx"
          className="w-full rounded border border-gray-300 p-2"
        />
        {caseStudies.length > 0 && (
          <div className="mt-2">
            <p className="font-medium">Uploaded files:</p>
            <ul className="list-disc pl-5">
              {caseStudies.map((file, index) => (
                <li key={index}>
                    {file.name}
                    <button
                        type="button"
                        onClick={() => removeFile(index)}
                        className="ml-2 text-red-500 hover:text-red-700 font-bold"
                    >
                    X
                    </button>
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>

      <div>
        <label className="mb-2 text-sm font-medium text-navy-700 dark:text-white">
          Tell us about your company
        </label>
        <TextField
          fullWidth
          multiline
          rows={4}
          placeholder="Leave a message"
          id="companyDescription"
          name="companyDescription"
          value={formik.values.companyDescription}
          onChange={formik.handleChange}
          inputProps={{ maxLength: 100 }}
        />
      </div>

      <div className="flex justify-between">
        <Button
          variant="outlined"
          onClick={() => setStep(1)}
          className="px-6 py-2"
        >
          Back
        </Button>

        <button
          // variant="contained"
          type="button"
          onClick={formik.handleSubmit}
          disabled={loading}
          className="bg-brand-500 rounded-lg px-6 py-2 text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700"
        >
          {loading ? (
            <CircularProgress sx={{ color: "white" }} size={24} />
          ) : (
            "Submit"
          )}
        </button>
      </div>
      <div className="mt-4">
        <span className="text-sm font-medium text-navy-700 dark:text-gray-600">
          Already registered?
        </span>
        <Link
          to="/auth/sign-in"
          className="ml-1 text-sm font-medium text-brand-main hover:text-brand-600 dark:text-white"
        >
          Sign In
        </Link>
      </div>
    </div>
  );

  const WorkSamples = ({ samples, setSamples }) => {
    const [newSample, setNewSample] = useState("");

    // Regex pattern to validate URL
  const urlPattern = /^(https?:\/\/)?([\w\d\-]+\.)+[a-z]{2,6}(\:[0-9]{1,5})?(\/.*)?$/i;

  // Function to validate URL using regex
  const isValidURL = (url) => urlPattern.test(url);

  // Function to handle adding a new sample
  const handleAddSample = () => {
    if (newSample && samples.length < 10) {
      if (isValidURL(newSample)) {
        setSamples([...samples, newSample]);
        setNewSample("");
      } else {
        toast.error("Please enter a valid URL.");
      }
    }
  };

    const handleRemoveSample = (index) => {
      setSamples(samples.filter((_, i) => i !== index));
    };

    return (
      <div className="space-y-4">
        <label className="text-sm font-medium text-navy-700 dark:text-white">
          Work samples (up to 10)
        </label>
        <div className="flex space-x-2">
          <TextField
            fullWidth
            value={newSample}
            onChange={(e) => setNewSample(e.target.value)}
            placeholder="Enter URL"
            size="small"
          />
          <Button
            onClick={handleAddSample}
            variant="contained"
            disabled={samples.length >= 10 || !newSample}
          >
            Add
          </Button>
        </div>
        <div className="flex flex-wrap gap-2">
          {samples.map((sample, index) => (
            <Chip
              key={index}
              label={sample}
              onDelete={() => handleRemoveSample(index)}
              color="primary"
              variant="outlined"
            />
          ))}
        </div>
      </div>
    );
  };

  return (
    <div className="mb-16 flex h-full w-full items-center justify-center px-2 md:mx-0 md:px-0 lg:mb-10 lg:items-center lg:justify-start">
      <div className="mt-16 w-full max-w-full flex-col items-center overflow-y-auto md:pl-4 lg:pl-0 xl:max-w-[450px]">
        <h4 className="mb-2.5 text-4xl font-bold text-navy-700 dark:text-white">
          Join as Re-seller
        </h4>
        <p className="mb-4 ml-1 text-base text-gray-600">
          {step === 1
            ? "Enter your details to join"
            : "Please fill these fields which will help us to understand your Organisation better."}
        </p>

        <div className="mb-4 flex justify-between">
          <div
            className={`h-1 w-1/2 ${
              step >= 1 ? "bg-brand-500" : "bg-gray-300"
            }`}
          ></div>
          <div
            className={`h-1 w-1/2 ${
              step >= 2 ? "bg-brand-500" : "bg-gray-300"
            }`}
          ></div>
        </div>

        <form onSubmit={formik.handleSubmit}>
          {step === 1 ? renderStep1() : renderStep2()}
        </form>

        {/* <div className="mt-4">
                    <span className="text-sm font-medium text-navy-700 dark:text-gray-600">
                        Already registered?
                    </span>
                    <Link
                        to="/auth/sign-in"
                        className="ml-1 text-sm font-medium text-brand-main hover:text-brand-600 dark:text-white"
                    >
                        Sign In
                    </Link>
                </div> */}
      </div>
    </div>
  );
};

export default JoinReseller;
