import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import Box from "@mui/material/Box";
import { instance, AWS_STATIC_PREFIX} from "common/Instance";
import Drawer from "@mui/material/Drawer";
import toast from "react-hot-toast";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import upload from "../../../../assets/img/upload.png";
import { MdOutlineClear } from "react-icons/md";
import { ADD_DOCUMENT } from "../../../../redux/reducerSlice/arSlice";

const AddDocument = ({ open, handleClose }) => {
  const [loading, setLoading] = useState(false);
  const userToken = useSelector((state) => state.auth.auth.token);
  const user = useSelector((state) => state.auth.auth.user);
  const arData = useSelector((state) => state.ar.data);
  const dispatch = useDispatch();

  const handleFileUpload = async (e) => {
    try {
      setLoading(true);
      const files = e.target.files;

      if (files) {
        const documentFile = files[0];

        console.log(documentFile, "documentFile");

        const fileSizeInMB = documentFile.size / (1024 * 1024);
        if (fileSizeInMB > 10) {
          setLoading(false);
          toast.error("File size should be less than 10 MB");
          return;
        }

        if (
          documentFile.type === "application/pdf" ||
          documentFile.type ===
            "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
          documentFile.type ===
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        ) {
        
          const formData = new FormData();
          formData.append("file", documentFile);
          formData.append("assetType", "document");

          const response = await instance.post(
            `/api/ar/upload/${user.username}/${arData.name}`,
            formData,
            {
              headers: {
                authorization: `Bearer ${userToken}`,
                "Content-Type": "multipart/form-data",
              },
            }
          );

          console.log(response, "hellorohit");

          let filename=documentFile?.name

          dispatch(
            ADD_DOCUMENT({
              id: uuidv4(),
              name:filename,
              type: "documents",
              src: `${
                documentFile.type === "application/pdf"
                  ? `${AWS_STATIC_PREFIX + "images/pdf_img.png"}`
                  : documentFile.type ===
                    "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                  ? `${AWS_STATIC_PREFIX + "images/doc_img.png"}`
                  : `${AWS_STATIC_PREFIX + "images/excel_img.png"}`
              }`,
              position: { x: 0, y: 0, z: 0 },
              rotation: { x: -1.5, y: 0, z: 0 },
              scale: { x: 0.5, y: 0.5, z: 0.5 },
              isAnimation: false,
              linkType: "RedirectLink",
              isLink: true,
              iconLink: response?.data?.url,
              LinkTo:"",
              animation: {
                property: "position",
                trigger:"Autoplay",
                duration: 0,
                delay: 0,
                direction: "topToBottom"
              },
            })
          );
          setLoading(false);

          toast.success("Document is Uploaded Successfully");

          handleClose();
          setLoading(false);
        }else {
       
          toast.error("Please upload a PDF,EXCEL or WORD file");
          setLoading(false);
        }
      } 
    } catch (error) {
      setLoading(false);
      toast.error(error);
      handleClose();
    }
  };

  return (
    <Drawer
      anchor="left"
      open={open}
      onClose={handleClose}
      className="relative"
    >
      <Box
        sx={{ width: 500 }}
        role="presentation"
        className="relative h-screen overflow-hidden"
      >
        <div>
          <div className="relative mb-9 mt-0 flex flex-col ">
            <div className="mb-2 flex  items-center justify-between bg-white p-3  text-gray-900 shadow-lg ">
              <h4 className=" text-lg font-semibold ">Document</h4>
              <button className='cursor-pointer' onClick={()=>{
                handleClose()
              }}> <MdOutlineClear size={20} /></button>
            </div>

            <div className="mt-5">
              <div className=" mb-4 flex cursor-pointer flex-col items-center justify-center gap-1 px-2">
                <img
                  src={upload}
                  alt="select "
                  className="h-[40%] w-[40%] rounded-lg"
                />

                <h1 className="pb-2 text-center text-2xl font-semibold text-gray-800">
                  Upload File
                </h1>
              </div>

              <div className="px-4">
                <label
                  htmlFor="fileInput"
                  className="flex h-full w-full items-center  justify-center rounded-xl border-[2px] border-dashed border-gray-600 p-4  dark:!border-navy-700 lg:pb-0"
                >
                  <input
                    type="file"
                    id="fileInput"
                    accept=".pdf, .doc, .docx, .xls, .xlsx"
                    className="hidden"
                    onChange={(e) => {
                      handleFileUpload(e);
                    }}
                  />

                  <div className="flex flex-col items-center justify-center">
                    <button
                      type="button"
                      onClick={() =>
                        document.getElementById("fileInput").click()
                      }
                      className="rounded-md bg-[#3d2fa9] px-4 py-2 text-white "
                    >
                      Browse
                    </button>
                    <p className="mt-4 text-lg font-semibold text-gray-900">
                      Supported Format :- PDF, WORD, EXCEL
                    </p>

                    <p className="text-md my-2 text-gray-900">
                      file limit size upto 10 mb
                    </p>
                  </div>
                </label>
              </div>
            </div>
          </div>
        </div>

        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={loading}
        >
          <div className="flex flex-col items-center justify-center gap-4">
            <h1 className="font-bold">Uploading document</h1>
            <CircularProgress color="inherit" />
          </div>
        </Backdrop>
      </Box>
    </Drawer>
  );
};

export default AddDocument;
