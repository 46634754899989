import React, { useState, useEffect } from "react";
import Card from "components/card";
import { CSVLink } from "react-csv";
import { useSelector, useDispatch } from "react-redux";
import { instance } from "common/Instance";
import Pagination from "@mui/material/Pagination";
import VCard from "vcard-creator";
import ActionMenu, { ActionBar, FilterDateMenu } from "./ActionMenu";
import { Chip, Tooltip, IconButton } from "@mui/material";
import LabelManager from "./modal/AddLabel";
import InfoDrawer from "./drawer/Drawer";
import { IoMdClose } from "react-icons/io";
import toast from "react-hot-toast";
import {
  FaPhone,
  FaEnvelope,
  FaWhatsapp,
  FaShareAlt,
  FaSave,
} from "react-icons/fa";
import { SET_LABLES } from "../../../../../redux/reducerSlice/enquiry";
import { debounce } from "lodash";
import { WhatsappModals } from "./modal/WhatsappModal";
import { FiCalendar } from "react-icons/fi";
import moment from "moment";
import { CiLock } from "react-icons/ci";

// Constants
const defaultLabels = {
  importance: [
    { _id: 1, name: "Lead", type: "importance", color: "#FF0000", default: true },
  ],
  status: [
    { _id: 2, name: "Check", type: "status", color: "#00FF00", default: true },
  ],
  source: [
    { _id: 3, name: "Facebook", type: "source", color: "#0000FF", default: true },
  ],
};

const TABLE_HEADERS = [
  "Name",
  "Contact",
  "Status",
  "Phone",
  "Email",
  "Label",
  "Source",
  "Action",
];

const ACTION_ICONS = {
  phone: { icon: FaPhone, color: "#4CAF50", hoverColor: "#388E3C" },
  email: { icon: FaEnvelope, color: "#0077B6", hoverColor: "#005A9E" },
  whatsapp: { icon: FaWhatsapp, color: "#25D366", hoverColor: "#1AA051" },
  share: { icon: FaShareAlt, color: "#6c757d", hoverColor: "#495057" },
  save: { icon: FaSave, color: "#FF9800", hoverColor: "#F57C00" },
};

const LeadTable = () => {
  const authToken = useSelector((state) => state?.auth?.auth?.token);
  const userData = useSelector((state) => state?.auth?.auth?.user);
  const dispatch = useDispatch();
  const staticOptions = useSelector((state) => state?.enquiry?.lables);

  const [search, setSearch] = useState("");
  const [searchParam, setSearchParam] = useState("");
  const [tableData, setTableData] = useState([]);
  const [allData, setAllData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchLoading, setSearchLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(5);
  const [openLabel, setOpenLabel] = useState(false);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [openWhatsappModal, setOpenWhatsappModal] = useState(false);
  const [selectedEnquiry, setSelectedEnquiry] = useState(null);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [startDateFilter, setStartDateFilter] = useState("");
  const [endDateFilter, setEndDateFilter] = useState("");
  const [isAppliedFilter, setIsAppliedFilter] = useState(false);

  // Utility function to handle API calls
  const fetchData = async (endpoint, params = {}) => {
    setLoading(true);
    try {
      const response = await instance.get(endpoint, {
        headers: { authorization: `Bearer ${authToken}` },
        params,
      });
      return response?.data?.data;
    } catch (error) {
      console.error(`Error fetching ${endpoint}:`, error);
      return null;
    } finally {
      setLoading(false);
    }
  };

  // Fetch inquiry labels
  const fetchInquiryLabels = async () => {
    const data = await fetchData("api/inquiries/get_inquiry_labels");
    if (data) {
      const updatedLabels = {
        status: [...defaultLabels.status, ...data.filter((d) => d.type === "status")],
        importance: [...defaultLabels.importance, ...data.filter((d) => d.type === "importance")],
        source: [...defaultLabels.source, ...data.filter((d) => d.type === "source")],
      };
      dispatch(SET_LABLES(updatedLabels));
    }
  };

  // Fetch table data
  const fetchTableData = async () => {
    setLoading(true);
    const data = await fetchData(
      `/api/inquiries/get_all_inquiry`,
      { page: currentPage, limit: itemsPerPage, search: searchParam, startDate: startDateFilter, endDate: endDateFilter }
    );
    if (data) {
      setTableData(data.data);
      setTotalPages(data.totalPages);
    } else {
      setTableData([]);
      setTotalPages(1);
    }
    setLoading(false);
  };

  // Fetch all data for CSV export
  const fetchAllEnquiryData = async () => {
    setSearchLoading(true);
    const data = await fetchData(
      `/api/inquiries/get_all_inquiry_csv`,
      { search: searchParam, startDate: startDateFilter, endDate: endDateFilter }
    );
    if (data) setAllData(data.data);
    else setAllData([]);
    setSearchLoading(false);
  };

  useEffect(() => {
    fetchInquiryLabels();
    fetchTableData();
  }, [currentPage, itemsPerPage, searchParam, startDateFilter, endDateFilter]);

  useEffect(() => {
    fetchAllEnquiryData();
  }, [searchParam, startDateFilter, endDateFilter]);

  // Debounced search handler (refined for clarity)
  const handleSearch = debounce((inputValue) => {
    setSearchParam(inputValue.trim() || "");
    setSearchLoading(true);
  }, 500);

  const handlePageChange = (event, value) => setCurrentPage(value);
  const handleItemsPerPage = (e) => setItemsPerPage(Number(e.target.value));

  // Action handlers (refactored for DRY)
  const handleContactAction = (enquiry, action) => {
    switch (action) {
      case "phone":
        window.location.href = `tel:${enquiry.phone}`;
        break;
      case "email":
        if (!enquiry.email) return toast.error("Email not available!");
        const subject = `Immarsify Enquiry`;
        const body = `Dear ${enquiry.viewerName},\nThank you for attending our event! We truly appreciate your time and support. If you have any questions or need further information, feel free to reach out.\nWe look forward to staying in touch!\nBest regards,\nIMMARSIFY`;
        window.location.href = `mailto:${enquiry.email}?subject=${subject}&body=${body}`;
        break;
      case "whatsapp":
        const whatsappMSG = `Dear ${enquiry.viewerName},\nThank you for attending our event! We truly appreciate your time and support. If you have any questions or need further information, feel free to reach out.\nWe look forward to staying in touch!\nBest regards,\nIMMARSIFY`;
        const message = userData?.defaultMessage?.Whatsapp || whatsappMSG;
        window.open(`https://wa.me/${enquiry.phone}?text=${encodeURIComponent(message)}`, "_self");
        break;
      case "share":
        handleShare(enquiry);
        break;
      case "save":
        handleSaveContact(enquiry);
        break;
      default:
        break;
    }
  };

  const handleSaveContact = (enquiry) => {
    const myVCard = new VCard();
    myVCard
      .addName(enquiry.viewerName)
      .addPhoneNumber(enquiry.phone, "MOBILE")
      .addEmail(enquiry.email)
      .addCompany(enquiry.company || "")
      .addAddress("", "", enquiry.address, enquiry.city, enquiry.state, enquiry.pincode, "WORK")
      .addURL(enquiry.website || "");
    const vCardString = myVCard.toString();
    const blob = new Blob([vCardString], { type: "text/vcard" });
    const url = window.URL.createObjectURL(blob);
    const tempLink = document.createElement("a");
    tempLink.href = url;
    tempLink.setAttribute("download", `${enquiry.viewerName}.vcf`);
    tempLink.click();
    window.URL.revokeObjectURL(url);
  };

  const handleShare = async (enquiry) => {
    const shareData = {
      title: `Contact: ${enquiry.viewerName}`,
      text: `Name: ${enquiry.viewerName}\nPhone: ${enquiry.phone}\nEmail: ${enquiry.email}`,
    };
    if (navigator.share) {
      const userConfirmed = await new Promise((resolve) => resolve(window.confirm("Would you like to share this contact?")));
      if (userConfirmed) {
        try {
          await navigator.share(shareData);
        } catch (error) {
          fallbackShare(enquiry);
        }
      }
    } else {
      fallbackShare(enquiry);
    }
  };

  const fallbackShare = (enquiry) => {
    const myVCard = new VCard();
    myVCard.addName(enquiry.viewerName).addPhoneNumber(enquiry.phone, "MOBILE").addEmail(enquiry.email);
    const vcfContent = myVCard.toString();
    const blob = new Blob([vcfContent], { type: "text/vcard" });
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = `${enquiry.viewerName}.vcf`;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
    toast.error("VCF file downloaded. You can now share this file.");
  };

  const handleFilter = () => {
    setIsAppliedFilter(true);
    setStartDateFilter(startDate);
    setEndDateFilter(endDate);
  };

  const handleClear = () => {
    setIsAppliedFilter(false);
    setStartDateFilter("");
    setEndDateFilter("");
    setStartDate("");
    setEndDate("");
  };

  const renderLoadingRows = () => (
    [...Array(itemsPerPage)].map((_, index) => (
      <tr key={index}>
        {TABLE_HEADERS.map((_, colIndex) => (
          <td key={colIndex} className="whitespace-nowrap px-4 py-3">
            <div role="status" className="animate-pulse">
              <div className="h-3 rounded-full bg-gray-200"></div>
            </div>
          </td>
        ))}
      </tr>
    ))
  );

  const renderTableRow = (item) => {
    const statusData = item?.status && staticOptions?.status?.find(l => l.name.toLowerCase() === item.status.toLowerCase());
    const importanceData = item?.importance && staticOptions?.importance?.find(l => l.name.toLowerCase() === item.importance.toLowerCase());

    if (item?.archive) return null;

    return (
      <tr key={item._id} className="hover:bg-gray-50/50 transition-all duration-300">
        <td className="whitespace-nowrap px-4 py-3">
          <div className="text-base font-medium text-gray-900">{item?.viewerName}</div>
        </td>
        <td className="whitespace-nowrap px-4 py-3">
          <div className="flex space-x-2">
            {Object.entries(ACTION_ICONS).map(([action, { icon: Icon, color, hoverColor }]) => (
              <IconButton
                key={action}
                onClick={() => handleContactAction(item, action)}
                aria-label={action.charAt(0).toUpperCase() + action.slice(1)}
                sx={{
                  color,
                  "&:hover": {
                    color: hoverColor,
                    backgroundColor: "rgba(0, 0, 0, 0.06)",
                    transform: "scale(1.05)",
                  },
                  padding: 1,
                  borderRadius: 1.5,
                  transition: "all 0.3s ease",
                }}
              >
                <Icon size={16} />
              </IconButton>
            ))}
          </div>
        </td>
        <td className="whitespace-nowrap px-4 py-3">
          <Chip
            size="small"
            label={item?.status || "No Status"}
            sx={{
              backgroundColor: statusData?.color || "#cccccc",
              color: "white",
              fontSize: "0.875rem",
              padding: "2px 8px",
              borderRadius: 1.5,
              transition: "all 0.3s ease",
              "&:hover": { transform: "scale(1.05)", boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)" },
            }}
          />
        </td>
        <td className="whitespace-nowrap px-4 py-3">
          <div className="text-base text-gray-900">{item?.phone}</div>
        </td>
        <td className="whitespace-nowrap px-4 py-3">
          <div className="text-base text-gray-900">{item?.email}</div>
        </td>
        <td className="whitespace-nowrap px-4 py-3">
          <Chip
            size="small"
            label={item?.importance || "No Label"}
            sx={{
              backgroundColor: importanceData?.color || "#cccccc",
              color: "white",
              fontSize: "0.875rem",
              padding: "2px 8px",
              borderRadius: 1.5,
              transition: "all 0.3s ease",
              "&:hover": { transform: "scale(1.05)", boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)" },
            }}
          />
        </td>
        <td className="whitespace-nowrap px-4 py-3">
          <div className="text-base text-gray-900">{item?.generatedFrom}</div>
        </td>
        <td className="whitespace-nowrap px-4 py-3">
          <ActionMenu fetchEnquiry={fetchTableData} leadInfo={item} setCurrentPage={setCurrentPage} />
        </td>
      </tr>
    );
  };

  const renderNoData = () => (
    <tr>
      <td colSpan={TABLE_HEADERS.length} className="py-4 text-center">
        <p className="bg-blue-100 px-4 py-3 text-base font-medium text-blue-700 rounded-lg shadow-md hover:shadow-lg transition-shadow duration-300">
          No results found!
        </p>
      </td>
    </tr>
  );

  const renderSearchSkeleton = () => (
    <div role="status" className="animate-pulse w-full lg:w-[500px]">
      <div className="h-10 rounded-lg bg-gray-200"></div>
    </div>
  );

  return (
    <Card extra="w-full h-full sm:overflow-auto px-4">
      <header className="flex w-full flex-col gap-4 py-6 bg-gradient-to-b from-blue-50 to-white rounded-t-lg shadow-md p-2">
        <div className="relative flex items-center justify-between ">
          <h1 className="text-2xl font-bold text-blue-900 tracking-tight">Enquiry List</h1>
          <div className="sm:hidden">
            <ActionBar handleOpen={() => setOpenDrawer(true)} setOpenLabel={() => setOpenLabel(true)} />
          </div>
          <div className="hidden items-center gap-3 sm:flex">
            {(userData?.allowedFeature?.ocr?.allowed || userData?.type !== "user") ? (
              <a
                href="https://scan.immarsify.com/"
                target="_blank"
                className="rounded-lg bg-blue-600 px-4 py-2 text-base font-semibold text-white shadow-md hover:bg-blue-700 hover:shadow-lg transition-all duration-300"
              >
                Create OCR
              </a>
            ) : (
              <Tooltip title="To unlock this feature, please upgrade and purchase the OCR add-on." placement="top">
                <button className="flex items-center gap-1 rounded-lg bg-gray-400 px-4 py-2 text-base font-semibold text-white shadow-md hover:bg-gray-500 hover:shadow-lg transition-all duration-300">
                  <CiLock size={16} />
                  Create OCR
                </button>
              </Tooltip>
            )}
            <button
              onClick={() => setOpenLabel(true)}
              className="rounded-lg bg-blue-600 px-4 py-2 text-base font-semibold text-white shadow-md hover:bg-blue-700 hover:shadow-lg transition-all duration-300"
            >
              Manage Labels
            </button>
            <button
              onClick={() => setOpenDrawer(true)}
              className="rounded-lg bg-blue-600 px-4 py-2 text-base font-semibold text-white shadow-md hover:bg-blue-700 hover:shadow-lg transition-all duration-300"
            >
              Add Enquiry
            </button>
          </div>
        </div>
        <div className="flex w-full flex-col gap-3 lg:flex-row lg:items-center lg:gap-4">
          {searchLoading ? (
            renderSearchSkeleton()
          ) : (
            <input
              className="w-full rounded-lg border border-gray-300 p-2 text-base outline-none focus:border-blue-500 focus:ring-1 focus:ring-blue-100 shadow-sm hover:shadow-md transition-all duration-300 lg:w-[500px]"
              type="text"
              value={search}
              onChange={(e) => { setSearch(e.target.value); handleSearch(e.target.value); }}
              placeholder="Search enquiries..."
            />
          )}
          <div className="mt-3 flex w-full flex-col items-center justify-end gap-3 lg:mt-0 lg:flex-row lg:items-center lg:gap-4">
            {isAppliedFilter ? (
              <div className="flex items-center gap-3 rounded-lg border border-blue-200 bg-blue-50 p-3 text-base font-medium shadow-md hover:shadow-lg transition-shadow duration-300">
                <FiCalendar size={18} className="text-blue-600" />
                <span className="text-blue-800">
                  {moment(startDate).format("DD/MM/YYYY")} - {moment(endDate).format("DD/MM/YYYY")}
                </span>
                <span onClick={handleClear} className="cursor-pointer text-red-500 hover:text-red-700 transition-colors duration-200">
                  <IoMdClose size={18} />
                </span>
              </div>
            ) : (
              <FilterDateMenu
                setStartDateFilter={setStartDateFilter}
                setEndDate={setEndDate}
                setStartDate={setStartDate}
                setEndDateFilter={setEndDateFilter}
                handleFilter={handleFilter}
                startDate={startDate}
                endDate={endDate}
                setIsAppliedFilter={setIsAppliedFilter}
              />
            )}
            <div className="flex items-center gap-3">
              <CSVLink
                data={allData}
                filename="enquiries.csv"
                className="rounded-lg bg-green-600 px-4 py-2 text-base font-semibold text-white shadow-md hover:bg-green-700 hover:shadow-lg transition-all duration-300"
              >
                Export to CSV
              </CSVLink>
              <div className="flex items-center gap-3">
                <span className="text-base text-gray-700">Items per page:</span>
                <select
                  className="h-10 w-20 rounded-lg border border-gray-300 bg-white p-2 text-base text-gray-900 shadow-sm outline-none focus:border-blue-500 focus:ring-1 focus:ring-blue-100 hover:shadow-md transition-all duration-300"
                  value={itemsPerPage}
                  onChange={handleItemsPerPage}
                >
                  {[...Array(11).keys()].slice(1).map((num) => (
                    <option key={num} value={num * 5} className="text-base">{num * 5}</option>
                  ))}
                </select>
              </div>
            </div>
          </div>
        </div>
      </header>

      <div className="overflow-x-auto mt-6">
        <table className="min-w-full divide-y divide-gray-200 rounded-lg shadow-md">
          <thead className="bg-gray-50">
            <tr>
              {TABLE_HEADERS.map((header, index) => (
                <th
                  key={index}
                  scope="col"
                  className="px-4 py-3 text-sm font-medium uppercase tracking-wider text-gray-700 hover:bg-gray-100 transition-all duration-300 cursor-pointer"
                >
                  {header}
                </th>
              ))}
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {loading ? renderLoadingRows() : tableData.length > 0 ? tableData.map(renderTableRow) : renderNoData()}
          </tbody>
        </table>
      </div>

      <LabelManager openLabel={openLabel} setOpenLabel={setOpenLabel} />
      <WhatsappModals
        number="023480238432"
        onClose={() => setOpenWhatsappModal(false)}
        open={openWhatsappModal}
        selectedEnquiry={selectedEnquiry}
      />
      <InfoDrawer
        drawerType="create"
        openDrawer={openDrawer}
        setOpenDrawer={setOpenDrawer}
        fetchEnquiry={fetchTableData}
      />
      <div className="mt-6 flex w-full items-center justify-end">
        <Pagination
          count={totalPages}
          color="primary"
          page={currentPage}
          onChange={handlePageChange}
          className="shadow-md rounded-lg"
          sx={{
            "& .MuiPaginationItem-root": {
              fontSize: "0.875rem",
              padding: "8px 12px",
              borderRadius: 1.5,
              transition: "all 0.3s ease",
              "&:hover": { backgroundColor: "#e5e7eb", transform: "scale(1.05)" },
              "&.Mui-selected": {
                backgroundColor: "#2563eb",
                color: "white",
                "&:hover": { backgroundColor: "#1d4ed8" },
              },
            },
          }}
        />
      </div>
    </Card>
  );
};

export default LeadTable;