import React from 'react' 
import { 
        SET_PHOTO_PROPERTIES,
        SET_VIDEO_PROPERTIES,
        UPDATE_TEXT_PROPERTIES,
        SET_ICONS_PROPERTIES,
        SET_DOCUMENT_PROPERTIES,
        SET_MODEL_PROPERTIES,
        SET_CAROUSEL_PROPERTIES,
        SET_RESUME_PROPERTIES,
        SET_GROUP_PROPERTIES,
        SET_EMITTER_PROPERTIES,
        } from "../../../../../redux/reducerSlice/arSlice";
import { useDispatch } from "react-redux";

const Attach = ({whatsClicked, arItem, anchor, type }) => {
    const dispatch = useDispatch();
  return (
    <div className="mt-2 px-2">
        <div className="bg-white py-2 px-4 rounded-md shadow-md">
            <p className="py-1">Attach </p>

            <div className="mx-2 my-1 flex flex-col gap-4 bg-white text-sm ">

                <div className="flex flex-col justify-center">
                <div className="text-gray-900">Parent Group</div>
                <select
                    name=""
                    id=""
                    className="w-full border p-1"
                    value={ arItem?.find((data) => data?.id === whatsClicked?.userData?.id)?.LinkTo || "" }
                    onChange={(event) => {
                        const action = {
                            key: "LinkTo", 
                            value: event.target.value,
                            id: whatsClicked?.userData?.id,
                        };
                        switch (type) {
                            case "photo":
                                dispatch(SET_PHOTO_PROPERTIES(action));
                                break;
                            case "video":
                                dispatch(SET_VIDEO_PROPERTIES(action));
                                break;
                            case "text":
                                dispatch(UPDATE_TEXT_PROPERTIES(action));
                                break;
                            case "icons":
                                dispatch(SET_ICONS_PROPERTIES(action));
                                break;
                            case "documents":
                                dispatch(SET_DOCUMENT_PROPERTIES(action));
                                break;
                            case "Model3d":
                                dispatch(SET_MODEL_PROPERTIES(action));
                                break;
                            case "Group":
                                dispatch(SET_CAROUSEL_PROPERTIES(action));
                                break;
                            case "resume":
                                dispatch(SET_RESUME_PROPERTIES(action));
                                break;
                            case "anchor":
                                dispatch(SET_GROUP_PROPERTIES(action));
                                break;
                            case 'emitter':
                                dispatch(SET_EMITTER_PROPERTIES(action));
                                break;
                            default:
                                break;
                        }
                    }}
                >
                    <option value="">None</option>
                    {anchor?.map((anchor) => (
                    <option key={anchor.id} value={anchor?.id}>
                    {anchor?.name }
                    </option>
                    ))}
                </select>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Attach