//not finished yet
import React, { useState, useEffect } from 'react';
import { useDispatch } from "react-redux";
import { 
        SET_PHOTO_PROPERTIES,
        SET_VIDEO_PROPERTIES,
        UPDATE_TEXT_PROPERTIES,
        SET_ICONS_PROPERTIES,
        SET_DOCUMENT_PROPERTIES,
        SET_MODEL_PROPERTIES,
        SET_CAROUSEL_PROPERTIES,
        SET_RESUME_PROPERTIES,
        SET_GROUP_PROPERTIES,
        SET_EMITTER_PROPERTIES,
        } from "../../../../../redux/reducerSlice/arSlice";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";

const Animation = ({whatsClicked, arData, type }) => {
    const dispatch = useDispatch();

    const arItem = () => {
        switch (type) {
            case 'photo':
                return arData.photos;
            case 'video':
                return arData.videos;
            case 'text':
                return arData.text;
            case 'icons':
                return arData.icons;
            case 'documents':
                return arData.documents;
            case 'Model3d':
                return arData.model;
            case 'resume':
                return arData.resume;
            case 'Group':
                return arData.carousel;
            case 'anchor':
                return arData.anchor;
            case 'emitter':
                return arData.emitter;
            default:
                console.log("this item is not set for animation");
                return null;
        }
    }
    const dispatchSelector = (action) => {
        switch (type) {
            case 'photo':
                dispatch(SET_PHOTO_PROPERTIES(action));
                break;
            case 'video':
                dispatch(SET_VIDEO_PROPERTIES(action));
                break;
            case 'text':
                dispatch(UPDATE_TEXT_PROPERTIES(action));
                break;
            case 'icons':
                dispatch(SET_ICONS_PROPERTIES(action));
                break;
            case 'documents':
                dispatch(SET_DOCUMENT_PROPERTIES(action));
                break;
            case 'Model3d':
                dispatch(SET_MODEL_PROPERTIES(action));
                break;
            case 'resume':
                dispatch(SET_RESUME_PROPERTIES(action));
                break;
            case 'Group':
                dispatch(SET_CAROUSEL_PROPERTIES(action));
                break;
            case 'anchor':
                dispatch(SET_GROUP_PROPERTIES(action));
                break;
            case 'emitter':
                dispatch(SET_EMITTER_PROPERTIES(action));
                break;
            default:
                console.log("this item is not set for dispatch");
                return null;
        }
    }

    const isAnimation = arItem()?.find((data) => data?.id === whatsClicked?.userData?.id)?.isAnimation;
    const selectedItem = arItem()?.find((data) => data?.id === whatsClicked?.userData?.id);
    const {id, animation} = selectedItem || {};
    const { delay, duration } = animation || {};

    const [animationDelay, setAnimationDelay] = useState(delay || 0);
    const [animationDuration, setAnimationDuration] = useState(duration || 0);
    
    useEffect(() => {
        setAnimationDelay(delay || 0);
        setAnimationDuration(duration || 0);
      }, [id, delay, duration]);

  return (
        <div className="mt-2 px-2">
            <div className="bg-white py-2 px-4 rounded-md shadow-md">
                <p className="py-1">Animation</p>

                {whatsClicked?.userData?.type === "Model3d" && (
                <div className="border-t-2">
                    <FormControlLabel
                    control={
                        <Checkbox
                        onChange={(e) => {
                            const action = {
                            key: "isAutorotate",
                            value: e.target.checked,
                            id: whatsClicked?.userData?.id,
                            };
                            dispatch(SET_MODEL_PROPERTIES(action));
                        }}
                        />
                    }
                    label={<span style={{ fontSize: 14 }}>Auto-rotate</span>}
                    />
                </div>
                )}

                <div className="  border-t-2  text-sm ">
                    <FormControlLabel
                    control={
                        <Checkbox
                        checked={isAnimation}
                        onChange={() => {
                            const action = {
                            key: "isAnimation",
                            value: !isAnimation,
                            id: whatsClicked?.userData?.id,
                            };
                            console.log(isAnimation);
                            dispatchSelector(action);
                        }}
                        />
                    }
                    label={<span style={{ fontSize: 14 }}>Apply</span>}
                    />
                </div>

                {isAnimation && (
                <div className="mx-2 my-1 flex flex-col gap-4 bg-white text-sm ">

                    <div className="flex flex-col justify-center">
                    <div className="text-gray-900">Property</div>
                    <select
                        name=""
                        id=""
                        className="w-full border p-1"
                        value={
                        arItem()?.find(
                            (data) => data?.id === whatsClicked?.userData?.id
                        )?.animation?.property || ""
                        }
                        onChange={(event) => {
                            const action = {
                                parentKey: "Animation",
                                key: "property",
                                value: event.target.value,
                                id: whatsClicked?.userData?.id,
                            };
                            dispatchSelector(action);
                            let subAction;
                            switch(event.target.value){
                                case 'position': 
                                    subAction = {
                                        parentKey: "Animation",
                                        key: "direction",
                                        value: "topToBottom",
                                        id: whatsClicked?.userData?.id,
                                    }
                                    dispatchSelector(subAction);
                                    break;
                                case 'rotation': 
                                    subAction = {
                                        parentKey: "Animation",
                                        key: "direction",
                                        value: "clock_x",
                                        id: whatsClicked?.userData?.id,
                                    }
                                    dispatchSelector(subAction);
                                    break;
                                case 'scale': 
                                    subAction = {
                                        parentKey: "Animation",
                                        key: "direction",
                                        value: "expand",
                                        id: whatsClicked?.userData?.id,
                                    }
                                    dispatchSelector(subAction);
                                    break;
                                case 'opacity': 
                                    subAction = {
                                        parentKey: "Animation",
                                        key: "direction",
                                        value: "showing",
                                        id: whatsClicked?.userData?.id,
                                    }
                                    dispatchSelector(subAction);
                                    break;
                                default:
                                    return;
                            }
                        }}
                    >
                        <option value="position">Position</option>
                        <option value="rotation">Rotation</option>
                        <option value="scale">Scale</option>
                        <option value="opacity">Visible</option>
                    </select>
                    </div>

                    <div className="flex flex-col justify-center">
                    <div className="text-gray-900">Trigger</div>
                    <select
                        name=""
                        id=""
                        className="w-full border p-1"
                        value={
                        arItem()?.find(
                            (data) => data?.id === whatsClicked?.userData?.id
                        )?.animation?.trigger || ""
                        }
                        onChange={(event) => {
                        const action = {
                            parentKey: "Animation",
                            key: "trigger",
                            value: event.target.value,
                            id: whatsClicked?.userData?.id,
                        };
                        dispatchSelector(action);
                        }}
                    >
                        <option value="Autoplay">Autoplay</option>
                        <option value="Click">Click</option>
                    </select>
                    </div>

                    {arItem()?.find((data) => data?.id === whatsClicked?.userData?.id)?.animation?.trigger === "Click" && (
                    <div className="flex flex-col justify-center">
                        <div className="text-gray-900">Trigger Target</div>
                        <select
                        name=""
                        id=""
                        className="w-full border p-1"
                        value={
                            arItem()?.find(
                            (data) => data?.id === whatsClicked?.userData?.id
                            )?.animation?.triggerTarget || ""
                        }
                        onChange={(event) => {
                            const action = {
                            parentKey: "Animation",
                            key: "triggerTarget",
                            value: event.target.value,
                            id: whatsClicked?.userData?.id,
                            };
                            dispatchSelector(action);
                        }}
                        >
                        <option value={whatsClicked?.userData?.id}>Self</option>
                        {Object.values(arData)
                            .flat() // Flatten any arrays inside ardata
                            .filter(item => item?.type && item.type !== "anchor" && item.type !== "centerPosition" && item.id !== whatsClicked?.userData?.id) // Filter out "anchor" type
                            .map(options => (
                            <option key={options.id} value={options.id}>
                                {options.name ? options.name : 
                                options.iconName ? options.iconName : 
                                    options.body ? `${options.type} - ${options.body}` :
                                    `${options.type} - ${options.id.slice(0, 4)}`}
                            </option>
                            ))}
                        </select>
                    </div>
                    )}

                    <div className="flex flex-col justify-center ">
                    <div className="text-gray-900">Duration (ms)</div>
                    <input
                        id="duration"
                        type="number"
                        className="w-full border p-1"
                        value={animationDuration}
                        onChange={(event) => setAnimationDuration(event.target.valueAsNumber)}
                        onBlur={() => {
                        const action = {
                            parentKey: "Animation",
                            key: "duration",
                            value: animationDuration,
                            id: whatsClicked?.userData?.id,
                        };
                        dispatchSelector(action);
                        }}
                    />
                    </div>

                    <div className="flex flex-col justify-center">
                    <div className="text-gray-900">Delay (ms)</div>
                    <input
                        id="delay"
                        type="number"
                        className="w-full border p-1"
                        value={animationDelay}
                        onChange={(event) => setAnimationDelay(event.target.valueAsNumber)}
                        onBlur={() => {

                        const action = {
                            parentKey: "Animation",
                            key: "delay",
                            value: animationDelay,
                            id: whatsClicked?.userData?.id,
                        };
                        dispatchSelector(action);
                        }}
                    />
                    </div>

                    <div className="flex flex-col justify-center">
                    <div className="text-gray-900">Direction</div>
                    <select
                        name=""
                        id=""
                        className="w-full border p-1"
                        value={
                        arItem()?.find(
                            (data) => data?.id === whatsClicked?.userData?.id
                        )?.animation?.direction || ""
                        }
                        onChange={(event) => {
                        const action = {
                            parentKey: "Animation",
                            key: "direction",
                            value: event.target.value,
                            id: whatsClicked?.userData?.id,
                        };
                        dispatchSelector(action);
                        }}
                    >
                        { (!arItem()?.find((data) => data?.id === whatsClicked?.userData?.id)?.animation?.property || arItem()?.find((data) => data?.id === whatsClicked?.userData?.id)?.animation?.property === "position") && (
                        <>
                            <option value="topToBottom">Top to Bottom</option>
                            <option value="leftToRight">Left to Right</option>
                            <option value="rightToLeft">Right to Left</option>
                            <option value="bottomToTop">Bottom to Top</option>
                        </>
                        )}

                        {arItem()?.find((data) => data?.id === whatsClicked?.userData?.id)?.animation?.property === "rotation" && (
                        <>
                            <option value="clock_x">Flip - Back</option>
                            <option value="anticlock_x">Flip - Front</option>
                            <option value="clock_y">Wheel - Right</option>
                            <option value="anticlock_y">Wheel - Left</option>
                            <option value="clock_z">Spin - Clockwise</option>
                            <option value="anticlock_z">Spin - Anticlockwise</option>
                        </>
                        )}

                        {arItem()?.find((data) => data?.id === whatsClicked?.userData?.id)?.animation?.property === "scale" && (
                        <>
                            <option value="expand">Expand</option>
                            <option value="shrink">Shrink</option>
                            <option value="expand_shrink">Expand to Shrink</option>
                            <option value="shrink_expand">Shrink to Expand</option>
                        </>
                        )}

                        {arItem()?.find((data) => data?.id === whatsClicked?.userData?.id)?.animation?.property === "opacity" && (
                        <>
                            <option value="showing">Invisible to Showing</option>
                            <option value="hiding">Visible to Hiding</option>
                        </>
                        )}
                    </select>
                    </div>
                </div>
                )}
            </div>
        </div>
  )
}

export default Animation
