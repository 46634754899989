import * as THREE from "three";
import { TextGeometry } from "three/examples/jsm/Addons";
import { FontLoader } from "three/examples/jsm/loaders/FontLoader";
import { loadingManager } from "./Editor";
import { GLTFLoader } from "three/addons/loaders/GLTFLoader.js";

const loadTexture = (path) => {
  return new Promise((resolve, reject) => {
    const loader = new THREE.TextureLoader(loadingManager);
    loader.crossOrigin = "Anonymous";
    loader.load(path, (texture) => {
      resolve(texture);
    });
  });
};

const loadFont = (path) => {
  return new Promise((resolve, reject) => {
    const loader = new FontLoader();
    loader.load(path, (font) => {
      resolve(font);
    });
  });
};

const loadVideo = (path) => {
  return new Promise((resolve, reject) => {
    const video = document.createElement("video");
    //video.addEventListener('loadeddata', () => {
    video.addEventListener("loadedmetadata", () => {
      video.setAttribute("playsinline", "");
      resolve(video);
    });
    video.src = path;
  });
};

/* =================== Create Photo Mesh ============================ */

const createPhotoMesh = async (data, index) => {
  const source = data.src + "?not-from-cache-please";
  const texture = await loadTexture(source);
  texture.colorSpace = THREE.SRGBColorSpace;
  texture.needsUpdate = true;
  let photo;

  // Set a base size for the geometry
  if (data.geometry === "Circle") {
    photo = new THREE.Mesh(
      new THREE.CircleGeometry(0.5, 32),
      new THREE.MeshBasicMaterial({
        map: texture,
        transparent: true,
        side: THREE.DoubleSide,
      })
    );
  } else {
    photo = new THREE.Mesh(
      new THREE.PlaneGeometry(),
      new THREE.MeshBasicMaterial({
        map: texture,
        transparent: true,
        side: THREE.DoubleSide,
      })
    );
  }

  photo.position.set(data.position.x, data.position.y, data.position.z);
  photo.rotation.set(data.rotation.x, data.rotation.y, data.rotation.z);
  photo.scale.set(data.scale.x, data.scale.y, data.scale.z);
  photo.name = data?.name ? data?.name : `Photo - ${index + 1}`;

  photo.userData.id = data.id;
  photo.userData.thumbnail = data?.src;
  photo.userData.type = data.type;
  photo.userData.LinkTo = data.LinkTo;
  return photo;
};

/* =================== Create Target Mesh ============================ */

const createTargetMesh = async (data) => {
  const source = data.src + "?not-from-cache-please";
  const texture = await loadTexture(source);
  texture.colorSpace = THREE.SRGBColorSpace;
  texture.needsUpdate = true;
  const textureWidth = texture.image.width;
  const textureHeight = texture.image.height;

  // Create a plane geometry with the same dimensions as the texture
  let photo;
  if (textureWidth >= textureHeight) {
    photo = new THREE.Mesh(
      new THREE.PlaneGeometry(),
      new THREE.MeshBasicMaterial({
        map: texture,
        transparent: true,
        side: THREE.DoubleSide,
      })
    );
  }

  if (textureHeight > textureWidth) {
    photo = new THREE.Mesh(
      new THREE.PlaneGeometry(),
      new THREE.MeshBasicMaterial({
        map: texture,
        transparent: true,
        side: THREE.DoubleSide,
      })
    );
  }

  photo.position.set(0, 0, 0);
  photo.rotation.set(-Math.PI / 2, 0, 0);
  photo.scale.set(data.scale.x, data.scale.y, data.scale.z);
  photo.name = `Target Image`;
  photo.userData.type = "target_img";

  return photo;
};

/* =================== Create Video Mesh ============================ */

const createVideoMesh = async (data, index) => {
  const video = document.createElement("video");
  video.src = data.src;
  video.autoplay = false;
  video.loop = false;
  video.currentTime = 5;
  video.pause();

  video.crossOrigin = "anonymous";

  await new Promise((resolve) => {
    video.onloadedmetadata = resolve;
  });

  const texture = new THREE.VideoTexture(video);
  texture.minFilter = THREE.LinearFilter;
  texture.magFilter = THREE.LinearFilter;
  texture.colorSpace = THREE.SRGBColorSpace;
  texture.needsUpdate = true;

  const videoMesh = new THREE.Mesh(
    new THREE.PlaneGeometry(1, 1),
    new THREE.MeshBasicMaterial({
      map: texture,
      transparent: true,
      side: THREE.DoubleSide,
    })
  );

  videoMesh.position.set(data.position.x, data.position.y, data.position.z);
  videoMesh.rotation.set(data.rotation.x, data.rotation.y, data.rotation.z);
  videoMesh.scale.set(data.scale.x, data.scale.y, data.scale.z);

  videoMesh.name = data?.name ? data?.name : `Video - ${index + 1}`;
  videoMesh.userData.id = data.id;
  videoMesh.userData.thumbnail = data.src;
  videoMesh.userData.type = data.type;
  videoMesh.userData.LinkTo = data.LinkTo;

  return videoMesh;
};

/* =================== Create Icons Mesh ============================ */

const createIconMesh = async (data, index) => {
  let icons;
  let iconLink = data?.src;
  let iconLenght = iconLink?.split(".");
  let extension = iconLenght[iconLenght.length - 1];

  if (
    extension === "svg" ||
    extension === "png" ||
    extension === "jpg" ||
    extension === "jpeg"
  ) {
    const source = data.src + "?not-from-cache-please";
    const texture = await loadTexture(source);
    texture.colorSpace = THREE.SRGBColorSpace;
    texture.needsUpdate = true;
    icons = new THREE.Mesh(
      new THREE.PlaneGeometry(),
      new THREE.MeshBasicMaterial({
        map: texture,
        transparent: true,
        side: THREE.DoubleSide,
        opacity: 1,
      })
    );
  } else if (extension === "gltf" || extension === "glb") {
    const loader = new GLTFLoader(loadingManager);
    const gltf = await new Promise((resolve, reject) => {
      loader.load(data.src, resolve, undefined, reject);
    });

    icons = gltf.scene.children[0];
    icons.type1 = "Icon";
    icons.userData.class = "3Dmodal";
    icons.userData.thumbnail = data.src;
  }

  icons.position.set(data.position.x, data.position.y, data.position.z);
  if (data?.iconName === "Whatsapp" || data?.iconName === "Email") {
    icons.rotation.set(0.086, data.rotation.y, data.rotation.z);
  } else {
    icons.rotation.set(data.rotation.x, data.rotation.y, data.rotation.z);
  }
  icons.scale.set(data.scale.x, data.scale.y, data.scale.z);

  icons.name =
    data?.name ? data?.name : data?.iconName ? data?.iconName : `Icon-${index + 1}`;
  icons.userData.id = data.id;
  icons.userData.type = data.type;
  icons.userData.thumbnail = data.src;
  icons.userData.iconType=data?.iconType
  icons.userData.LinkTo = data.LinkTo;
 
  return icons;
};

/* =================== Create Text Mesh ============================ */

const createTextMesh = async (data, index) => {
  // Load the font based on the data.font
  let fontUrl;
  switch (data.font) {
    case "open sans":
      fontUrl =
        "https://fonts.gstatic.com/s/opensans/v18/mem5YaGs126MiZpBA-UN7rgOUuhp.woff";
      break;
    case "arimo":
      fontUrl =
        "https://fonts.gstatic.com/s/arimo/v14/P5sMzZCDf9_T_20eziBM3Q.woff";
      break;
    default:
      fontUrl =
        "https://threejs.org/examples/fonts/optimer_regular.typeface.json";
      break;
  }
  const font = await loadFont(fontUrl);

  // Determine style and weight
  const fontStyle = data.style === "italic" ? "italic" : "normal";
  const fontWeight = data.weight === "bold" ? "bold" : "normal";

  // Create text material

  // Create text geometry
  const textGeometry = new TextGeometry(data.body, {
    font: font,
    size: data.fontSize / 100,
    height: 0.01,
    transparent: true,
    style: fontStyle,
    weight: fontWeight,
    align: data.align,
  });

  textGeometry.center();

  const textMaterial = new THREE.MeshBasicMaterial({
    color: data.color,
    transparent: true,
  });

  // Compute bounding box to determine text dimensions
  textGeometry.computeBoundingBox();
  const textWidth =
    textGeometry.boundingBox.max.x - textGeometry.boundingBox.min.x;
  const textHeight =
    textGeometry.boundingBox.max.y - textGeometry.boundingBox.min.y;

  // Create text mesh
  const textMesh = new THREE.Mesh(textGeometry, textMaterial);

  // Create plane material

  // Create plane geometry and mesh based on text dimensions

  const planeGeometry = new THREE.PlaneGeometry(
    textWidth + 0.2,
    textHeight + 0.2
  );
  const planeMaterial = new THREE.MeshBasicMaterial({
    alphaTest: 1,
    opacity: 0,
  });
  const textPlane = new THREE.Mesh(planeGeometry, planeMaterial);
  textPlane.add(textMesh);

  // Set position, rotation, and scale of the plane
  textPlane.position.set(data.position.x, data.position.y, data.position.z);
  textPlane.rotation.set(data.rotation.x, data.rotation.y, data.rotation.z);
  textPlane.scale.set(data.scale.x, data.scale.y, data.scale.z);
  textPlane.name = data?.body;
  textPlane.userData.id = data.id;
  textPlane.userData.type = data.type;
  textPlane.userData.content = data.body;
  textPlane.userData.LinkTo = data.LinkTo;

  return textPlane;
};

const createDefaultPosition = async (data) => {
  let dato = data.centerPosition;
  const geometry = new THREE.ConeGeometry(0.05, 0.1, 32);
  const material = new THREE.MeshBasicMaterial({ color: 0xff0000 });
  const coneMesh = new THREE.Mesh(geometry, material);
  coneMesh.userData.type = "centerPosition";
  coneMesh.position.set(
    dato?.position?.x,
    dato?.position?.y,
    dato?.position?.z
  );
  coneMesh.rotation.set(
    dato?.rotation?.x,
    dato?.rotation?.y,
    dato?.rotation?.z
  );
  return coneMesh;
};

const create3DMesh = async (data, index) => {
  let model;
  const loader = new GLTFLoader(loadingManager);
  const gltf = await new Promise((resolve, reject) => {
    loader.load(data.src, resolve, undefined, reject);
  });
  model = gltf.scene.children[0];
  model.position.set(data.position.x, data.position.y, data.position.z);
  model.rotation.set(data.rotation.x, data.rotation.y, data.rotation.z);
  model.scale.set(data.scale.x, data.scale.y, data.scale.z);
  model.name = data?.name ?? `Model-${index + 1}`;
  model.userData.id = data.id;
  model.userData.type = "Model3d";
  model.type1 = "Model3d";
  model.userData.class = "3Dmodal";
  model.userData.thumbnail = data.src;
  model.userData.LinkTo = data.LinkTo;
  return model;
};

const createGroupAnchor = async (data, index) => {
  const geometry = new THREE.BoxGeometry(0.05, 0.05, 0.05);
  const material = new THREE.MeshBasicMaterial({ color: 0x2a86f7 });
  const anchorMesh = new THREE.Mesh(geometry, material);
  anchorMesh.position.set(data.position.x, data.position.y, data.position.z);
  anchorMesh.rotation.set(data.rotation.x, data.rotation.y, data.rotation.z);
  anchorMesh.scale.set(data.scale.x, data.scale.y, data.scale.z);
  anchorMesh.name = data?.name ?? `Anchor-${index + 1}`;
  anchorMesh.userData.id = data.id;
  anchorMesh.userData.type = "anchor";
  anchorMesh.userData.LinkTo = data.LinkTo;
  return anchorMesh;
};

const createPhotoMeshCarousel = async (data, index) => {
  let childMesh;

  if (data?.type2 === "video") {
    const child = document.createElement("video");
    child.src = data.src;
    child.autoplay = false;
    child.loop = false;
    child.currentTime = 5;
    child.pause();

    child.crossOrigin = "anonymous";

    await new Promise((resolve) => {
      child.onloadedmetadata = resolve;
    });

    const texture = new THREE.VideoTexture(child);
    texture.minFilter = THREE.LinearFilter;
    texture.magFilter = THREE.LinearFilter;
    texture.colorSpace = THREE.SRGBColorSpace;
    texture.needsUpdate = true;

    childMesh = new THREE.Mesh(
      new THREE.PlaneGeometry(1, 1),
      new THREE.MeshBasicMaterial({
        map: texture,
        transparent: true,
        side: THREE.DoubleSide,
      })
    );

    childMesh.name = data?.name ? data?.name : `Video - ${index + 1}`;
  } else {
    const source = data.src + "?not-from-cache-please";
    const texture = await loadTexture(source);
    texture.colorSpace = THREE.SRGBColorSpace;
    texture.needsUpdate = true;

    childMesh = new THREE.Mesh(
      new THREE.PlaneGeometry(),
      new THREE.MeshBasicMaterial({
        map: texture,
        transparent: true,
        side: THREE.DoubleSide,
      })
    );

    childMesh.name = data?.name || `Photo - ${data.id}`;
  }

  childMesh.position.set(data.position.x, data.position.y, data.position.z);
  childMesh.rotation.set(data.rotation.x, data.rotation.y, data.rotation.z);
  childMesh.scale.set(data.scale.x, data.scale.y, data.scale.z);

  childMesh.userData.id = data.id;
  childMesh.userData.thumbnail = data?.src;
  childMesh.userData.type = data.type;
  childMesh.userData.type1 = data?.type1;
  childMesh.userData.thumbnail = data?.src;
  childMesh.userData.LinkTo = data.LinkTo;

  return childMesh;
};

const createCarousel = async (data, index) => {
  const carousel = new THREE.Group();

  const meshes = await Promise.all(
    data?.children?.map(async (item) => {
      if (item.type === "carousel") {
        return await createPhotoMeshCarousel(item);
      }
      return null;
    })
  );
  meshes.forEach((mesh) => carousel.add(mesh));

  carousel.position.set(data.position.x, data.position.y, data.position.z);
  carousel.rotation.set(data.rotation.x, data.rotation.y, data.rotation.z);
  carousel.scale.set(data.scale.x, data.scale.y, data.scale.z);
  carousel.name = data?.name || `Group-${index + 1}`;
  carousel.userData = { ...carousel.userData, ...data?.userData, id: data.id, LinkTo: data.LinkTo };

  carousel.children.forEach((child) => {
    child.updateMatrixWorld(true);
  });

  return carousel;
};

const createResumeMesh = async (data, index) => {
  const source = data.src + "?not-from-cache-please";
  const texture = await loadTexture(source);
  texture.colorSpace = THREE.SRGBColorSpace;
  texture.needsUpdate = true;
  let resume = new THREE.Mesh(
    new THREE.PlaneGeometry(1.77, 0.63),
    new THREE.MeshBasicMaterial({
      map: texture,
      transparent: true,
      side: THREE.DoubleSide,
    })
  );

  resume.position.set(data.position.x, data.position.y, data.position.z);
  resume.rotation.set(data.rotation.x, data.rotation.y, data.rotation.z);
  resume.scale.set(data.scale.x, data.scale.y, data.scale.z);
  resume.name = data?.name ? data?.name : `Resume - ${index + 1}`;

  resume.userData.id = data.id;
  resume.userData.thumbnail = source;
  resume.userData.type = data.type;
  resume.userData.LinkTo = data.LinkTo;
  return resume;
};

const createDocumentMesh = async (data, index) => {
  let document;
  let iconLink = data?.src;
  let iconLenght = iconLink?.split(".");
  let extension = iconLenght[iconLenght.length - 1];

  if (
    extension === "svg" ||
    extension === "png" ||
    extension === "jpg" ||
    extension === "jpeg"
  ) {
    const source = data.src + "?not-from-cache-please";
    const texture = await loadTexture(source);
    texture.colorSpace = THREE.SRGBColorSpace;
    texture.needsUpdate = true;
    document = new THREE.Mesh(
      new THREE.PlaneGeometry(),
      new THREE.MeshBasicMaterial({
        map: texture,
        transparent: true,
        side: THREE.DoubleSide,
        opacity: 1,
      })
    );
  } else if (extension === "gltf" || extension === "glb") {
    const loader = new GLTFLoader(loadingManager);
    const gltf = await new Promise((resolve, reject) => {
      loader.load(data.src, resolve, undefined, reject);
    });

    document = gltf.scene.children[0];
    document.type1 = "Icon";
    document.userData.class = "3Dmodal";
  }

  document.position.set(data.position.x, data.position.y, data.position.z);

  document.rotation.set(data.rotation.x, data.rotation.y, data.rotation.z);

  document.scale.set(data.scale.x, data.scale.y, data.scale.z);

  document.name = data?.name ? data?.name : `Document-${index + 1}`;
  document.userData.id = data.id;
  document.userData.type = data.type;
  document.userData.thumbnail = data?.iconLink;
  document.userData.LinkTo = data.LinkTo;

  return document;
};

const createPEmitter = async (data, index) => {
  const geometry = new THREE.CapsuleGeometry( 0.05, 0.055, 8, 6 ); 
  const material = new THREE.MeshBasicMaterial({ color: 0x45cc74 });
  const emitterMesh = new THREE.Mesh(geometry, material);
  emitterMesh.position.set(data.position.x, data.position.y, data.position.z);
  emitterMesh.rotation.set(data.rotation.x, data.rotation.y, data.rotation.z);
  emitterMesh.scale.set(data.scale.x, data.scale.y, data.scale.z);
  emitterMesh.name = data?.name ?? `Emitter-${index + 1}`;
  emitterMesh.userData.id = data.id;
  emitterMesh.userData.type = "emitter";
  emitterMesh.userData.LinkTo = data.LinkTo;
  return emitterMesh;
};

const createGroupAnchorPreview = async (data, index) => {
  const groupAnchor = new THREE.Group();
  groupAnchor.position.set(data.position.x, data.position.y, data.position.z);
  groupAnchor.rotation.set(data.rotation.x, data.rotation.y, data.rotation.z);
  groupAnchor.scale.set(data.scale.x, data.scale.y, data.scale.z);
  groupAnchor.name = data?.name ?? `Anchor-${index + 1}`;
  groupAnchor.userData.id = data.id;
  groupAnchor.userData.type = "anchor";
  groupAnchor.userData.LinkTo = data.LinkTo;
  return groupAnchor;
};

export {
  loadTexture,
  loadVideo,
  createPhotoMesh,
  createVideoMesh,
  createTextMesh,
  createIconMesh,
  createTargetMesh,
  createDefaultPosition,
  create3DMesh,
  createCarousel,
  createResumeMesh,
  createDocumentMesh,
  createGroupAnchor,
  createPEmitter,
  createGroupAnchorPreview
};