import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { MdBarChart } from "react-icons/md";
import TotalSpent from "views/admin/Reseller/components/TotalSpent";
import PieChartCard from "views/admin/Reseller/components/PieChartCard";
import RegionChartCard from "views/admin/Reseller/components/RegionChartCard";
import RegionAnalytics from "views/admin/Reseller/components/RegionAnalytics";
import ResellerDashboard from "views/admin/Reseller/ResellerDashboard";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import Select from "@mui/material/Select";
import { Tabs, Tab } from '@mui/material'

import DailyTraffic from "views/admin/Reseller/components/DailyTraffic";
import Widget from "components/widget/Widget";
import TopCreatorTable from "./components/TopCreatorTable";
import { instance } from "common/Instance";
import Checkbox from "@mui/material/Checkbox";

import {
  SET_INITIAL_STATE_ANALYTICS,
  SET_INITIAL_STATE_ANALYTICS_UNIQUE_REGION_WISE,
} from "../../../redux/reducerSlice/analytics";

import { SET_HISTORY_DATA_FOR_SIDEBAR } from "../../../redux/reducerSlice/arSlice";
import Switch from "components/switch";
import SocialMediaCountsDashboard from "./SocialMediaCount";
import ExperienceCount from "./ResellerDashboard/Components/ExperienceCount";

const Dashboard = () => {
  const [loading, setLoading] = useState(false);
  const userType = useSelector((state) => state?.auth?.auth?.user?.type);
  const historyData = useSelector((state) => state?.ar?.historyDataForSidebar);

  const userid = useSelector((state) => state.auth.auth.user._id);
  const [isMultiscene, setISMultiScene] = useState(false);
  const [multiscene, setMultiScene] = useState([]);

  const [experience, setExperienceName] = useState(historyData[0]?.name);

  const handleChange = (event) => {
    setExperienceName(event.target.value);
  };

  const userToken = useSelector((state) => state?.auth?.auth?.token);
  const userDetails = useSelector((state) => state?.auth?.auth?.user);
  const getAnalytics = useSelector(
    (state) => state?.analytics?.data?.getVisitor
  );

  const getUniqueAndReturnVistor = useSelector(
    (state) => state?.analytics?.data?.getUniqueAndReturnVistor
  );

  const ReturningViewer = getUniqueAndReturnVistor?.filter(
    (element) => element?.revisit > 0
  ).length;

  const dispatch = useDispatch();

  const getDashboardData = async () => {
    setLoading(true);
    try {
      let response;

      if (userType === "user") {
        response = await instance.get(
          `api/visitors/get_visitors?experienceName=${experience}${
            isMultiscene ? `&multiScene=true` : ""
          }`,
          {
            headers: {
              authorization: `Bearer ${userToken}`,
            },
          }
        );
      } else {
        response = await instance.get(`api/visitors/get_visitors`, {
          headers: {
            authorization: `Bearer ${userToken}`,
          },
        });
      }

      if (response.status === 200) {
        dispatch(SET_INITIAL_STATE_ANALYTICS(response?.data?.data));
      }
    } catch (error) {
      console.log("Dashboard Error: ", error);
    }
  };

  const getDashboardVisitorData = async () => {
    setLoading(true);
    try {
      const response = await instance.get(
        `api/visitors/visitors_detailed_data?page=1&limit=1000&experienceName=${experience}${
          isMultiscene ? `&multiScene=true` : ""
        }`,
        {
          headers: {
            authorization: `Bearer ${userToken}`,
          },
        }
      );
      if (response.status === 200) {
        dispatch(
          SET_INITIAL_STATE_ANALYTICS_UNIQUE_REGION_WISE(
            response?.data?.data?.data
          )
        );
      }
    } catch (error) {
      console.log("Dashboard Error: ", error);
    }
  };

  const forSideBar = async () => {
    try {
      const response = await instance.get(
        `api/ar/ar_history/${userid}?page=${1}&limit=${historyData?.length}`,
        {
          headers: {
            authorization: `Bearer ${userToken}`,
          },
        }
      );
      if (response.status === 200) {
        dispatch(SET_HISTORY_DATA_FOR_SIDEBAR(response?.data?.data));
      }
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    getDashboardData();
    getDashboardVisitorData();
  }, [experience, isMultiscene]);

  useEffect(() => {
    getMultiscene();
  }, [isMultiscene]);

  const getMultiscene = async () => {
    try {
      const response = await instance.get(`api/multi/multi_scene_all`, {
        headers: {
          authorization: `Bearer ${userToken}`,
        },
      });
      if (response.status === 200) {
        setMultiScene(response?.data?.data);
        if (isMultiscene) {
          setExperienceName(
            response?.data?.data.length > 0 ? response.data?.data[0].name : ""
          );
        } else {
          setExperienceName(historyData[0]?.name ?? "");
        }
      }
    } catch (error) {}
  };

  useEffect(() => {
    forSideBar();
    getMultiscene();
  }, [userid]);

  useEffect(() => {
    if (getAnalytics && getUniqueAndReturnVistor) {
      setLoading(false);
    }
  }, [getAnalytics, getUniqueAndReturnVistor]);

  return (
    <div className="overflow-x-hidden pb-5">
      <div className="grid grid-cols-1 gap-5 md:grid-cols-2 lg:grid-cols-2 2xl:grid-cols-2 3xl:grid-cols-2">
        {userDetails?.type !== "user" && (
          <>
            <Widget
              icon={<MdBarChart className="h-7 w-7" />}
              title="Total Users"
              subtitle={getAnalytics?.total_users}
              loading={loading}
            />
            <Widget
              icon={<MdBarChart className="h-7 w-7" />}
              title="Total Sub Reseller"
              subtitle={getAnalytics?.total_subReseller}
              loading={loading}
            />
          </>
        )}
      </div>

      {userDetails?.type === "user" && (
        <div className="flex items-center justify-between gap-2 ">
          {isMultiscene ? (
            <div className="mb-1 flex items-center  justify-end">
              {multiscene?.length > 1 ? (
                <FormControl
                  sx={{ m: 1, minWidth: 120, bgColor: "#fff" }}
                  size="small"
                  className="bg-white "
                >
                  <InputLabel
                    id="demo-select-small-label"
                    sx={{ fontSize: "0.75rem", marginTop: "3px" }}
                  >
                    Multiscene
                  </InputLabel>
                  <Select
                    // labelId="demo-select-small-label"
                    id="demo-select-small"
                    value={experience}
                    label="Multiscene"
                    onChange={handleChange}
                    sx={{ fontSize: "0.75rem", bgColor: "#fff" }}
                    className="bg-white "
                  >
                    {multiscene?.map((element) => (
                      <MenuItem
                        value={element?.name}
                        sx={{ fontSize: "0.75rem" }}
                      >
                        {element?.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              ) : (
                multiscene[0]?.name && (
                  <div className="my-1 rounded-md border-2 border-gray-700 bg-white px-2 py-1 text-sm  text-black">
                    {" "}
                    {multiscene[0]?.name}
                  </div>
                )
              )}
            </div>
          ) : (
            <div className="mb-1 flex items-center  justify-end">
              {historyData?.length > 1 ? (
                <FormControl
                  sx={{ m: 1, minWidth: 120, bgColor: "#fff" }}
                  size="small"
                  className="bg-white "
                >
                  <InputLabel>Experience</InputLabel>
                  <Select
                    value={experience}
                    label="Experience"
                    onChange={handleChange}
                    className="bg-white " 
                  >
                    {historyData?.map((element) => (
                      <MenuItem
                        value={element?.name}
                       
                      >
                        {element?.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              ) : (
                historyData[0]?.name && (
                  <div className="my-1 rounded-md border-2 border-gray-700 bg-white px-2 py-1 text-sm  text-black">
                    {" "}
                    {historyData[0]?.name}
                  </div>
                )
              )}
            </div>
          )}

<Tabs
  value={isMultiscene}
  onChange={(event, newValue) => setISMultiScene(newValue)}
  sx={{ minHeight: "36px" }}
  className='bg-gray-100 p-2 border border-gray-200 rounded-xl shadow-lg'
>
  <Tab
    label="Experience"
    value={false}
    sx={{
      fontSize: "14px",
      minHeight: "36px",
      padding: "6px 12px",
      backgroundColor: isMultiscene === false ? "white" : "transparent",
      borderRadius: "8px",
      "&.Mui-selected": {
        backgroundColor: "white",
        color: "black" ,
        
      }
    }}
  />
  <Tab
    label="Multiscene"
    value={true}
    sx={{
      fontSize: "14px",
      minHeight: "36px",
      padding: "6px 12px",
      backgroundColor: isMultiscene === true ? "white" : "transparent",
      borderRadius: "8px",
      "&.Mui-selected": {
        backgroundColor: "white",
        color: "black" 
      }
    }}
  />
</Tabs>
        </div>
      )}

      <div className="grid grid-cols-1 gap-5 mt-5 md:grid-cols-3 lg:grid-cols-3 2xl:grid-cols-3 3xl:grid-cols-3">
        {userDetails?.type === "user" && (
          <>
            <Widget
              icon={<MdBarChart className="h-7 w-7" />}
              title="Total Viewers"
              subtitle={getAnalytics?.total_experience_visitor}
              loading={loading}
            />
            <Widget
              icon={<MdBarChart className="h-7 w-7" />}
              title="Returning Viewers"
              subtitle={ReturningViewer}
              loading={loading}
            />

            <Widget
              icon={<MdBarChart className="h-7 w-7" />}
              title="Unique Viewers"
              subtitle={getAnalytics?.total_unique_visitor}
              loading={loading}
            />
          </>
        )}
      </div>
      {userDetails?.type === "user" && (
        <div className="mt-5 grid w-full grid-cols-1 gap-5 md:grid-cols-2">
          <DailyTraffic
            data={getAnalytics}
            experience={experience}
            isMultiscene={isMultiscene}
          />
          {/* <TopCreatorTable
            experience={experience}
            isMultiscene={isMultiscene}
          /> */}
          <ExperienceCount
            isMultiscene={isMultiscene}
            experience={experience}
          />
        </div>
      )}

      {userDetails?.type === "reseller" && (
        <div className="mt-5 grid w-full grid-cols-1 gap-5 md:grid-cols-1">
          <DailyTraffic
            data={getAnalytics}
            experience={experience}
            isMultiscene={isMultiscene}
          />
        </div>
      )}

      <div className="mt-5 grid w-full grid-cols-1 gap-5 md:grid-cols-2">
        <TotalSpent
          data={getAnalytics}
          experience={experience}
          isMultiscene={isMultiscene}
        />
        <PieChartCard
          data={getAnalytics}
          loading={loading}
          isMultiscene={isMultiscene}
        />
      </div>

      <div className="mt-5 grid w-full grid-cols-1 gap-5 md:grid-cols-1">
        <RegionAnalytics experience={experience} isMultiscene={isMultiscene} />
      </div>
      <div className="mt-5 grid w-full grid-cols-1 gap-5 md:grid-cols-1">
        <RegionChartCard experience={experience} isMultiscene={isMultiscene} />
      </div>
      <div className="mt-5 grid w-full grid-cols-1 gap-5">
        <SocialMediaCountsDashboard experienceName={experience} />
      </div>

      {userType === "reseller" && (
        <>
          <div className="mt-5 grid w-full grid-cols-1">
            <ResellerDashboard />
          </div>
        </>
      )}
    </div>
  );
};

export default Dashboard;
