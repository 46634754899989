import React from "react";
import ForgetPassword from "views/auth/ForgetPassword";
import MainDashboard from "views/admin/Reseller";
import Userlist from "views/admin/Reseller/userlist";
import PaymentList from "views/admin/Reseller/payment";
import Leads from "views/admin/Reseller/leads";
import Profile from "views/admin/profile";
import History from "views/admin/history";
import Pricing from "views/admin/pricing";
import { Upragdepricing } from "./views/admin/pricing/components/Upgradepricing";
// import Restaurant from "views/admin/restaurants";
import Multiscene from "views/admin/Multiscene";
import Commission from "views/admin/Reseller/Commission";

import SubResellerTable from "views/admin/Reseller/subresellerlist";
// Auth Imports
import SignIn from "views/auth/SignIn";
import SignUp from "views/auth/SignUp";
import Otp from "views/auth/Otp";
// import { IoIosRestaurant } from "react-icons/io";
import TemplateList from "views/admin/template/template.jsx";
// import RestaurantDashboard from "views/admin/restaurantDashboard";
import RestaurantMenu from "views/admin/restaurantMenu";
import Branding from "views/admin/branding";
import UserSignUp from "views/auth/UserSignup";
import ResetPassword from "views/auth/ResetPassword";
import Refer from "views/admin/Reseller/Refer";
import Form from "views/admin/Reseller/FormTable";
import Settings from "views/admin/settings";
import CardForm from "views/admin/cardForm"
import SignupTryOn from "views/auth/SignupTryon";

import {

  MdOutlineShoppingCart,
  MdPerson,
  MdLock,

  MdOutlineBrandingWatermark,
  MdOutlineShoppingBag,
  MdContactSupport,

  MdDesignServices,
  MdOutlinePayment,
  MdOutlineLibraryBooks,
} from "react-icons/md";
import { TiWorldOutline } from "react-icons/ti";
// import { SiPowerpages } from "react-icons/si";
import { ImInsertTemplate } from "react-icons/im";
import { TbAugmentedReality2 } from "react-icons/tb";
import { FaFileInvoice } from "react-icons/fa";
import ArExperience from "views/admin/arExperience";
import { GrGroup } from "react-icons/gr";
import { HiOutlineShare } from "react-icons/hi";
import { TbUsersPlus, TbTruckDelivery } from "react-icons/tb";
import Orders from "views/admin/orders";
import Products from "views/admin/products";
// import RecycleBinCardTable from "views/admin/recyclebin/components/RecycleBinCardTable";
// import RecycleBinCardList from "views/admin/recyclebin";
import { IoTrashBinOutline } from "react-icons/io5";
// import Enquiry from "views/admin/enquiry";
import Billing from "views/admin/billing";
// import DesignCard from "views/admin/designCard";
import { RiBillFill } from "react-icons/ri";
// import BillingHistory from "views/admin/billing_hitsory";
import InvoiceForm from "views/admin/Reseller/invoice/create";
import Invoice from "views/admin/Reseller/invoice";
import InvoiceView from "views/admin/Reseller/invoice/view";
import EditInvoiceForm from "views/admin/Reseller/invoice/edit";
import { CiSettings } from "react-icons/ci";
import JoinReseller from "views/auth/JoinReseller";
import CustomCard from "views/admin/designCard/CustomCardPage/CardList";
import CustomCardEdit from "views/admin/designCard/CustomCardPage/CustomCard";
import PaymentForm from "views/admin/Reseller/invoice/components/RecordPayment";
import ViewPaymentForm from "views/admin/Reseller/payment/View";
import EditPaymentForm from "views/admin/Reseller/payment/Edit";
// import RecycleBinUserList from "views/admin/recyclebin/recycleBinUserList";
import RecycleBinUserList from "views/admin/recycleBin/recycleBinUserList";
import RecycleBinForExperience from "views/admin/recycleBinForExperience";
import WorldTracking from "views/admin/world_tracking_history";
import WorldExperience from "views/admin/world_experience";
import LoginTryOn from "views/auth/LoginTryOn";
import HtmlEmbedScript from "views/admin/tryon/HtmlEmbedScript";
const routes = [
  {
    name: "Dashboard",
    layout: "/admin",
    path: "dashboard",
    component: <MainDashboard />,
    isForSidebar: true,
    isSidebarNeeded: true,
    isNabBarNeeded: true,
    isFooterNeeded: false,
    isProtected: true,
  },

  {
    name: "Users",
    layout: "/admin",
    icon: <TbUsersPlus className="h-6 w-6" />,
    path: "users-list",
    component: <Userlist />,
    isForSidebar: true,
    isSidebarNeeded: true,
    isNabBarNeeded: true,
    isFooterNeeded: false,
    isProtected: true,
  },




  {
    name: "Invoice",
    layout: "/admin",
    icon: <FaFileInvoice className="h-6 w-6" />,
    path: "invoice",

    component: <Invoice />,
    isForSidebar: true,
    isSidebarNeeded: true,
    isNabBarNeeded: true,
    isFooterNeeded: false,
    isProtected: true,
  },
  {
    name: "Invoice",
    layout: "/admin",
    icon: <FaFileInvoice className="h-6 w-6" />,
    path: "invoice",
    subPath: "/create",
    component: <InvoiceForm />,
    isForSidebar: false,
    isSidebarNeeded: true,
    isNabBarNeeded: true,
    isFooterNeeded: false,
    isProtected: true,
  },
  {
    name: "Payments",
    layout: "/admin",
    icon: <MdOutlinePayment className="h-6 w-6" />,
    path: "payment",
    component: <PaymentList />,
    isForSidebar: true,
    isSidebarNeeded: true,
    isNabBarNeeded: true,
    isFooterNeeded: false,
    isProtected: true,
  },
  {
    name: "Payment",
    layout: "/admin",
    icon: <FaFileInvoice className="h-6 w-6" />,
    path: "payment",
    subPath: "/view",
    component: <ViewPaymentForm />,
    isForSidebar: false,
    isSidebarNeeded: true,
    isNabBarNeeded: true,
    isFooterNeeded: false,
    isProtected: true,
  },

  {
    name: "Payment",
    layout: "/admin",
    icon: <FaFileInvoice className="h-6 w-6" />,
    path: "payment",
    subPath: "/edit",
    component: <EditPaymentForm />,
    isForSidebar: false,
    isSidebarNeeded: true,
    isNabBarNeeded: true,
    isFooterNeeded: false,
    isProtected: true,
  },

  {
    name: "Invoice",
    layout: "/admin",
    icon: <FaFileInvoice className="h-6 w-6" />,
    path: "invoice",
    subPath: "/view",
    component: <InvoiceView />,
    isForSidebar: false,
    isSidebarNeeded: true,
    isNabBarNeeded: true,
    isFooterNeeded: false,
    isProtected: true,
  },
  {
    name: "Invoice",
    layout: "/admin",
    icon: <FaFileInvoice className="h-6 w-6" />,
    path: "invoice",
    subPath: "/edit",
    component: <EditInvoiceForm />,
    isForSidebar: false,
    isSidebarNeeded: true,
    isNabBarNeeded: true,
    isFooterNeeded: false,
    isProtected: true,
  },
  {
    name: "Invoice",
    layout: "/admin",
    icon: <FaFileInvoice className="h-6 w-6" />,
    path: "invoice",
    subPath: "/record",
    component: <PaymentForm />,
    isForSidebar: false,
    isSidebarNeeded: true,
    isNabBarNeeded: true,
    isFooterNeeded: false,
    isProtected: true,
  },



  {
    name: "Sub Reseller",
    layout: "/admin",
    icon: <GrGroup className="h-6 w-6" />,
    path: "subreseller-list",
    component: <SubResellerTable />,
    isForSidebar: true,
    isSidebarNeeded: true,
    isNabBarNeeded: true,
    isFooterNeeded: false,
    isProtected: true,
  },
  {
    name: "Form",
    layout: "/form",
    icon: <GrGroup className="h-6 w-6" />,
    path: "form",
    component: <Form />,
    isForSidebar: false,
    isSidebarNeeded: false,
    isNabBarNeeded: false,
    isFooterNeeded: false,
    isProtected: false,
  },


  {
    name: "Card Form",
    layout: "/admin",
    icon: <GrGroup className="h-6 w-6" />,
    path: "cardform/:id",
    component: <CardForm />,
    isForSidebar: false,
    isSidebarNeeded: false,
    isNabBarNeeded: false,
    isFooterNeeded: false,
    isProtected: false,
  },



  {
    name: "Template",
    layout: "/admin",
    path: "template",
    icon: <ImInsertTemplate className="h-6 w-6" />,
    component: <TemplateList />,
    isForSidebar: true,
    isSidebarNeeded: true,
    isNabBarNeeded: true,
    isFooterNeeded: false,
    isProtected: true,
  },



  {
    name: "Multiscene",
    layout: "/admin",
    path: "multiscene",
    // icon: <SiPowerpages className="h-6 w-6" />,
    component: <Multiscene />,
    isForSidebar: true,
    isSidebarNeeded: true,
    isNabBarNeeded: true,
    isFooterNeeded: false,
    isProtected: true,
  },



  {
    name: "experience",
    layout: "/admin",
    path: "ar_experience",
    subPath: "/:taskId",
    component: <ArExperience />,
    isForSidebar: false,
    isSidebarNeeded: false,
    isNabBarNeeded: false,
    isFooterNeeded: false,
    isProtected: true,
  },
  {
    name: "worldExperience",
    layout: "/admin",
    path: "world_experience",
    subPath: "/:taskId",
    component: <WorldExperience />,
    isForSidebar: false,
    isSidebarNeeded: false,
    isNabBarNeeded: false,
    isFooterNeeded: false,
    isProtected: true,
  },


  // {
  //   name: "Template",
  //   layout: "/admin",
  //   path: "template",
  //   subPath: "/:cardName",
  //   component: <TemplateList/>,
  //   isForSidebar: false,
  //   isSidebarNeeded: true,
  //   isNabBarNeeded: true,
  //   isFooterNeeded: false,
  //   isProtected: true,
  // },

  {
    name: "AR Experience",
    layout: "/admin",
    path: "AR_history",
    icon: <TbAugmentedReality2 className="h-5 w-5" />,
    component: <History />,
    isForSidebar: true,
    isSidebarNeeded: true,
    isNabBarNeeded: true,
    isFooterNeeded: false,
    isProtected: true,
  },
  {
    name: "World",
    layout: "/admin",
    path: "World_Tracking_history",
    icon: <TiWorldOutline className="h-6 w-6" />,
    component: <WorldTracking />,
    isForSidebar: true,
    isSidebarNeeded: true,
    isNabBarNeeded: true,
    isFooterNeeded: false,
    isProtected: true,
  },

  // {
  //   name: "Design Card",
  //   layout: "/admin",
  //   path: "design-card",
  //   icon: <MdOutlineModeEditOutline className="h-6 w-6" />,
  //   component: <DesignCard />,
  //   isForSidebar: true,
  //   isSidebarNeeded: true,
  //   isNabBarNeeded: true,
  //   isFooterNeeded: false,
  //   isProtected: true,
  // },
  // {
  //   name: "Custom Card",
  //   layout: "/admin",
  //   path: "custom-card",
  //   icon: <MdDesignServices className="h-6 w-6" />,
  //   component: <CustomCard />,
  //   isForSidebar: true,
  //   isSidebarNeeded: false,
  //   isNabBarNeeded: false,
  //   isFooterNeeded: false,
  //   isProtected: true,
  // },

  {
    name: "Upgrade",
    layout: "/admin",
    path: "upgrade_plan",
    icon: <MdOutlineShoppingCart className="h-6 w-6" />,
    component: <Pricing />,
    secondary: true,
    isForSidebar: false,
    isSidebarNeeded: false,
    isNabBarNeeded: false,
    isFooterNeeded: false,
    isProtected: true,
  },
  {
    name: "Upgrade",
    layout: "/admin",
    path: "upgrade_plans",
    icon: <MdOutlineShoppingCart className="h-6 w-6" />,
    component: <Upragdepricing forUpgrade={true} />,
    secondary: true,
    isForSidebar: false,
    isSidebarNeeded: true,
    isNabBarNeeded: true,
    isFooterNeeded: true,
    isProtected: true,
  },

  // {
  //   name: "Restaurant",
  //   layout: "/admin",
  //   path: "restaurant",
  //   icon: <IoIosRestaurant className="h-6 w-6" />,
  //   component: <Restaurant />,
  //   isForSidebar: true,
  //   isSidebarNeeded: true,
  //   isNabBarNeeded: true,
  //   isFooterNeeded: true,
  // },

  // {
  //   name: "Restaurant",
  //   layout: "/admin",
  //   path: "restaurant/dasboard",
  //   // subPath: "/:restaurantName",
  //   component: <RestaurantDashboard />,
  //   isForSidebar: false,
  //   isSidebarNeeded: true,
  //   isNabBarNeeded: true,
  //   isFooterNeeded: true,
  // },

  {
    name: "RestaurantMenu",
    layout: "/admin",
    path: "restaurant_menu",
    subPath: "/:categoryName",
    component: <RestaurantMenu />,
    isForSidebar: false,
    isSidebarNeeded: true,
    isNabBarNeeded: true,
    isFooterNeeded: true,
    isProtected: true,
  },

  {
    name: "Sign In",
    layout: "/auth",
    path: "sign-in",
    subPath: "?SSO",
    icon: <MdLock className="h-6 w-6" />,
    component: <SignIn />,
    isForSidebar: false,
    isSidebarNeeded: true,
    isNabBarNeeded: true,
    isFooterNeeded: true,
  },
  {
    name: "Sign Up",
    layout: "/auth",
    path: "sign-up",
    icon: <MdLock className="h-6 w-6" />,
    component: <SignUp />,
    isForSidebar: false,
    isSidebarNeeded: true,
    isNabBarNeeded: true,
    isFooterNeeded: true,
  },
  {
    name: "Sign Up",
    layout: "/auth",
    path: "tryon-signup",
    icon: <MdLock className="h-6 w-6" />,
    component: <SignupTryOn />,
    isForSidebar: false,
    isSidebarNeeded: true,
    isNabBarNeeded: true,
    isFooterNeeded: true,
  },
  // {
  //   name: "Login Try On",
  //   layout: "/auth",
  //   path: "tryon-login",
  //   icon: <MdLock className="h-6 w-6" />,
  //   component: <LoginTryOn />,
  //   isForSidebar: false,
  //   isSidebarNeeded: true,
  //   isNabBarNeeded: true,
  //   isFooterNeeded: true,
  // },

  {
    name: "Join Reseller",
    layout: "/auth",
    path: "join-reseller",
    icon: <MdLock className="h-6 w-6" />,
    component: <JoinReseller />,
    isForSidebar: false,
    isSidebarNeeded: true,
    isNabBarNeeded: true,
    isFooterNeeded: true,
  },
  {
    name: "Otp",
    layout: "/auth",
    path: "otp",
    icon: <MdLock className="h-6 w-6" />,
    component: <Otp />,
    isForSidebar: false,
    isSidebarNeeded: true,
    isNabBarNeeded: true,
    isFooterNeeded: true,
  },
  {
    name: "Forget Password",
    layout: "/auth",
    path: "password",
    icon: <MdLock className="h-6 w-6" />,
    component: <ForgetPassword />,
    isForSidebar: false,
    isSidebarNeeded: true,
    isNabBarNeeded: true,
    isFooterNeeded: true,
  },
  {
    name: "Forget Password",
    layout: "/auth",
    path: "resetpassword",
    icon: <MdLock className="h-6 w-6" />,
    component: <ResetPassword />,
    isForSidebar: false,
    isSidebarNeeded: true,
    isNabBarNeeded: true,
    isFooterNeeded: true,
  },
  {
    name: "User SignUp",
    layout: "/auth",
    path: "user/setPassword/:token",
    // subPath: "/",
    icon: <MdLock className="h-6 w-6" />,
    component: <UserSignUp />,
    isForSidebar: false,
    isSidebarNeeded: true,
    isNabBarNeeded: true,
    isFooterNeeded: true,
  },

  {
    name: "Branding",
    layout: "/admin",
    path: "branding",
    icon: <MdOutlineBrandingWatermark className="h-6 w-6" />,
    component: <Branding />,
    isForSidebar: true,
    isSidebarNeeded: true,
    isNabBarNeeded: true,
    isProtected: true,
    isFooterNeeded: false,
  },

  {
    name: "Enquiry",
    layout: "/admin",
    path: "enquiry",
    icon: <MdContactSupport className="h-6 w-6" />,
    component: <Leads />,
    isForSidebar: true,
    isSidebarNeeded: true,
    isNabBarNeeded: true,
    isFooterNeeded: false,
    isProtected: true,
  },

  {
    name: "Settings",
    layout: "/admin",
    path: "settings",
    icon: <CiSettings className="h-6 w-6" />,
    component: <Settings />,
    isForSidebar: true,
    isSidebarNeeded: true,
    isNabBarNeeded: true,
    isFooterNeeded: false,
    isProtected: true,
  },

  {
    name: "Billing",
    layout: "/admin",
    path: "billing",
    subPath: "/:id",
    icon: <RiBillFill className="h-6 w-6" />,
    component: <Billing />,
    isForSidebar: false,
    isSidebarNeeded: false,
    isNabBarNeeded: false,
    isFooterNeeded: false,
    isProtected: true,
  },
  {
    name: "Billing",
    layout: "/admin",
    path: "billing",

    icon: <RiBillFill className="h-6 w-6" />,
    component: <Billing />,
    isForSidebar: true,
    isSidebarNeeded: true,
    isNabBarNeeded: true,
    isFooterNeeded: false,
    isProtected: true,
  },

  {
    name: "Commission",
    layout: "/admin",
    path: "commission",
    icon: <RiBillFill className="h-6 w-6" />,
    component: <Commission />,
    isForSidebar: true,
    isSidebarNeeded: true,
    isNabBarNeeded: true,
    isFooterNeeded: false,
    isProtected: true,
  },

  {
    name: "Refer",
    layout: "/admin",
    icon: <HiOutlineShare className="h-6 w-6" />,
    path: "refer",
    component: <Refer />,
    isForSidebar: true,
    isSidebarNeeded: true,
    isNabBarNeeded: true,
    isFooterNeeded: false,
    isProtected: true,
  },

  {
    name: "Products",
    layout: "/admin",
    path: "products",
    icon: <MdOutlineShoppingBag className="h-6 w-6" />,
    component: <Products />,
    isForSidebar: true,
    isSidebarNeeded: true,
    isNabBarNeeded: true,
    isFooterNeeded: false,
    isProtected: true,
  },
  {
    name: "Account",
    layout: "/admin",
    path: "profile",
    icon: <MdPerson className="h-6 w-6" />,
    component: <Profile />,
    isForSidebar: true,
    isSidebarNeeded: true,
    isNabBarNeeded: true,
    isFooterNeeded: false,
    isProtected: true,
  },
  {
    name: "Recycle Bin",
    layout: "/admin",
    icon: <IoTrashBinOutline className="h-6 w-6" />,
    path: "recyclebin",
    component: <RecycleBinUserList />,
    isForSidebar: true,
    isSidebarNeeded: true,
    isNabBarNeeded: true,
    isFooterNeeded: false,
    isProtected: true,
  },
  {
    name: "Recycle Bin",
    layout: "/admin",
    icon: <IoTrashBinOutline className="h-6 w-6" />,
    path: "deletedExperiences",
    component: <RecycleBinForExperience />,
    isForSidebar: true,
    isSidebarNeeded: true,
    isNabBarNeeded: true,
    isFooterNeeded: false,
    isProtected: true,
  },
  {
    name: "Orders",
    layout: "/admin",
    path: "orders",
    icon: <TbTruckDelivery className="h-6 w-6" />,
    component: <Orders />,
    isForSidebar: true,
    isSidebarNeeded: true,
    isNabBarNeeded: true,
    isFooterNeeded: false,
    isProtected: true,
  },
  {
    name: "HTML Embed",
    layout: "/admin",
    path: "html-script",
    icon: <MdOutlineLibraryBooks className="h-6 w-6" />,
    component: <HtmlEmbedScript />,
    isForSidebar: true,
    isSidebarNeeded: true,
    isNabBarNeeded: true,
    isFooterNeeded: true,
    isProtected: true
  }
];
export default routes;
