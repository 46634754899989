import React from "react";
import { LuNfc } from "react-icons/lu";
import Logo from "./logo/Logo";
import { IoLocationSharp } from "react-icons/io5";
import { MdOutlineMail } from "react-icons/md";
import { FaMobileAlt } from "react-icons/fa";
import { QRCodeSVG } from "qrcode.react";

const CardSeventeeen = React.forwardRef(({ username, experiencename, name }, ref) => {
  return (
    <div ref={ref} className="relative flex h-[250px] w-[400px] flex-col items-center overflow-hidden bg-gray-200 text-black shadow-lg md:flex-row">
      <div className="absolute right-4 top-0 z-10 flex items-center justify-end">
        <LuNfc size={25} color="#000000" />
        <Logo color={"#000000"} height={35} width={35} />
      </div>
      <div className="absolute inset-0 z-0 h-full w-full bg-gray-200 opacity-25">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 800 600">
          <g fill="none" stroke="currentColor" strokeWidth="2">
            <circle cx="200" cy="200" r="50" />
            <circle cx="600" cy="400" r="75" />
            <line x1="0" y1="0" x2="800" y2="600" />
            <line x1="0" y1="600" x2="800" y2="0" />
            <path d="M 300 200 Q 400 300 500 200 T 700 200" />
            <path d="M 300 400 Q 400 500 500 400 T 700 400" />
          </g>
        </svg>
      </div>
      <div className="relative z-10 flex w-full flex-col items-center justify-center p-6">
        <h2 className={`mb-2 flex items-center justify-center break-words capitalize break-all ${name.length > 20 ? "text-2xl" : "text-3xl"} font-bold`}>
          {name.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()).join(' ')}
        </h2>
        <QRCodeSVG value={`https://ar.immarsify.com/${username}/${experiencename}`} fgColor="black" bgColor="transparent" className="mb-4 h-[115px] w-[115px] text-[#fdd446]" />
      </div>
    </div>
  );
});

export default CardSeventeeen;

export const CardSeventeeenBackSide = React.forwardRef(({ cardData }, ref) => {
  return (
    <div ref={ref} className="relative flex h-[250px] w-[400px] flex-col items-center overflow-hidden bg-gray-200 text-black shadow-lg md:flex-row">
      <div className="absolute right-4 top-0 z-10 flex items-center justify-end">
        <LuNfc size={25} color="#000000" />
        <Logo color={"#000000"} height={35} width={35} />
      </div>
      <div className="absolute inset-0 z-0 h-full w-full bg-gray-200 opacity-25">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 800 600">
          <g fill="none" stroke="currentColor" strokeWidth="2">
            <circle cx="200" cy="200" r="50" />
            <circle cx="600" cy="400" r="75" />
            <line x1="0" y1="0" x2="800" y2="600" />
            <line x1="0" y1="600" x2="800" y2="0" />
            <path d="M 300 200 Q 400 300 500 200 T 700 200" />
            <path d="M 300 400 Q 400 500 500 400 T 700 400" />
          </g>
        </svg>
      </div>
      <div className="relative p-4 text-sm break-all w-full">
        <div className="mt-8">
          <h2 className={`font-bold mb-2 text-center ${cardData?.companyName?.length > 20 ? "text-2xl" : "text-3xl"}`}>
            {cardData?.companyName?.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()).join(' ')}
          </h2>
          <div className="flex justify-center">
            <div className="flex flex-col gap-2 mt-4">
              {cardData?.address && <p className="text-sm flex gap-2"><IoLocationSharp className="min-w-5 min-h-5" /> {cardData?.address}</p>}
              {cardData?.email && <p className="text-sm flex gap-2"><MdOutlineMail className="min-w-5 min-h-5" /> {cardData?.email}</p>}
              {cardData?.Phone && <p className="text-sm flex gap-2"><FaMobileAlt className="min-w-5 min-h-5" /> {cardData?.Phone}</p>}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});
