import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import UploadIcon from "./UploadIcon";
import { MdOutlineExpandMore } from "react-icons/md";
import {
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
} from "@mui/material";
import {
  SET_ICONS_LINK,
  SET_ICONS_NAME,
  SET_ICONS_PROPERTIES,
  UPDATE_POSITION_FOR_FULLSCREEN,
} from "../../../../../redux/reducerSlice/arSlice";
import Attach from "./attach";
import Animation from "./animation";
import Checkbox from "@mui/material/Checkbox";
import VCard from "././../VCard";

function IconEditorSection({ whatsClicked }) {
  const [openVcard, setOpenVcard] = useState(false);
  const [position, setPosition] = useState("");
  const dispatch = useDispatch();
  const [selectedIcon, setSelectedIcon] = useState("");
  const ardata = useSelector((state) => state.ar.data);
  const [errorMessage, setErrorMessage] = useState("");
  const iconData = ardata?.icons?.find(
    (data) => data?.id === whatsClicked?.userData?.id
  );

  const anchor = useSelector((state) => state.ar.data.anchor) || null;

  const [iconModal, setIconModal] = useState(false);

  const handleOpenIconModal = () => {
    setIconModal(true);
  };

  const handleCloseIconModal = () => {
    setIconModal(false);
  };

  const linkType = useSelector((state) => {
    const icon = state?.ar?.data?.icons?.find(
      (data) => data.id === whatsClicked?.userData?.id
    );

    return icon?.linkType;
  });

  const hanndleVcard = () => {
    setOpenVcard(true);
  };
  const handleClose = () => {
    setOpenVcard(false);
  };

  const iconLink = useSelector((state) => {
    const icon = state?.ar?.data?.icons?.find(
      (data) => data.id === whatsClicked?.userData?.id
    );
    return icon?.iconLink;
  });
  const vCard = useSelector((state) => {
    const icon = state?.ar?.data?.icons?.find(
      (data) => data.id === whatsClicked?.userData?.id
    );
    return icon?.vCard;
  });

  const handleDownloadVCard = () => {
    const blob = new Blob([vCard], {
      type: "text/plain;charset=utf-8",
    });
    let url = window.URL.createObjectURL(blob);
    let a = document.createElement("a");
    a.href = url;
    a.download = `contact.vcf`; // Set the default file name here
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
  };

  const handleRadioChange = (event) => {
    const selectedPosition = event.target.value;
    setPosition(selectedPosition);

    let data = {
      id: whatsClicked?.userData?.id,
      position: selectedPosition,
    };
    dispatch(UPDATE_POSITION_FOR_FULLSCREEN(data));
  };

  const currentPositionFullscreen = useSelector((state) => {
    const icon = state?.ar?.data?.icons?.find(
      (icon) => icon.id === whatsClicked?.userData?.id
    );
    return icon?.positionFullscreen || "topRight";
  });

  useEffect(() => {
    setPosition(currentPositionFullscreen);
  }, [currentPositionFullscreen]);

  return (
    <div>
      {whatsClicked?.userData?.type === "icons" && (
        <div className="mt-2 px-2">
          <div className="rounded-md bg-white px-4 py-2 shadow-md">
            <p className="py-2">Edit Icon</p>
            {whatsClicked?.userData?.type === "icons" && (
              <div className=" flex w-full items-center justify-center">
                <button
                  className="w-full cursor-pointer rounded-md bg-[#3D2FA9] p-1 py-3 text-center text-xs font-semibold text-white"
                  onClick={handleOpenIconModal}
                >
                  Replace Icon
                </button>

                <UploadIcon
                  open={iconModal}
                  handleClose={handleCloseIconModal}
                  id={whatsClicked?.userData?.id}
                />
              </div>
            )}

            <div className="border-t-1 flex w-full items-center justify-center">
              <div className="flex w-full flex-row items-center justify-between">
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={linkType === "RedirectLink"}
                      onChange={(event) => {
                        const isChecked = event.target.checked;
                        const isRedirectLink = isChecked;

                        const action = {
                          key: "isLink",
                          value: Boolean(isRedirectLink),
                          id: whatsClicked?.userData?.id,
                        };
                        dispatch(SET_ICONS_PROPERTIES(action));

                        const actionLink = {
                          key: "isvCard",
                          value: false,
                          id: whatsClicked?.userData?.id,
                        };
                        dispatch(SET_ICONS_PROPERTIES(actionLink));

                        const actionType = {
                          key: "linkType",
                          value: isChecked ? "RedirectLink" : "",
                          id: whatsClicked?.userData?.id,
                        };
                        dispatch(SET_ICONS_PROPERTIES(actionType));
                      }}
                    />
                  }
                  label={<span style={{ fontSize: 12 }}>Link</span>}
                  className="w-full"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={linkType === "Vcard"}
                      onChange={(event) => {
                        const isChecked = event.target.checked;
                        const isVcard = isChecked;

                        hanndleVcard();

                        const action = {
                          key: "isLink",
                          value: false,
                          id: whatsClicked?.userData?.id,
                        };
                        dispatch(SET_ICONS_PROPERTIES(action));

                        const actionLink = {
                          key: "isvCard",
                          value: Boolean(isVcard),
                          id: whatsClicked?.userData?.id,
                        };
                        dispatch(SET_ICONS_PROPERTIES(actionLink));

                        const actionType = {
                          key: "linkType",
                          value: isChecked ? "Vcard" : "",
                          id: whatsClicked?.userData?.id,
                        };
                        dispatch(SET_ICONS_PROPERTIES(actionType));
                      }}
                    />
                  }
                  label={<span style={{ fontSize: 12 }}>Vcard</span>}
                  className="w-full"
                />
              </div>
            </div>

            {whatsClicked?.userData?.type === "icons" &&
              linkType === "Vcard" && (
                <div className="flex items-center justify-between gap-1">
                  <div className="text-bold my-1  flex w-full items-center justify-between gap-2 text-xs text-gray-900">
                    <button
                      className="w-full rounded-lg bg-[#3d2fa9] px-3 py-3 font-semibold text-white"
                      onClick={hanndleVcard}
                    >
                      Add V-Card
                    </button>

                    <button
                      onClick={handleDownloadVCard}
                      className="w-full rounded-lg bg-[#3d2fa9] py-3 text-xs font-semibold text-white"
                    >
                      Download V-Card
                    </button>
                  </div>

                  <VCard
                    open={openVcard}
                    handleClose={handleClose}
                    id={whatsClicked?.userData?.id}
                  />
                </div>
              )}

            {whatsClicked?.userData?.type === "icons" &&
              linkType === "RedirectLink" && (
                <>
                  <div className="flex flex-col justify-center">
                    <div className="text-gray-900">Select Icon Name</div>
                    <select
                      name=""
                      id=""
                      className="w-full border p-1"
                      value={iconData?.iconName || selectedIcon || ""}
                      onChange={(event) => {
                        const action = {
                          iconName: event.target.value,
                          id: whatsClicked?.userData?.id,
                        };
                        dispatch(SET_ICONS_NAME(action));
                        setSelectedIcon(event.target.value);
                        setErrorMessage("");
                      }}
                    >
                      <option value="" className=" text-gray-200" disabled>
                        Select an icon
                      </option>
                      <option value="Instagram">Instagram</option>
                      <option value="FaceBook">FaceBook</option>
                      <option value="LinkedIn">LinkedIn</option>
                      <option value="Twitter">Twitter</option>
                      <option value="WhatsApp">WhatsApp</option>
                      <option value="Reddit">Reddit</option>
                      <option value="Gmail">Gmail</option>
                      <option value="Pinterest">Pinterest</option>
                      <option value="Youtube">Youtube</option>
                      <option value="Discord">Discord</option>
                      <option value="Chrome">Chrome</option>
                      <option value="Drive">Drive</option>
                      <option value="Google">Google</option>
                      <option value="Dribble">Dribble</option>
                      <option value="Google Maps">Google Maps</option>
                      <option value="Google Reviews">Google Reviews</option>
                      <option value="Snapchat">Snapchat</option>
                      <option value="Telegram">Telegram</option>
                      <option value="Amazon">Amazon</option>
                      <option value="Meesho">Meesho</option>
                      <option value="Flipkart">Flipkart</option>
                      <option value="Website">Website</option>
                    </select>
                  </div>
                  <div className="text-bold mb-2 mt-2 flex w-full flex-col items-center text-xs text-gray-900">
                    <div className="flex w-full flex-col rounded-lg bg-white">
                      <h1 className="flex items-center py-1">URL:</h1>
                      <input
                        type="text"
                        className="w-full border p-1 outline-none"
                        value={iconLink}
                        onChange={(event) => {
                          if (!selectedIcon && iconData?.iconName === "") {
                            setErrorMessage("Please select Icon Name first!");
                          } else {
                            setErrorMessage("");
                            const action = {
                              iconLink: event.target.value,
                              id: whatsClicked?.userData?.id,
                            };
                            dispatch(SET_ICONS_LINK(action));
                          }
                        }}
                      />
                    </div>
                    {errorMessage && (
                      <span className="mt-1 w-full text-left text-xs text-red-500">
                        {errorMessage}
                      </span>
                    )}
                  </div>
                </>
              )}
          </div>
        </div>
      )}

      {whatsClicked?.userData?.type === "icons" &&
        whatsClicked?.userData?.iconType === "video" && (
          <div className="mx-2 mt-2 ">
            <Accordion>
              <AccordionSummary
                expandIcon={<MdOutlineExpandMore size={20} />}
                aria-controls="panel1-content"
                id="panel1-header"
              >
                Select Position
              </AccordionSummary>
              <AccordionDetails sx={{ marginTop: "-15px" }}>
                <FormControl component="fieldset">
                  <RadioGroup
                    aria-label="position"
                    name="position"
                    value={position}
                    onChange={handleRadioChange}
                  >
                    <FormControlLabel
                      value="topRight"
                      sx={{
                        "& .MuiFormControlLabel-label": {
                          fontSize: "0.875rem",
                        },
                        marginBottom: "-5px",
                      }}
                      control={<Radio />}
                      label="Top Right"
                    />
                    <FormControlLabel
                      value="topLeft"
                      sx={{
                        "& .MuiFormControlLabel-label": {
                          fontSize: "0.875rem",
                        },
                        marginBottom: "-5px",
                      }}
                      control={<Radio />}
                      label="Top Left"
                    />
                    <FormControlLabel
                      value="bottomRight"
                      sx={{
                        "& .MuiFormControlLabel-label": {
                          fontSize: "0.875rem",
                        },
                        marginBottom: "-5px",
                      }}
                      control={<Radio />}
                      label="Bottom Right"
                    />
                    <FormControlLabel
                      value="bottomLeft"
                      sx={{
                        "& .MuiFormControlLabel-label": {
                          fontSize: "0.875rem",
                        },
                        marginBottom: "-5px",
                      }}
                      control={<Radio />}
                      label="Bottom Left"
                    />
                  </RadioGroup>
                </FormControl>
              </AccordionDetails>
            </Accordion>
          </div>
        )}

      {whatsClicked?.userData?.type === "icons" && Array.isArray(anchor) && anchor.length > 0 && (
        <Attach whatsClicked={whatsClicked} anchor={anchor} arItem={ardata.icons} type="icons" />
      )}

      {whatsClicked?.userData?.type === "icons" && (
        <Animation whatsClicked={whatsClicked} arData={ardata} type="icons" />
      )}
    </div>
  );
}

export default IconEditorSection;
