import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  ADD_NEW_RESUME_FIELD,
  REMOVE_RESUME_FIELD,
  UPDATE_RESUME_FIELD_ITEM,
} from "../../../../../redux/reducerSlice/arSlice";
import Attach from "./attach";
import Animation from "./animation";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  IconButton,
  Typography,
} from "@mui/material";
import { MdOutlineDeleteOutline, MdOutlineExpandCircleDown } from "react-icons/md";
import AddProductIcons from "../modal/AddProductModalIcon";

function ResumeEditorSection({ whatsClicked, open, setOpenModal }) {

  const [expanded, setExpanded] = useState(false);
  const dispatch = useDispatch();
  const ardata = useSelector((state) => state.ar.data);
  

  const resumeData = ardata.resume?.find(
    (element) => element?.id === whatsClicked?.userData?.id
  );

  const [fields, setFields] = useState([]);
  const [IconIndex, setIconIndex] = useState(1);

  const anchor = useSelector((state) => state.ar.data.anchor) || null;

  const [loading, setLoading] = useState(false);
  
  useEffect(() => {
    setFields(resumeData?.resumeData || []);
  }, [whatsClicked]);

  const handleDelete = (index) => {
    const newFields = fields.filter((_, i) => i !== index);
    setFields(newFields);

    dispatch(REMOVE_RESUME_FIELD({ id: resumeData?.id, fieldId: index + 1 }));
  };

  const handleChange = (event, index) => {
    const { name, value } = event.target;
    const newFields = fields.map((field, i) =>
      i === index ? { ...field, [name]: value } : field
    );
    setFields(newFields);
  };

  const handleOnBlur = (index) => {
    const newFields = [...fields];
    dispatch(
      UPDATE_RESUME_FIELD_ITEM({
        resumeId: resumeData?.id,
        id: index + 1,
        itemData: newFields[index],
      })
    );
  };

  const handleAccordionChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleAddMore = () => {
    setFields((prevFields) => {
      const updatedFields = [...prevFields, { title: "", description: "" }];
      const fieldData = { title: "", description: "" };
      // Dispatch the action to Redux with the updated fields
      dispatch(
        ADD_NEW_RESUME_FIELD({
          fieldData,
          id: fields.length + 1,
          resumeId: resumeData?.id,
        })
      );
      return updatedFields;
    });
  };

  return (
    <div>
      {whatsClicked?.userData?.type === "resume" && (
        <>
          <div className="mt-2 items-center px-2">
            <div className="mx-auto w-full max-w-lg rounded-lg bg-white px-4 py-2 shadow-md">
            
              <p className="p-2">Resume Feilds</p>
              {fields?.map((field, index) => (
                  <Accordion
                    key={index}
                    expanded={expanded === `panel${index}`}
                    onChange={handleAccordionChange(`panel${index}`)}
                    sx={{
                      boxShadow:
                        "rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px",
                    }}
                  >
                    <AccordionSummary
                      expandIcon={<MdOutlineExpandCircleDown />}
                      aria-controls={`panel${index}-content`}
                      id={`panel${index}-header`}
                    >
                      <Typography
                        className="flex w-full items-center justify-between"
                        sx={{ fontSize: "14px" }}
                      >
                        Field Set {index + 1}
                        <IconButton
                          onClick={() => handleDelete(index)}
                          sx={{ fontSize: "15px", color: "#e94646" }}
                        >
                          <MdOutlineDeleteOutline />
                        </IconButton>
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails className="flex flex-col space-y-2">
                      <div className="flex flex-col space-y-2">
                        <label
                          htmlFor={`input1-${index}`}
                          className="  text-gray-700"
                          style={{ fontSize: "13px" }}
                        >
                          Enter Title Text
                        </label>
                        <input
                          id={`input1-${index}`}
                          type="text"
                          className="h-10 rounded-md border px-3 outline-none focus:ring-2 focus:ring-indigo-500"
                          placeholder="Ex: About Me...."
                          style={{ fontSize: "12px" }}
                          name="title"
                          value={field?.title || ""}
                          onChange={(e) => handleChange(e, index)}
                          onBlur={() => handleOnBlur(index)}
                        />
                      </div>
                      <div className="flex flex-col space-y-2">
                        <label
                          htmlFor={`input2-${index}`}
                          className="  text-gray-700"
                          style={{ fontSize: "13px" }}
                        >
                          Enter description text
                        </label>
                        <textarea
                          id={`input2-${index}`}
                          style={{ fontSize: "12px" }}
                          type="text"
                          className=" rounded-md border px-3 outline-none focus:ring-2 focus:ring-indigo-500"
                          placeholder="Ex: About Me description..."
                          name="description"
                          value={field?.description || ""}
                          onChange={(e) => handleChange(e, index)}
                          onBlur={() => handleOnBlur(index)}
                          rows='6'
                          cols='6'
                        />
                      </div>
                      {field?.icon && field?.icon.src && (
                        <div className="flex items-center">
                          <img
                            src={field.icon.src}
                            alt={field.icon.name}
                            className="mr-2 h-10 w-10 object-contain" 
                          />
                        
                        </div>
                      )}
                      <button
                        onClick={() => {
                          setIconIndex(index);
                          setOpenModal(true);
                        }}
                        type="button"
                        id={`icon-${index}`}
                        className="mt-2 w-full rounded-md bg-brand-main px-4 py-1 font-semibold text-white shadow  focus:outline-none focus:ring-2 "
                      >
                        Set Icon
                      </button>
                    </AccordionDetails>
                  </Accordion>
                ))}
              <div className="mt-4 flex justify-end">
                <button
              
                 
                  className="rounded-md bg-[#3d2fa9] px-4 py-2 text-white"
                  onClick={handleAddMore}
                >
                  Add More
                </button>
              </div>
            </div>
          </div>
          
          <div/>
          
          {whatsClicked?.userData?.type === "resume" && Array.isArray(anchor) && anchor.length > 0 && (
            <Attach whatsClicked={whatsClicked} anchor={anchor} arItem={ardata.resume} type="resume" />
          )}
          <div/>

          {whatsClicked?.userData?.type === "resume" && (
            <Animation whatsClicked={whatsClicked} arData={ardata} type="resume" />
          )}
        </>
      )}

      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <div className="flex flex-col items-center justify-center gap-4">
          <h1 className="font-bold">Uploading Image</h1>
          <CircularProgress color="inherit" />
        </div>
      </Backdrop>

      <AddProductIcons
        open={open}
        IconIndex={IconIndex}
        resumeId={resumeData?.id}
        setFields={setFields}
        fields={fields}
        handleClose={() => {
          setOpenModal(false);
        }}
      />
    </div>
  );
}

export default ResumeEditorSection;
