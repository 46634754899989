import React, { useCallback, useEffect, useRef, useState } from "react";
import * as THREE from "three";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls.js";
import { Modal, Box,  } from '@mui/material';
import { MdOutlineClear } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import {
  createPhotoMesh,
  createTextMesh,
  createVideoMesh,
  createIconMesh,
  createTargetMesh,
  createDefaultPosition,
  create3DMesh,
  createDocumentMesh,
  createCarousel,
  createGroupAnchorPreview,
  createPEmitter,
  createResumeMesh,
} from "./EditorUtils";

const Preview = ({ open, handleClose }) => {
  const mountRef = useRef(null);
  const sceneRef = useRef(null);
  const rendererRef = useRef(null);
  const cameraRef = useRef(null);
  const controlsRef = useRef(null);
  const arData = useSelector((state) => state.ar.data);
  const [photoMeshes, setPhotoMeshes] = useState([]);
  const [resumeMeshes, setResumeMeshes] = useState([]);
  const [textMeshes, setTextMeshes] = useState([]);
  const [videoMeshes, setVideoMeshes] = useState([]);
  const [iconMeshes, setIconMeshes] = useState([]);
  const [documentMeshes, setDocumentMeshes] = useState([]);
  const [threeDMeshes, setThreeDMeshes] = useState([]);
  const [carouselMesh, setCarouselMesh] = useState([]);
  const [groupMeshes, setGroupMeshes] = useState([]);
  const [pEmitter, setPEmitter] = useState([]);
  const [targetMesh, setTargetMesh] = useState(null);
  const [centerPosition, setCenterPosition] = useState(null);
  const [clickEvent, setClickEvent] = useState(null);
  

  const createPhotoMeshMemo = useCallback(
    async (photo, index) => await createPhotoMesh(photo, index),
    []
  );

  const createResumeMeshMemo = useCallback(
    async (resume, index) => await createResumeMesh(resume, index),
    []
  );

  const createTextMeshMemo = useCallback(
    async (text, index) => await createTextMesh(text, index),
    []
  );

  const createVideoMeshMemo = useCallback(
    async (video, index) => await createVideoMesh(video, index),
    []
  );

  const createIconMeshMemo = useCallback(
    async (icon, index) => await createIconMesh(icon, index),
    []
  );

  const create3DMeshMemo = useCallback(
    async (model, index) => await create3DMesh(model, index),
    []
  );

  const createGroup = useCallback(
    //Modify this to only create the group and not the cube mesh
    async (anchor, index) => await createGroupAnchorPreview(anchor, index),
    []
  );

  const createEmitter = useCallback(
    async (emitter, index) => await createPEmitter(emitter, index),
    []
  );

  const createCarouselMesh = useCallback(
    async (carousel, index) => await createCarousel(carousel, index),
    []
  );

  const createTargetMeshMemo = useCallback(
    async (targetImage) => await createTargetMesh(targetImage),
    []
  );

  const createDefaultPositionMemo = useCallback(
    async (data) => await createDefaultPosition(data),
    []
  );

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    bgcolor: 'background.paper',
    border: 'none',
    boxShadow: 24,
    outline:'none',
    borderRadius:2,
  };

  //create item meshes and groups
  useEffect(() => {
    const meshes = [
      ...photoMeshes,
      ...textMeshes,
      ...videoMeshes,
      ...iconMeshes,
      ...threeDMeshes,
      ...documentMeshes,
      ...carouselMesh,
      ...resumeMeshes,
      ...groupMeshes,
      ...pEmitter,
      centerPosition,
      targetMesh,
    ].filter(Boolean);

    console.log("Preview Meshes --> ",meshes);

    meshes.forEach((mesh) => {
      if (mesh) {
        //Add mesh to scene
        sceneRef.current?.add(mesh);
        //Attach meshes to Anchors/Parents if the relation exist
        if (mesh.userData.LinkTo){
          const parent = meshes.filter((data) => data?.userData.id === mesh.userData?.LinkTo);
          parent[0].attach(mesh);
        }
      }
    });

    return () => {
      meshes.forEach((mesh) => {
        if (mesh) {
          mesh?.material?.dispose();
          mesh?.geometry?.dispose();
          sceneRef.current?.remove(mesh);
        }
      });
    };
  }, [sceneRef.current]);

    useEffect(() => {
      const fetchPhotoMeshes = async () => {
        const meshes = await Promise.all(arData.photos.map(createPhotoMeshMemo));
        setPhotoMeshes(meshes);
      };
      fetchPhotoMeshes();
    }, [open, arData?.photos, createPhotoMeshMemo]);
  
    useEffect(() => {
      if (arData?.resume) {
        const fetchResumeMeshes = async () => {
          const meshes = await Promise.all(
            arData.resume.map(createResumeMeshMemo)
          );
          setResumeMeshes(meshes);
        };
  
        fetchResumeMeshes();
      }
    }, [open, arData?.resume, createResumeMeshMemo]);
  
    useEffect(() => {
      const fetchTextMeshes = async () => {
        const meshes = await Promise.all(arData.text.map(createTextMeshMemo));
        setTextMeshes(meshes);
      };
      fetchTextMeshes();
    }, [open, arData?.text, createTextMeshMemo]);
  
    useEffect(() => {
      const fetchVideoMeshes = async () => {
        const meshes = await Promise.all(arData.videos.map(createVideoMeshMemo));
        setVideoMeshes(meshes);
      };
      fetchVideoMeshes();
    }, [open, arData?.videos, createVideoMeshMemo]);
  
    useEffect(() => {
      if (arData?.carousel) {
        const fetchCarouselMeshes = async () => {
          const meshes = await Promise.all(
            arData?.carousel?.map(createCarouselMesh)
          );
          setCarouselMesh(meshes);
        };
        fetchCarouselMeshes();
      }
    }, [open, arData?.carousel, createCarouselMesh]);
  
    useEffect(() => {
      const fetchIconMeshes = async () => {
        const meshes = await Promise.all(arData.icons.map(createIconMeshMemo));
        setIconMeshes(meshes);
      };
      fetchIconMeshes();
    }, [open, arData?.icons, createIconMeshMemo]);
  
    useEffect(() => {
      if (arData?.documents) {
        const fetchDocumentMeshes = async () => {
          const meshes = await Promise.all(
            arData.documents.map(createDocumentMesh)
          );
          setDocumentMeshes(meshes);
        };
        fetchDocumentMeshes();
      }
    }, [open, arData?.documents, createDocumentMesh]);
  
    useEffect(() => {
      if (arData?.anchor) {
        const fetchGroupMeshes = async () => {
          const meshes = await Promise.all(arData?.anchor?.map(createGroup));
          setGroupMeshes(meshes);
        };
        fetchGroupMeshes();
      }
    }, [open, arData?.anchor, createGroup]);
  
    useEffect(() => {
      if (arData?.emitter) {
        const fetchPEmitter = async () => {
          const meshes = await Promise.all(arData?.emitter?.map(createEmitter));
          setPEmitter(meshes);
        };
        fetchPEmitter();
      }
    }, [open, arData?.emitter, createEmitter]);
  
    useEffect(() => {
      if (arData?.model) {
        const fetch3DMeshes = async () => {
          const meshes = await Promise.all(arData?.model?.map(create3DMeshMemo));
          setThreeDMeshes(meshes);
        };
        fetch3DMeshes();
      }
    }, [open, arData?.model, create3DMeshMemo]);
  
    const fetchTargetMesh = async () => {
      const mesh = await createTargetMeshMemo(arData?.targetImage);
      setTargetMesh(mesh);
    };
    useEffect(() => {
      fetchTargetMesh();
    }, [open, arData?.targetImage, createTargetMeshMemo]);

    useEffect(() => {
      const fetchCenterPosition = async () => {
        const position = await createDefaultPositionMemo(arData);
        setCenterPosition(position);
        controlsRef.current?.target.set(arData.centerPosition.position.x,arData.centerPosition.position.y,arData.centerPosition.position.z);
      };
      fetchCenterPosition();
    }, [open, arData, createDefaultPositionMemo]);

  useEffect(() => {
    if (!open) return; // Only activate when `open` is true

    // Create the scene
    sceneRef.current = new THREE.Scene();
    sceneRef.current.background = new THREE.Color(0x50b1c7);

    // Create camera
    const width = mountRef.current.clientWidth;
    const height = 300;
    cameraRef.current = new THREE.PerspectiveCamera(75, width / height, 0.1, 1000);
    cameraRef.current.position.set(0, 3, 0);

    // Create renderer
    rendererRef.current = new THREE.WebGLRenderer({ antialias: true });
    rendererRef.current.setSize(width, height);
    mountRef?.current?.appendChild(rendererRef?.current?.domElement);

    // Add lights
    const light = new THREE.DirectionalLight(0xffffff, 1);
    const ambientLight = new THREE.AmbientLight(0xffffff, 0.5);
    light.position.set(5, 5, 5);
    sceneRef.current?.add(light);
    sceneRef.current?.add(ambientLight);

    //Setup racaster and clickables
    // function onMouseClick(event) {
    //   const rect = renderer.domElement.getBoundingClientRect();
    //   const mouse = new THREE.Vector2();
    //   mouse.x = ((event.clientX - rect.left) / rect.width) * 2 - 1;
    //   mouse.y = -((event.clientY - rect.top) / rect.height) * 2 + 1;
    //   const raycaster = new THREE.Raycaster();
    //   raycaster.setFromCamera(mouse, camera);
  
    //   const clickableMeshes = scene.children.filter(
    //     (obj) =>
    //       obj.type === "Mesh" ||
    //       obj.type1 === "Icon" ||
    //       obj.type1 === "Model3d" ||
    //       obj.type === "Group"
    //   );
  
    //   const clickableObjects = [...clickableMeshes];
  
  
    //   const intersects = raycaster.intersectObjects(clickableObjects);
    //   const clickedObjects = [];
  
    //   intersects.forEach((intersection) => {
    //     let parent = intersection.object.parent;
  
    //     while (parent) {
    //       if (parent.userData && parent.userData.class === "3Dmodal") {
    //         clickedObjects.push(parent);
    //         break;
    //       }
    //       parent = parent.parent;
    //     }
    //   });
  
    //   if (intersects.length > 0) {
    //     setIsClicked(true);
  
    //     if (clickedObjects?.length === 0) {
    //       if (intersects[0].object.geometry.type === "TextGeometry") {
    //         intersects[0].object.parent.userData.clicked = true;
    //         setWhatsClicked(intersects[0].object.parent);
    //       } else if (intersects[0]?.object?.userData?.type === "target_img") {
    //         intersects[0].object.userData.clicked = true;
    //         setWhatsClicked(intersects[0].object);
    //       } else if (intersects[0]?.object?.userData?.type === "carousel") {
    //         intersects[0].object.parent.userData.clicked = true;
    //         setWhatsClicked(intersects[0].object.parent);
    //       } else {
    //         intersects[0].object.userData.clicked = true;
    //         setWhatsClicked(intersects[0].object);
    //       }
    //     } else if (clickedObjects?.length !== 0) {
    //       clickedObjects[0].userData.clicked = true;
    //       setWhatsClicked(clickedObjects[0]);
    //     }
    //   } else {
    //     setWhatsClicked(null);
    //     setIsClicked(false);
    //   }
    // }
    

    // Set the orbitControls
    controlsRef.current = new OrbitControls(cameraRef?.current, rendererRef?.current.domElement);
    controlsRef.current.enableDamping = true;

    //CSS for the canvas
    document.getElementById("previewScene").lastChild.style.borderRadius = "8px";

    // Set the animation Loop
    const animate = () => {
      if (!sceneRef.current) return; // Stop if scene is removed
      requestAnimationFrame(animate);
      //create a function to animate the meshes acording to arData.itemType.item.animation if arData.itemType.item.isAnimation is true.
      controlsRef?.current?.update();
      rendererRef?.current?.render(sceneRef?.current, cameraRef?.current);
    };

    animate();

    // Cleanup function to remove scene on unmount/close
    return () => {
      controlsRef?.current?.dispose();
      mountRef?.current?.removeChild(rendererRef.current.domElement);
      rendererRef?.current?.dispose();
      sceneRef.current = null;
    };
  }, [open]);

  return (
    open && (
        <div>
            <div className="h-full w-full bg-black opacity-[40%] absolute top-0 left-0" />
            <Box sx={style}>
                <div className="mb-2 flex  items-center justify-between bg-white p-3  text-gray-900 shadow-lg ">
                    <h4 className=" text-lg font-semibold">Preview</h4>
                    <button
                    className="cursor-pointer"
                    onClick={() => {
                        handleClose();
                    }}
                    >
                    {" "}
                    <MdOutlineClear size={20} />
                    </button>
                </div>
                <div className='px-2' >
                    <div id="previewScene" ref={mountRef} className="h-full w-full rounded-md"/>
                    {/* This is your preview content. */}
                </div>
                <div className='flex justify-end p-4'>
                    <button 
                        onClick={handleClose}
                        className='bg-gray-900 text-white rounded-lg px-4 py-2'
                        >
                        Close
                    </button>
                </div>
            </Box>
        </div>
    )
  );
};

export default Preview;
